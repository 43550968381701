import socket from './PublicSocket'
import api from './API'

const baseSignUp = {
  signUps: [],
  signupCount: 0,
  totalPages: 0,
  momentState: 'hidden',
  waitingToStart: false,
}

function getSignUpMoment(signUps, id) {
  return signUps[id] ? signUps[id] : { ...baseSignUp }
}

export default {
  namespaced: true,
  state: {
    signUps: {},
    subscribedSignUps: {},
  },
  getters: {
    signUpMomentForId: (state) => (id) => {
      return getSignUpMoment(state.signUps, id)
    },
  },
  actions: {
    async subscribe({ commit, state }, momentId) {
      if (!this.$socket) {
        this.$socket = await socket()
        this.$socket.subscribe('app_button').listen((data) => commit('APP_BUTTON_UPDATE', data), { backlog: 1 })
      }

      if (!state.subscribedSignUps[momentId]) {
        this.$socket.subscribe({ 'dario:sign_up': momentId }).on('count', ({ count }) =>
          commit('SIGNUP_COUNT_UPDATE', {
            momentId,
            count,
          })
        )
        commit('SET_SUBSCRIBED_SIGN_UP_MOMENT', momentId)
      }
    },
    async fetchSignups({ commit }, { momentId, params }) {
      commit('PARAMS_CHANGE', { momentId, params })
      const { data } = await api.get(`/production/sign_up_moments/${momentId}/sign_ups.json`, { params })
      commit('SIGNUPS_FETCHED', { momentId, data })
    },
    async refreshSignups({ commit, state }, momentId) {
      const signUpMoment = state.signUps[momentId]
      const { data } = await api.get(`/production/sign_up_moments/${momentId}/sign_ups.json`, {
        params: signUpMoment.params,
      })
      commit('SIGNUPS_FETCHED', { momentId, data })
    },
    async selectWinner({ dispatch }, momentId) {
      await api.post(`/production/sign_up_moments/${momentId}/winner`)
      dispatch('refreshSignups', momentId)
    },
    async contactPerson({ dispatch }, { momentId, signUpId }) {
      await api.post(`/production/sign_ups/${signUpId}/contacted`)
      dispatch('refreshSignups', momentId)
    },
    async clearPerson({ dispatch }, { momentId, signUpId }) {
      await api.post(`/production/sign_ups/${signUpId}/clear`)
      dispatch('refreshSignups', momentId)
    },
    async startSignUpMoment({ commit }, signUpId) {
      commit('WAITING_TO_START', signUpId)
      await api.post(`/production/sign_up_moments/${signUpId}/start`)
    },
    async stopSignUpMoment(context, signUpId) {
      await api.post(`/production/sign_up_moments/${signUpId}/stop`)
    },
  },
  mutations: {
    APP_BUTTON_UPDATE(state, data) {
      state.signUps = Object.entries(state.signUps).reduce((agg, [momentId, signUp]) => {
        if (data.id.toString() === momentId) {
          signUp.momentState = data.state
          signUp.duration = data.duration
        } else {
          signUp.momentState = 'hidden'
          signUp.duration = undefined
        }
        signUp.waitingToStart = false

        agg[momentId] = signUp

        return agg
      }, {})
    },
    SIGNUP_COUNT_UPDATE(state, { momentId, count }) {
      const signUp = getSignUpMoment(state.signUps, momentId)
      signUp.signupCount = count
      state.signUps[momentId] = signUp
    },
    SIGNUPS_FETCHED(state, { momentId, data }) {
      let signUp = getSignUpMoment(state.signUps, momentId)
      signUp = {
        ...signUp,
        signupCount: data.totalCount,
        totalPages: data.totalPages,
        totalUnfilteredCount: data.totalUnfilteredCount,
        signUps: data.signUps.map((su) => ({
          ...su,
          contactedAt: su.contactedAt ? new Date(su.contactedAt) : null,
          selectedAt: su.selectedAt ? new Date(su.selectedAt) : null,
        })),
      }
      state.signUps[momentId] = signUp
    },
    WAITING_TO_START(state, momentId) {
      const signUp = getSignUpMoment(state.signUps, momentId)
      signUp.waitingToStart = true
      state.signUps[momentId] = signUp
    },
    PARAMS_CHANGE(state, { momentId, params }) {
      const signUp = getSignUpMoment(state.signUps, momentId)
      signUp.params = params
      state.signUps[momentId] = signUp
    },
    SET_SUBSCRIBED_SIGN_UP_MOMENT(state, momentId) {
      state.subscribedSignUps[momentId] = true
    },
  },
}
