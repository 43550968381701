<template>
  <span class="options">
    <span
      v-for="({ label, count }, optionValue) in options"
      :key="optionValue"
      class="label switch"
      :class="{ 'label-info': optionValue == modelValue }"
      @click="$emit('update:modelValue', optionValue)"
    >
      <span v-if="count !== undefined" class="count">{{ count }}</span>
      {{ label }}
    </span>
  </span>
</template>

<script>
export default {
  props: ['options', 'modelValue'],
}
</script>

<style scoped>
.switch {
  cursor: pointer;
  margin-left: 1em;
  position: relative;
}

.switch:hover {
  background-color: #75adbe;
}

.options:hover .count {
  display: block;
}

.count {
  display: none;
  position: absolute;
  top: -10px;
  right: -5px;
  font-size: 0.8em;
  line-height: 12px;
  border-radius: 5px;
  background: white;
  color: black;
  border: 1px solid #337ab7;
  padding: 0 5px;
}
</style>
