<template>
  <div class="panel tl-body" :class="{ expanded: display }">
    <div class="title">
      <h4 data-role="item-title">
        Nieuws {{ hour }} <small v-if="hasNews">aangepast op: {{ modified }}</small>
      </h4>

      <div class="pull-right actions">
        <a
          href="#"
          class="btn btn-with-icon btn-default toggle-icon"
          :class="toggleClosedClass"
          @click.prevent="toggle"
        >
          <i class="fa fa-sort-down" />
          Toon Nieuws
        </a>

        <RefreshButton @click="refresh" />
      </div>
    </div>

    <div class="description" data-role="item-description">
      <p v-if="newsReader" class="by-newsreader">door {{ newsReader }}</p>
      <template v-if="display">
        <template v-if="hasNews">
          <div v-for="newsItem in currentNews" :key="newsItem.uid">
            <h3 v-if="newsItem.title">{{ newsItem.title }}</h3>
            <div v-html="newsItem.text" />
            <hr />
          </div>
        </template>
        <div v-else>
          <br />
          {{ noNewsText }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/store/API'
import { format, parseISO } from 'date-fns'
import RefreshButton from '@/general/components/RefreshButton.vue'

export default {
  name: 'ScheduleNews',
  components: {
    RefreshButton,
  },
  props: ['newsReader'],
  data() {
    return {
      display: false,
      newsBlock: null,
      loading: false,
    }
  },
  computed: {
    modified() {
      return this.newsBlock ? format(parseISO(this.newsBlock.modified), 'HH:mm:ss') : ''
    },
    hour() {
      return this.newsBlock && this.newsBlock.hour
    },
    currentNews() {
      return this.newsBlock && this.newsBlock.news
    },
    toggleClosedClass() {
      return this.display ? '' : 'toggle-closed'
    },
    hasNews() {
      return this.currentNews && this.currentNews.length
    },
    noNewsText() {
      return this.loading ? 'Nieuws aan het laden...' : 'Geen nieuws beschikbaar'
    },
  },
  methods: {
    toggle() {
      if (this.display) {
        this.display = false
      } else {
        this.display = true
        this.refresh()
      }
    },
    indexOfNewsItem() {
      const broadcastItemNews = this.$el.closest('.hour').querySelectorAll('.broadcast-item-news')
      const currentBroadcastItemNews = this.$el.closest('.broadcast-item-news')
      return Array.prototype.indexOf.call(broadcastItemNews, currentBroadcastItemNews)
    },
    isHalfHourItem() {
      return this.indexOfNewsItem() > 0
    },
    getNewsTime() {
      const time = this.$el.closest('[data-hour]').getAttribute('data-hour')

      if (this.isHalfHourItem()) {
        return time.replace(':00:00', ':30:00')
      }

      return time
    },
    async refresh() {
      if (this.loading) return
      this.loading = true

      const response = await api.get('/production/api/news.json', { params: { time: this.getNewsTime() } })
      this.newsBlock = response.data
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.broadcast-item-news {
  .expanded .by-newsreader {
    margin-bottom: 1em;
  }

  h3 {
    font-size: 15px;
    margin: 0;
    font-weight: bold;
  }
  p {
    font-size: 15px;
  }
  .ql-color-bg__tealmarker {
    background: #299688;
    color: white;
    padding: 1px 5px;
    margin: 3px 0px;
    display: inline-block;
  }
  .mark-audio,
  .ql-color-bg__redmarker {
    display: inline-block;
    background: #f65722;
    color: white;
    padding: 1px 5px;
    margin: 3px 0px;
  }
}
</style>
