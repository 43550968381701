<template>
  <div class="panel tl-body">
    <div class="title">
      <h4 data-role="item-title">Verkeer</h4>

      <div class="pull-right actions">
        <a
          href="#"
          class="pull-right toggle-icon btn btn-with-icon btn-default"
          :class="!active && 'toggle-closed'"
          icon="sort-down"
          @click.prevent="active = !active"
        >
          <i class="fa fa-sort-down avoid-clicks" />
          Toon verkeer
        </a>
      </div>
    </div>
    <component :is="trafficComponent" v-if="active" class="traffic" />
  </div>
</template>

<script>
import TrafficNL from './TrafficNL.vue'
import TrafficBE from './TrafficBE.vue'

export default {
  components: { TrafficNL, TrafficBE },
  props: { country: String },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    trafficComponent() {
      return this.country === 'be' ? TrafficBE : TrafficNL
    },
  },
}
</script>

<style scoped>
.traffic {
  background: #edece7;
  margin: 0 -10px;
  padding: 10px;
}
</style>
