import PhoneCallModal from './PhoneCallModal.vue'
import { renderVueComponent } from '../main'

function initListeners() {
  document.addEventListener('click', (event) => {
    const elem = event.target.closest('[data-type=mobile]')

    if (elem) {
      event.preventDefault()
      loadVueComponent(elem)
    }
  })
}

function loadVueComponent(elem) {
  const { name, number, gigyaId } = elem.dataset
  const container = document.createElement('div')
  document.body.append(container)

  const app = renderVueComponent(container, PhoneCallModal, {
    name,
    initialNumber: number,
    gigyaId,
    onClose: () => app.unmount(),
  })
}

initListeners()
