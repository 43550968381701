<template>
  <div>
    <div v-if="minutesAgoRefreshed >= 5" class="alert alert-danger">
      <strong>Let op:</strong>
      De informatie hieronder is langer dan {{ minutesAgoRefreshed }} minuten oud.
      <button class="btn btn-with-icon btn-default" :class="{ 'btn-success': loading }" @click="load">
        <i class="fa fa-refresh avoid-clicks" :class="{ 'fa-spin': loading }" />
      </button>
    </div>

    <div v-if="error" class="alert alert-danger">
      <strong>Oeps:</strong>
      {{ error }}
    </div>

    <div class="text-right actions">
      <span v-if="lastRefresh">laatst vernieuwd: {{ lastRefreshAgo }}</span>
      <OptionSelector v-model="majorRoadsOnly" :options="majorRoadsOnlyOptions" />
      <button class="btn btn-with-icon btn-default" :class="loading && 'btn-success'" @click="load">
        <i class="fa fa-refresh avoid-clicks" :class="loading && 'fa-spin'" />
      </button>
    </div>

    <slot />
  </div>
</template>

<script>
import axios from 'axios'
import formatDateDistance from 'date-fns/formatDistance'
import nlDateLocale from 'date-fns/locale/nl'
import { useNow } from '@/general/utils/useNow'
import OptionSelector from './OptionSelector.vue'
import socket from '../store/PublicSocket'

export default {
  name: 'TrafficBase',
  components: { OptionSelector },
  props: {
    path: {
      type: String,
      default: '/production/traffic.json',
    },
  },
  setup() {
    const currentDate = useNow()

    return { currentDate }
  },
  data() {
    return {
      loading: false,
      error: null,
      lastRefresh: undefined,

      items: [],
      ignores: [],

      majorRoadsOnly: 'true',

      majorRoadsOnlyOptions: {
        true: { label: 'Alleen hoofdwegen' },
        '': { label: 'Alle wegen' },
      },
    }
  },
  computed: {
    minutesAgoRefreshed() {
      return Math.round((this.currentDate - this.lastRefresh) / 1000 / 60)
    },
    lastRefreshAgo() {
      return formatDateDistance(this.lastRefresh, this.currentDate, { addSuffix: true, locale: nlDateLocale })
    },
  },
  watch: {
    majorRoadsOnly() {
      this.$emit('majorRoadsOnlyChange', this.majorRoadsOnly === 'true')
    },
  },
  mounted() {
    this.load()
    this.subscribe()
  },
  methods: {
    emitItems() {
      this.$emit(
        'items',
        this.items.map((item) => ({ ...item, ignored: this.ignores.includes(item.id) }))
      )
    },

    async load() {
      this.loading = true
      try {
        const { data } = await axios.get(this.path)
        this.items = data.traffic
        this.ignores = data.traffic_ignores
        this.emitItems()

        this.lastRefresh = new Date()
        this.error = null
      } catch (error) {
        this.error = `De verkeerinformatie kon niet worden geladen. (${error.message})`
        this.lastRefresh = undefined
      } finally {
        this.loading = false
      }
    },

    async subscribe() {
      ;(await socket())
        .subscribe('traffic_ignores')
        .on('create', ({ id }) => {
          this.ignores = [...this.ignores, id]
          this.emitItems()
        })
        .on('destroy', ({ id }) => {
          this.ignores = this.ignores.filter((ignore) => ignore !== id)
          this.emitItems()
        })
    },
  },
}
</script>

<style scoped>
.actions {
  margin-bottom: 0.5rem;
}
</style>
