<template>
  <div class="row">
    <div class="col-sm-7 form-group">
      <label class="string control-label" for="broadcast_item_name">Omschrijving</label>
      <TrackAutocomplete
        v-if="isTrackItem"
        :modelValue="name"
        type="TrackCode"
        @change="$emit('update:name', $event.target.value)"
        @selected="$emit('update:track', $event)"
      />
      <input
        v-else
        id="broadcast_item_name"
        :value="name"
        class="string form-control"
        name="name"
        @change="$emit('update:name', $event.target.value)"
      />
    </div>
    <div class="col-sm-2 form-group">
      <label class="string required control-label"><abbr title="required">*</abbr> Type</label>
      <select
        id="broadcast-item-type"
        :value="itemType"
        class="select required form-control"
        @change="$emit('update:itemType', $event.target.value)"
      >
        <option v-for="option in broadcastTypes" :key="option.key" :value="option.key">{{ option.content }}</option>
      </select>
    </div>
    <div class="col-sm-3 form-group">
      <label class="string control-label">Status</label>
      <select
        id="broadcast_item_status_type"
        :value="state"
        class="select form-control"
        @change="$emit('update:state', $event.target.value)"
      >
        <option v-for="option in broadcastStates" :key="option.key" :value="option.key">{{ option.content }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TrackAutocomplete from '../dario-autocomplete/TrackAutocomplete.vue'

export default {
  components: { TrackAutocomplete },
  props: ['name', 'itemType', 'state', 'track'],
  emits: ['update:name', 'update:itemType', 'update:state', 'update:track'],
  data() {
    return {}
  },
  computed: {
    ...mapState('BroadcastItems', ['broadcastTypes', 'broadcastStates']),
    isTrackItem() {
      return this.itemType === 'track'
    },
  },
  mounted() {
    this.getBroadcastStates()
    this.getBroadcastTypes()
  },
  methods: {
    ...mapActions({
      fetchBroadcastTypes: 'BroadcastItems/fetchBroadcastTypes',
      fetchBroadcastStates: 'BroadcastItems/fetchBroadcastStates',
    }),
    getBroadcastStates() {
      if (this.broadcastStates.length === 0) {
        this.fetchBroadcastStates()
      }
    },
    getBroadcastTypes() {
      if (this.broadcastTypes.length === 0) {
        this.fetchBroadcastTypes()
      }
    },
  },
}
</script>
