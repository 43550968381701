<template>
  <div class="modal-mask" @mousedown.self="prepareClose" @mouseup="closeWhenSelf">
    <div class="modal-wrapper" @mousedown.self="prepareClose" @mouseup="closeWhenSelf">
      <div class="modal-dialog" :class="dialogSizeClass">
        <div class="modal-content">
          <div v-if="!hideHeader" class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" @click.self="$emit('close')">
              ×
            </button>

            <slot name="header" />
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApiModal',
  props: ['size', 'hideHeader'],
  emits: ['close'],
  data() {
    return {
      wantsToClose: false,
    }
  },
  computed: {
    dialogSizeClass() {
      return this.size ? `modal-dialog-${this.size}` : ''
    },
  },
  mounted() {
    document.addEventListener('keyup', this.closeOnEscape)
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.closeOnEscape)
  },
  methods: {
    // User clicked the backdrop directly. Prepare to close the modal on mouseup.
    prepareClose() {
      this.wantsToClose = true
    },
    // User released the mouse button. If the user clicked the backdrop, close the modal, otherwise reset the flag.
    closeWhenSelf(e) {
      if (e.target == e.currentTarget && this.wantsToClose) {
        this.$emit('close')
      } else {
        this.wantsToClose = false
      }
    },
    closeOnEscape(event) {
      if (event.key === 'Escape') {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-dialog {
  width: 800px;
}

.modal-dialog-large {
  width: 80vw;
  height: 80vh;
}

.modal-content {
  height: 100%;
  max-height: 80vh;
  overflow: scroll;
}
</style>
