<template>
  <div class="delay-selector">
    <OptionSelector v-model="internalModelValue" class="pull-right" :options="delayThresholdOptions" />
    <input v-model="internalModelValue" class="input-xs" min="0" size="2" type="number" />
  </div>
</template>

<script>
import OptionSelector from '@/traffic/OptionSelector.vue'

export default {
  name: 'DelaySelector',
  components: { OptionSelector },
  props: {
    traffic: {
      type: Array,
      required: true,
    },
    delayThresholds: {
      type: Array,
      required: true,
    },
    showSuffix: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      internalModelValue: this.modelValue,
    }
  },
  computed: {
    delayThresholdOptions() {
      return {
        0: { label: 'Alle', count: this.traffic.length },
        ...Object.fromEntries(
          this.delayThresholds.map((threshold) => [
            threshold,
            {
              label: `> ${threshold}${this.showSuffix ? ' min' : ''}`,
              count: this.traffic.filter((item) => item.delay >= threshold).length,
            },
          ])
        ),
      }
    },
  },
  watch: {
    modelValue(newValue) {
      this.internalModelValue = newValue
    },
    internalModelValue(newValue) {
      this.$emit('update:modelValue', newValue)
    },
  },
}
</script>

<style scoped>
.delay-selector {
  float: right;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;

  input {
    width: 50px;
    margin-left: 10px;
    text-align: right;
  }
}
</style>
