import api from './API'

export default {
  namespaced: true,
  state: {
    tags: [],
  },
  getters: {
    find: (state) => (id) => {
      return state.tags.filter((tag) => tag.id === id)[0]
    },
    indexOf: (state) => (id) => {
      return state.tags.findIndex((tag) => tag.id === id)
    },
    query: (state, getters) => (query) => {
      return getters.sorted.filter((tag) => ('' + tag.name).toLowerCase().match(('' + query).toLowerCase()))
    },
    findByName: (state) => (name) => {
      return state.tags.filter((tag) => tag.name === name)[0]
    },
    sorted: (state) => {
      const tags = state.tags.slice()
      tags.sort((a, b) => b.personal_favorite - a.personal_favorite)
      return tags
    },
  },
  mutations: {
    add(state, colorTag) {
      // Never add a color tag twice
      if (!state.tags.find((tag) => tag.id === colorTag.id)) {
        state.tags.push(colorTag)
      }
    },
    destroy(state, colorTagId) {
      state.tags = state.tags.filter((tag) => tag.id !== colorTagId, 1)
    },
    update(state, [tagIndex, attributes]) {
      const tag = state.tags[tagIndex]
      tag.name = attributes.name
      tag.color = attributes.color
    },
    setTags(state, tags) {
      state.tags = tags
    },
    startedFetching(state) {
      state.startedFetching = true
    },
    setPersonalFavorite(state, [tagId, favorite]) {
      const tag = state.tags.find((tag) => tag.id === tagId)
      if (tag) {
        tag.personal_favorite = favorite
      }
    },
  },
  actions: {
    create({ commit }, { tag, sockets }) {
      return new Promise((resolve) => {
        if (sockets) {
          commit('add', tag)
          resolve(tag)
        } else {
          api.post('/production/messages/color_tags.json', { color_tag: tag }).then((response) => {
            const tag = response.data
            commit('add', tag)
            resolve(tag)
          })
        }
      })
    },
    destroy({ commit }, { tag, sockets }) {
      commit('destroy', tag.id)
      if (!sockets) {
        api.delete(`/production/messages/color_tags/${tag.id}`)
      }
    },
    update({ commit, getters }, [colorTagId, attributes]) {
      const tagIndex = getters.indexOf(colorTagId)
      if (tagIndex > -1) {
        commit('update', [tagIndex, attributes])
      }
      api.put(`/production/messages/color_tags/${colorTagId}`, { color_tag: attributes })
    },
    updateWithoutApiCall({ commit, getters }, [colorTagId, attributes]) {
      const tagIndex = getters.indexOf(colorTagId)
      if (tagIndex) {
        commit('update', [tagIndex, attributes])
      }
    },
    fetchTags({ commit, state }) {
      if (state.startedFetching) return
      commit('startedFetching')
      api.get('/production/messages/color_tags.json').then((response) => {
        commit('setTags', response.data.color_tags)
      })
    },
    async togglePersonalFavorite({ commit, getters }, [tagId]) {
      const tag = getters.find(tagId)
      if (!tag) {
        throw new Error(`ColorTag ${tagId} not found.`)
      }
      const newState = !tag.personal_favorite
      commit('setPersonalFavorite', [tagId, newState])

      try {
        if (newState) {
          await api.post(`/production/messages/color_tags/${tag.id}/favorites`)
        } else {
          await api.delete(`/production/messages/color_tags/${tag.id}/favorites`)
        }
      } catch (e) {
        console.error(e)
        // Revert the change if the API call fails
        commit('setPersonalFavorite', [tagId, !newState])
        alert(this.$t('production.messages.color_tags.failed_favoriting'))
      }
    },
  },
}
