<template>
  <div class="text-and-media">
    {{ comment.text }}
    <PreviewVideo v-if="hasVideo" :video="comment.media" />
    <PreviewImage v-if="hasImage" :image="comment.media" />
    <PreviewAudio v-if="hasAudio" :audio="comment.media" />
  </div>
</template>

<script>
import PreviewVideo from './preview/PreviewVideo.vue'
import PreviewImage from './preview/PreviewImage.vue'
import PreviewAudio from './preview/PreviewAudio.vue'

export default {
  name: 'CommentTextAndMedia',
  components: {
    PreviewVideo,
    PreviewImage,
    PreviewAudio,
  },
  props: {
    comment: Object,
  },
  computed: {
    hasVideo() {
      return this.comment.media && this.comment.media.type === 'video'
    },
    hasImage() {
      return this.comment.media && this.comment.media.type === 'image'
    },
    hasAudio() {
      return this.comment.media && this.comment.media.type === 'audio'
    },
  },
}
</script>

<style scoped>
.text-and-media {
  padding-right: 80px;
}
</style>
