<template>
  <ul v-if="totalPages > 1" class="pagination">
    <li class="prev" :class="{ disabled: currentPage == 1 }">
      <a href="#" rel="prev" @click.prevent="changePage(currentPage - 1)">Vorige</a>
    </li>
    <li
      v-for="{ page, active, gap } in pages"
      :key="page || gap"
      class="page"
      :class="{ active: active, gap: gap, disabled: gap }"
    >
      <a v-if="page" href="#" @click.prevent="changePage(page)">{{ page }}</a>
      <a v-if="gap" href="#" @click.prevent>…</a>
    </li>
    <li class="next" :class="{ disabled: currentPage === totalPages }">
      <a href="#" rel="next" @click.prevent="changePage(currentPage + 1)">Volgende</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ApiPagination',
  props: {
    currentPage: Number,
    totalPages: Number,
    onChangePage: Function,
  },
  emits: ['changePage'],
  computed: {
    pages() {
      let pages = []
      let curPage = this.currentPage

      for (let i = 1; i <= this.totalPages; i++) {
        if (Math.abs(curPage - i) < 3) {
          // Inside the active window
        } else if (i < 3 || i > this.totalPages - 2) {
          // Beginning and end
        } else {
          // We don't render these
          continue
        }
        pages.push({ active: curPage === i, page: i })
      }

      // Fill in the gaps
      let filledPages = []
      for (let i = 0; i < pages.length; i++) {
        filledPages.push(pages[i])
        if (pages[i + 1] && pages[i + 1].page - pages[i].page > 1) {
          filledPages.push({ gap: true })
        }
      }

      return filledPages
    },
  },
  methods: {
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        if (this.onChangePage) {
          this.$emit('changePage', page)
        } else {
          const url = new URL(window.location.href)
          url.searchParams.set('page', page)
          window.location.href = url.toString()
        }
      }
    },
  },
}
</script>
