<template>
  <div class="row">
    <div class="col-sm-4">
      <div class="panel">
        <table class="table table-hover">
          <tbody>
            <tr>
              <th>Bedrag</th>
              <td>{{ amount }}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{{ state }}</td>
            </tr>
            <tr>
              <th>Snelheid</th>
              <td>{{ speedDisplay }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmountCaster',
  props: ['speeds'],
  data() {
    return {
      amount: 0,
      state: '',
      speed: '',
    }
  },
  computed: {
    speedDisplay() {
      return this.speed ? this.speeds[this.speed].label : null
    },
  },
  mounted() {
    window.QSocket?.ready((e, q) => {
      q.subscribe('amount_caster').on(
        'change',
        (amountCasting) => {
          this.amount = amountCasting.amount_display
          this.state = amountCasting.state
          this.speed = amountCasting.speed
        },
        { backlog: 1 }
      )
    })
  },
}
</script>
