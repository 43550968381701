export default {
  namespaced: true,
  state: {
    pauseFunctions: {},
  },
  mutations: {
    setPauseFunction(state, { playerId, pauseFunction }) {
      state.pauseFunctions[playerId] = pauseFunction
    },
    removePauseFunction(state, { playerId }) {
      delete state.pauseFunctions[playerId]
    },
  },
  actions: {
    markAsPlaying({ state, commit }, { playerId, pauseFunction }) {
      // Pause any other player
      Object.entries(state.pauseFunctions)
        .filter(([otherplayerId]) => otherplayerId !== playerId)
        .forEach(([, pause]) => pause())

      commit('setPauseFunction', { playerId, pauseFunction })
    },
    markAsStopped({ commit }, { playerId }) {
      commit('removePauseFunction', { playerId })
    },
  },
}
