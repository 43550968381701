/*
This plugin is derived from the code of TipTap. https://github.com/ueberdosis/tiptap/blob/6f218be6e439603c85559c6d77ec93a205003bf5/packages/extension-bullet-list/src/bullet-list.ts
We chose to create our own implementation instead of using the standard package because the standard package does not include the formatting of the previous line, 
which is something we want to retain.

+ it interpreted a '+' as the start of a bullet list.
*/

import { mergeAttributes, Node, wrappingInputRule } from '@tiptap/core'

export const inputRegex = /^\s*(\*)\s$/

export const BulletList = Node.create({
  name: 'bulletList',

  addOptions() {
    return {
      itemTypeName: 'listItem',
      HTMLAttributes: {},
    }
  },

  group: 'block list',

  content() {
    return `${this.options.itemTypeName}+`
  },

  parseHTML() {
    return [{ tag: 'ul' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['ul', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      toggleBulletList:
        () =>
        ({ chain }) => {
          return chain().toggleList(this.name, this.options.itemTypeName, true).run()
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-8': () => this.editor.commands.toggleBulletList(),
    }
  },

  addInputRules() {
    let inputRule = wrappingInputRule({
      find: inputRegex,
      type: this.type,
    })

    inputRule = wrappingInputRule({
      find: inputRegex,
      type: this.type,
      keepMarks: true,
      keepAttributes: true,
      editor: this.editor,
    })
    return [inputRule]
  },
})
