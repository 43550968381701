<!-- eslint-disable vue/no-mutating-props -->
<!-- This should be fixed in the future, however, I did not include a fix for this in the introduction of this eslint rule. -->

<template>
  <div>
    <input :id="prize.id" v-model="prize.checked" type="checkbox" :value="prize.id" :disabled="!prize.optional" />
    <label :for="prize.id">{{ prize.name }}</label>

    <div v-if="prize.checked && prize.distributionType === 'arbitrary'" class="distribution-message">
      <div class="input-group">
        <span class="input-group-addon">€</span>
        <input
          v-model="prize.totalPrizeValue"
          placeholder="Vul hier het bedrag in"
          class="form-control"
          type="number"
          step="any"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Prize',
  props: {
    prize: Object,
  },
}
</script>
