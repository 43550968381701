<template>
  <div class="broadcast_item tl-entry details" :class="containerClasses">
    <div class="panel tl-body">
      <div class="scenario-form">
        <div class="row">
          <div class="col-sm-11">
            <MGPForm v-if="isMGPItem" v-model="item.mgpBlock" />
            <FormHeader
              v-else-if="showFormHeader"
              v-model:name="item.name"
              v-model:itemType="item.item_type"
              v-model:state="item.state"
              v-model:track="item.track"
            />

            <div class="row">
              <div class="col-sm-12">
                <label class="string required control-label">Details</label>
                <ScenarioEditor
                  ref="wysiwyg"
                  v-model="item.description"
                  :identifier="wysiwgIdentifier"
                  :factsUrl="factsUrl"
                  :loading="loading"
                  @update:modelValue="scenarioChange"
                />
              </div>
            </div>
            <div v-if="referencesAddable" class="row">
              <div class="col-sm-12">
                <ContactDetails
                  v-for="{ reference_id } in visibleReferences"
                  :id="reference_id"
                  :key="reference_id"
                  @remove="removeReference(reference_id)"
                />

                <button type="button" class="btn btn-lg btn-labeled" @click="openContactModal">
                  <span class="btn-label icon fa fa-user" />Voeg contactpersoon toe
                </button>
              </div>
            </div>

            <PollForm v-if="isPollItem" v-model="item.poll" :pollType="pollType" />
          </div>
          <div class="col-sm-1">
            <div class="btn-group-vertical pull-right">
              <DarioButton type="default" icon="close" :confirm="closeConfirmText" @click="closeWithoutSaving" />
              <DarioButton type="success" icon="save" :loading="saving" @click="saveItem" />
              <DarioButton
                v-if="isExistingItem"
                type="danger"
                icon="trash"
                confirm="Weet je zeker dat je dit item wilt verwijderen?"
                @click="removeItem"
              />
              <DropdownButton v-if="isExistingItem" position="right" icon="gear">
                <a :href="editPageUrl" class="menu-item">Aanpassen</a>
                <ScenarioVotes v-if="showVotes" :editionId="editionId" :track="track" @addToWysiwyg="addToWysiwyg" />
                <a v-if="isBroadcastItem" :href="makeProgramItemPageUrl" class="menu-item">Maak vast item</a>
                <ScenarioRatings
                  v-if="isTrackItemWithRelatedTrack"
                  :trackTitle="item.name"
                  :broadcastItemId="item.id"
                />
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactDetails from './ContactDetails.vue'
import PollForm from './PollForm.vue'
import MGPForm from './MGPForm.vue'
import FormHeader from './FormHeader.vue'
import {
  fetchBroadcastItem,
  liveUpdateBroadcastItem,
  removeBroadcastItem,
  saveBroadcastItem,
  createBroadcastItem,
  startEditing,
  stopEditing,
  getBroadcastItemHtml,
} from './broadcastItemClient'
import DarioButton from '../general/components/DarioButton.vue'
import DropdownButton from '../general/components/DropdownButton.vue'
import ScenarioRatings from '../ratings/ScenarioRatings.vue'
import { ScenarioEditor } from '../general/components/Editor'
import ScenarioVotes from '../vote-list/ScenarioVotes.vue'
export default {
  name: 'InlineForm',
  components: {
    DropdownButton,
    DarioButton,
    FormHeader,
    MGPForm,
    PollForm,
    ContactDetails,
    ScenarioRatings,
    ScenarioEditor,
    ScenarioVotes,
  },
  props: ['resourceId', 'resourceType', 'showUrl', 'itemId', 'itemType', 'hour'],
  emits: ['close'],
  data() {
    return {
      item: {
        id: this.itemId,
        type: this.itemType || 'broadcast-item',
        description: '',
        item_type: 'conversation',
        status: 'draft',
        references: [],
        hour: this.hour,
      },
      wasChanged: false,
      wasRemoved: false,
      saving: false,
      loading: false,
    }
  },
  computed: {
    factsUrl() {
      if (this.isTrackItemWithRelatedTrack) {
        return `/production/tracks/${this.item.track.track_id}/facts`
      }

      return null
    },
    wysiwgIdentifier() {
      return this.item.id ? `${this.item.type}-${this.item.id}` : null
    },
    hasEdition() {
      return !!this.item.broadcast?.edition_id
    },
    isExistingItem() {
      return !!this.showUrl
    },
    isBroadcastItem() {
      return this.item.type === 'broadcast-item'
    },
    isPollItem() {
      return this.item.item_type === 'poll' || this.item.item_type === 'music-poll'
    },
    isMGPItem() {
      return this.item.item_type === 'mgp'
    },
    isTrackItem() {
      return this.item.item_type === 'track'
    },
    track() {
      return this.item.track
    },
    isTrackItemWithRelatedTrack() {
      return this.isTrackItem && this.track
    },
    pollType() {
      return this.item.item_type
    },
    editPageUrl() {
      return `${this.showUrl}/edit`
    },
    referencesAddable() {
      return !!this.item.references
    },
    makeProgramItemPageUrl() {
      return `/production/programs/${this.item.broadcast?.program_id}/broadcast_items/new?broadcast_item_id=${this.item.id}`
    },
    closeConfirmText() {
      if (this.wasChanged) {
        return 'Weet je zeker dat je dit item wilt sluiten? Je hebt nog niet opgeslagen.'
      } else {
        return null
      }
    },
    showVotes() {
      return this.isTrackItem && !!this.editionId
    },
    editionId() {
      return this.item.broadcast?.edition_id
    },
    containerClasses() {
      return {
        reordable: !!this.showUrl,
      }
    },
    showFormHeader() {
      return this.isBroadcastItem
    },
    visibleReferences() {
      return this.item.references?.filter((r) => !r._destroy)
    },
  },
  async mounted() {
    if (this.showUrl) {
      // show a loading text while we fetch the item
      this.loading = true
      this.item = await fetchBroadcastItem(this.showUrl)
      if (this.isBroadcastItem) {
        startEditing(this.showUrl)
      }
      this.loading = false
    }
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
  },
  unmounted() {
    if (this.showUrl && !this.wasRemoved) {
      stopEditing(this.showUrl)
    }
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  },
  methods: {
    async removeItem() {
      await removeBroadcastItem(this.showUrl)
      this.wasRemoved = true
      this.$emit('close')
    },
    async saveItem() {
      this.saving = true
      try {
        if (this.showUrl) {
          await this.updateItem()
        } else {
          await this.createItem()
        }
      } catch (e) {
        console.error(e)
        alert(this.$t('production.broadcast_items.error'))
      } finally {
        this.saving = false
      }
    },
    async createItem() {
      const before = this.$el.nextElementSibling
      const after = this.$el.previousElementSibling
      const data = await createBroadcastItem(this.resourceId, this.item, before, after)

      // Removing the element here because our socket responds faster than the ajax call, so to avoid a double element we need to delete it now and trust the socket to recreate it.
      if (data.valid) {
        this.$el.remove()
        this.$emit('close')
      } else {
        this.showErrors(data.errors)
      }
    },
    async updateItem() {
      const data = await saveBroadcastItem(this.showUrl, this.item)

      if (data.valid) {
        this.$el.outerHTML = data.html
        this.$emit('close')
      } else {
        this.showErrors(data.errors)
      }
    },
    showErrors(errors) {
      const errorMessages = Object.entries(errors)
        .map(([field, error]) => `${field}: ${error.join(', ')}`)
        .join('\n')
      alert(`Er zijn fouten gevonden:\n${errorMessages}`)
    },
    scenarioChange(val, { ownUpdate }) {
      if (ownUpdate) {
        this.wasChanged = true
      }
      if (this.showUrl && this.isBroadcastItem && ownUpdate) {
        liveUpdateBroadcastItem(this.showUrl, val)
      }
    },
    async closeWithoutSaving() {
      if (this.showUrl) {
        this.$el.outerHTML = await getBroadcastItemHtml(this.showUrl)
      } else {
        this.$el.remove()
      }

      this.$emit('close')
    },
    openContactModal() {
      const modal = new window.QAPI.Views.Production.SearchContactModalView()
      modal.render()
      modal.on('contact:selected', (contact) => {
        this.item.references.push({ reference_id: contact.id, reference_type: 'Contact' })
        modal.hide()
      })
    },
    removeReference(id) {
      const reference = this.item.references.find((r) => r.reference_id === id)
      if (reference.id) {
        // Set _destroy to true so that the server knows to remove the reference
        reference._destroy = true
      } else {
        // Newly added references don't have an id, so we can just remove them from the array
        this.item.references = this.item.references.filter((r) => r.reference_id !== id)
      }
    },
    addToWysiwyg(html) {
      this.$refs.wysiwyg.appendContent(html)
    },
    beforeUnloadHandler(event) {
      if (this.wasChanged) {
        event.preventDefault()
        // None of the browsers will show this text anymore, they'll just show a generic popup.
        return 'Weet je zeker dat je dit item wilt sluiten? Je hebt nog niet opgeslagen.'
      } else {
        return null
      }
    },
  },
}
</script>

<style lang="scss">
.row {
  margin-top: 5px;
}

.scenario-form {
  min-height: 50px;
  margin: 5px;
}
</style>
