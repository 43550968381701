<template>
  <div class="panel" :class="headerColorClass">
    <div v-if="showHeader" class="panel-heading" @click="togglePanelBody">
      <span class="panel-title">
        <slot name="title">
          {{ title }}
        </slot>
      </span>
      <div class="panel-heading-controls">
        <div v-if="collapsable" class="panel-heading-icon panel-heading-link">
          <i v-if="open" class="fa fa-chevron-up" />
          <i v-else class="fa fa-chevron-down" />
        </div>
      </div>
    </div>

    <template v-if="rawBody && open">
      <slot />
    </template>

    <div v-if="!rawBody && open" class="panel-body list-group">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    title: String,
    headerColor: String,
    collapsable: {
      type: Boolean,
      default: false,
    },
    defaultOpen: {
      type: Boolean,
      default: true,
    },
    rawBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: this.defaultOpen,
    }
  },
  computed: {
    showHeader() {
      return this.title || this.$slots.title || this.collapsable
    },
    iconClass() {
      return `fa-${this.icon}`
    },
    headerColorClass() {
      return this.headerColor && `panel-${this.headerColor}`
    },
  },
  methods: {
    togglePanelBody() {
      if (!this.collapsable) {
        return
      }
      this.open = !this.open
    },
  },
}
</script>
