<template>
  <div class="menu-item">
    <div class="menu-item-link" @click="showVotesModal = true">Toon votes</div>
    <Teleport to="body">
      <VotesModal
        v-if="showVotesModal"
        :editionId="editionId"
        :track="track"
        @openVoterDetail="openVotesDetail"
        @close="showVotesModal = false"
        @voteSelected="voteSelected"
      />
    </Teleport>
  </div>
</template>

<script>
import VotesModal from './VotesModal.vue'

export default {
  name: 'ScenarioVotes',
  components: {
    VotesModal,
  },
  props: ['editionId', 'track'],
  data() {
    return {
      showVotesModal: false,
      votesDetailId: null,
    }
  },
  methods: {
    voteSelected(vote) {
      const html = `
        <p>
          <strong>${vote.profile.name} (${vote.profile.mobile ?? ''})</strong>: 
          ${vote.comment ?? ''}
        </p>
      `

      vote.voteSelected = true

      this.$emit('addToWysiwyg', html)
    },
  },
}
</script>

<style scoped>
.menu-item-link {
  width: 100%;
}
</style>
