<template>
  <wysiwygEditor ref="wysiwigEditor" :identifier="identifier" :loading="loading">
    <MenuItem v-if="factsUrl" title="Facts" icon="globe" @click="openFacts" />
  </wysiwygEditor>
</template>

<script>
import MenuItem from './MenuItem.vue'
import WysiwygEditor from './WysiwygEditor.vue'

export default {
  components: {
    MenuItem,
    WysiwygEditor,
  },
  props: ['factsUrl', 'identifier', 'loading'],
  methods: {
    appendContent(content) {
      this.$refs.wysiwigEditor.appendContent(content)
    },
    async openFacts() {
      const view = new window.QAPI.Views.Production.ArtistFactsModalView({
        url: this.factsUrl,
      })
      view.on('loved', ({ contentHTML }) => this.appendContent(contentHTML))
      view.render()
    },
  },
}
</script>
