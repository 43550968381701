<template>
  <div class="row wrapper">
    <div class="col-sm-6 locations-col">
      <div class="panel no-margin-b">
        <div
          class="panel-heading"
          v-text="$t('admin.location_trackers.show.last_x_locations', { count: latestLocations.length })"
        />
        <ul class="list-group">
          <li v-for="location in latestLocations" :key="location.id" class="list-group-item">
            <span v-text="`${location.lat}, ${location.long}`" />
            <span
              class="text-muted text-xs"
              style="margin-left: 5px"
              :title="location.recordedAtFormatted"
              v-text="location.recordedAtDistance"
            />

            <div class="list-group-right">
              <span class="badge" :title="location.recordedAtFormatted" v-text="location.recordedAtDistance" />
              <a
                :href="location.googleMapsURL"
                target="_blank"
                class="btn btn-link btn-xs"
                :title="$t('admin.location_trackers.show.open_google_maps')"
              >
                <i class="fa fa-external-link" />
              </a>
              <template v-if="location.speed">
                <span class="badge" v-text="`${location.speedKM} km/h`" />
              </template>
              <button
                class="btn btn-default btn-xs"
                :title="$t('admin.location_trackers.show.focus_location')"
                @click="focusLocation(location)"
              >
                <i class="fa fa-bullseye" />
              </button>
              <button
                class="btn btn-danger btn-xs delete-button"
                :title="$t('admin.location_trackers.show.delete_location')"
                @click="deleteLocation(location)"
              >
                <i class="fa fa-trash" />
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-sm-6 map-col">
      <div class="panel no-margin-b">
        <div class="panel-body no-padding">
          <l-map ref="map" class="map" :center="[0, 0]" :zoom="1" @ready="centerMap">
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layerType="base"
              name="OpenStreetMap"
            />
            <l-polyline :latLngs="latLongs" color="red" />
            <l-circle-marker
              v-for="(location, i) in locationsFormatted"
              :key="location.id"
              :latLng="location.latLong"
              :radius="i === 0 ? 6 : 3"
              color="red"
            >
              <l-popup>
                <dl>
                  <dt v-text="$t('admin.location_trackers.show.datetime')" />
                  <dd v-text="location.recordedAtFormatted" />
                  <template v-if="location.speed">
                    <dt v-text="$t('admin.location_trackers.show.speed')" />
                    <dd v-text="`${location.speedKM} km/h`" />
                  </template>
                </dl>

                <a
                  :href="location.googleMapsURL"
                  target="_blank"
                  class="btn btn-link btn-xs"
                  :title="$t('admin.location_trackers.show.open_google_maps')"
                >
                  <i class="fa fa-external-link" />
                </a>
                <button class="btn btn-danger btn-xs" @click="deleteLocation(location)">
                  <i class="fa fa-trash" />
                </button>
              </l-popup>
            </l-circle-marker>
          </l-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LCircleMarker, LPolyline, LPopup } from '@vue-leaflet/vue-leaflet'
import L from 'leaflet'
import api from '@/store/API'
import { format, formatDistance } from 'date-fns'
import { useNow } from '@/general/utils/useNow'

export default {
  name: 'LocationTrackerLocationMap',

  components: { LPopup, LPolyline, LCircleMarker, LMap, LTileLayer },

  props: {
    locationTracker: { type: Object, required: true },
    sortedLocations: { type: Array, required: true },
  },

  emits: ['location-deleted'],

  setup() {
    const now = useNow()

    return { now }
  },

  computed: {
    locationsFormatted() {
      return this.sortedLocations.map((location) => ({
        ...location,
        latLong: [location.lat, location.long],
        recordedAtFormatted: format(location.recordedAt, 'dd-MM-yyyy HH:mm:ss'),
        googleMapsURL: `https://www.google.com/maps/place/${location.lat},${location.long}`,
        speedKM: Math.round(location.speed * 3.6),
      }))
    },

    latLongs() {
      return this.locationsFormatted.map((location) => location.latLong)
    },

    latestLocations() {
      return this.locationsFormatted.slice(0, 10).map((location) => ({
        ...location,
        recordedAtDistance: formatDistance(location.recordedAt, this.now, {
          addSuffix: true,
        }),
      }))
    },
  },

  watch: {
    sortedLocations() {
      this.$nextTick(this.centerMap)
    },
  },

  created() {
    // We need the leaflet import in order for the LMap component to work (so assign it here to ensure the import remains in the bundle)
    this.leaflet = L
  },

  methods: {
    centerMap() {
      if (!this.latLongs.length) return

      const bounds = new L.LatLngBounds(this.latLongs)

      this.$refs.map.leafletObject.fitBounds(bounds)
    },

    deleteLocation(location) {
      api
        .delete(`/admin/location_trackers/${this.locationTracker.uuid}/location_tracker_locations/${location.id}`, {
          headers: { Accept: 'application/json' },
        })
        .then(() => this.$emit('location-deleted', location))
    },

    focusLocation(location) {
      this.$refs.map.leafletObject.setView(location.latLong, 15)
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: auto;

  .locations-col {
    flex: 1;
    min-width: 400px;

    .list-group-right {
      float: right;

      > *:not(:first-child) {
        margin-left: 5px;
      }
    }
  }

  .map-col {
    flex: 1;
    min-width: 500px;
    min-height: 400px;

    > .panel {
      height: 100%;

      > .panel-body {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
