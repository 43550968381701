import api from './API'

const withoutHiddenComments = (comment) => {
  return comment.deletedAt == null
}

const newComments = (comment) => {
  return comment.new
}

const withoutNewComments = (comment) => {
  return !comment.new
}

const withoutReplies = (comment) => {
  return !comment.parentId
}

const activeProfiles = (comment) => {
  return !comment.from.mutedAt
}

export default {
  namespaced: true,
  state: {
    comments: [],
    profileComments: [],
    content: {},
  },
  getters: {
    visibleComments(state) {
      return state.comments
        .filter(withoutHiddenComments)
        .filter(withoutNewComments)
        .filter(withoutReplies)
        .filter(activeProfiles)
    },
    visibleProfileComments(state) {
      return state.profileComments.filter(withoutHiddenComments)
    },
    newComments(state) {
      return state.comments.filter(newComments).filter(withoutReplies).filter(activeProfiles)
    },
    contentTitle: (state) => (contentId) => {
      let content = state.content[contentId]
      return content ? content.title : ''
    },
    contentUrl: (state) => (contentId) => {
      let content = state.content[contentId]
      return content ? window.userConfig.websiteUrl + content.url : ''
    },
    selectedComments(state) {
      return state.comments.filter((comment) => comment.selected)
    },
  },
  mutations: {
    addComments(state, { comments, newer }) {
      comments = comments.map((comment) => {
        return {
          selected: false,
          deletedAt: null,
          new: false,
          ...comment,
        }
      })

      if (newer) {
        // Prepend
        state.comments = comments.concat(state.comments)
      } else {
        // Append
        state.comments = state.comments.concat(comments)
      }
    },

    clearComments(state) {
      state.comments = []
    },

    addContentDetails(state, content) {
      content.forEach((item) => {
        state.content[item.id] = item
      })
    },

    toggleSelection(state, { selected, id }) {
      const comment = state.comments.find((comment) => comment.id === id)
      comment.selected = selected
    },

    toggleAll(state, { selected }) {
      state.comments.forEach((comment) => {
        comment.selected = selected
      })
    },

    showNewComments(state) {
      state.comments.forEach((comment) => {
        comment.new = false
      })
    },

    hideComments(state, ids) {
      const comments = state.comments.filter((comment) => ids.indexOf(comment.id) > -1)
      comments.forEach((comment) => {
        comment.deletedAt = new Date()
        comment.selected = false
      })
    },
    setProfileComments(state, { comments }) {
      state.profileComments = comments
    },
    addReply(state, { comment, reply }) {
      if (!comment.replies) {
        comment.replies = []
      }
      comment.replies.push(reply)
    },
  },
  actions: {
    async fetch({ commit, state, dispatch }, { older, newer, query }) {
      const params = {}

      if (!newer && !older) {
        commit('clearComments')
      }

      if (query) {
        params.q = query
      }

      if (older) {
        let [oldestComment] = state.comments.slice(-1)
        params.upto = oldestComment && oldestComment.id
      } else if (newer) {
        let newestComment = state.comments[0]
        params.since = newestComment && newestComment.id
      }

      const response = await api.get('/production/comments.json', { params })
      const comments = response.data.comments
      commit('addComments', { comments: comments, older, newer })
      dispatch('fetchMissingContent', comments)
    },

    async fetchMissingContent({ commit, state }, newComments) {
      const missingContentIds = newComments.reduce((content, comment) => {
        if (
          !Object.prototype.hasOwnProperty.call(state, comment.contentId) &&
          content.indexOf(comment.contentId) === -1
        ) {
          return [...content, comment.contentId]
        } else {
          return content
        }
      }, [])

      if (missingContentIds.length) {
        const response = await api.get('/2.4/content/timeline', { params: { ids: missingContentIds } })
        commit('addContentDetails', response.data.items)
      }
    },

    liveCommentsAdded({ commit, dispatch }, comment) {
      comment.new = true
      commit('addComments', { comments: [comment], newer: true })
      commit('addContentDetails', [comment])
      dispatch('fetchMissingContent', [comment])
    },

    toggleSelection({ commit }, { selected, id }) {
      commit('toggleSelection', { selected, id })
    },

    toggleAll({ commit }, { selected }) {
      commit('toggleAll', { selected })
    },

    showNewComments({ commit }) {
      commit('showNewComments')
    },

    hide({ commit }, { ids }) {
      api.put('/production/comments/hide', { ids: ids })
      commit('hideComments', ids)
    },

    async fetchProfileComments({ commit }, { profileId, contentId }) {
      const response = await api.get(`/production/profiles/${profileId}/comments`, {
        params: { content_id: contentId, limit: 15 },
      })
      const comments = response.data.comments
      commit('setProfileComments', { comments: comments })
    },

    reply({ commit }, { comment, reply }) {
      api.post(`/production/comments/${comment.id}/reply`, {
        reply: {
          content_id: reply.contentId,
          dj_id: reply.from.djId,
          content: reply.text,
        },
      })

      // new object need to have an id
      reply.id = Math.round(Math.random() * 999999999999)
      commit('addReply', { comment: comment, reply: reply })
    },
  },
}
