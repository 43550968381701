<template>
  <a href="#" class="position-info" @click.prevent="showDetails = true">
    <span class="position"># {{ position }}</span>
    <span class="movement" :class="changeClass"> <i class="fa" :class="changeIcon" />{{ change }}</span>
  </a>
  <ApiModal v-if="showDetails" @close="showDetails = false">
    <template #header>Track info</template>
    <div class="modal-body">
      <EditionTrackDetails :editionTrackId="editionTrackId" />
    </div>
  </ApiModal>
</template>

<script>
import ApiModal from '@/general/components/ApiModal.vue'
import EditionTrackDetails from '@/editions/EditionTrackDetails.vue'

export default {
  name: 'ScenarioEditionTrackInfo',
  components: { ApiModal, EditionTrackDetails },
  props: {
    editionTrackId: {
      type: Number,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
    previousPosition: {
      type: Number,
    },
    timesInList: {
      type: Number,
      required: true,
    },
    isReentry: {
      type: Boolean,
    },
  },
  data() {
    return {
      showDetails: false,
    }
  },
  computed: {
    isNew() {
      return this.timesInList === 1
    },
    changeIcon() {
      if (this.movement > 0) {
        return 'fa-angle-up'
      } else if (this.movement < 0) {
        return 'fa-angle-down'
      } else if (this.isNew || this.isReentry) {
        return ''
      } else {
        return 'fa-bars'
      }
    },
    changeClass() {
      if (this.movement > 0) {
        return 'movement__up'
      } else if (this.movement < 0) {
        return 'movement__down'
      } else if (this.isNew) {
        return 'movement__new'
      } else if (this.isReentry) {
        return 'movement__reentry'
      } else {
        return 'movement__eq'
      }
    },
    change() {
      if (this.isNew) {
        return 'NEW'
      } else if (this.isReentry) {
        return 'RE-ENTRY'
      } else {
        return Math.abs(this.movement)
      }
    },
    movement() {
      if (this.previousPosition === null) {
        return null
      } else {
        return this.previousPosition - this.position
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$border-radius: 4px;
.position-info {
  margin: 0 2px;
  color: #fff;

  .position {
    padding: 2px 4px 2px 6px;
    background-color: #a1a1a1;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    font-weight: 600;
  }

  .movement {
    padding: 2px 6px 2px 4px;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    &__up {
      background-color: #5ebd5e;
    }

    &__down {
      background-color: #ff4d4d;
    }

    &__new,
    &__reentry {
      background-color: #5bc0de;
    }

    &__eq {
      background-color: #f4b04f;
    }
  }
  span {
    i {
      margin-right: 4px;
    }
  }
}
</style>
