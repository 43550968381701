<template>
  <li class="menu-item" :title="title" @click="$emit('click')">
    {{ title }}
  </li>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['click'],
}
</script>
