<template>
  <fieldset>
    <label v-for="item in items" :key="item.value">
      <input v-model="selectedItem" type="radio" :value="item.id" :name="name" @change="toggle(item.id)" />
      <span> <i class="fa" :class="`fa-${item.icon}`" />{{ item.value }}</span>
    </label>
  </fieldset>
</template>

<script>
export default {
  name: 'DarioButtonGroup',
  props: {
    name: {
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    defaultValue: {
      type: [String, Boolean],
      required: false,
    },
  },
  emits: ['select'],
  data() {
    return {
      selected: !!this.defaultValue,
      selectedItem: this.defaultValue,
    }
  },
  methods: {
    toggle(item) {
      this.selectedItem = item
      this.$emit('select', item)
    },
  },
}
</script>

<style lang="scss" scoped>
fieldset {
  div {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    justify-content: center;
  }
}

input[type='radio'] {
  display: none;

  &:checked + span {
    box-shadow: 0 0 0 0.0625em var(--mediumblue);
    background: var(--mediumblue);
    color: white;
  }
}

label {
  span {
    min-width: 100px;
    display: block;
    cursor: pointer;
    font-size: 15px;
    padding: 10px 15px;
    position: relative;
    margin-left: 0.0625em;
    box-shadow: 0 0 0 0.0625em var(--mediumblue);
    color: var(--mediumblue);
    text-align: center;
    transition: background-color 0.5s ease;
  }

  i {
    margin-right: 10px;
  }

  &:first-child {
    span {
      border-radius: 0.375em 0 0 0.375em;
    }
  }

  &:last-child {
    span {
      border-radius: 0 0.375em 0.375em 0;
    }
  }
}
</style>
