<template>
  <div class="panel">
    <div class="panel-heading">
      <span class="panel-title">Telefoonlijnen</span>

      <div class="actions pull-right">
        <RefreshButton @click="load" />
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Lijn</th>
          <th>Nummer</th>
          <th>Profiel</th>
        </tr>
      </thead>
      <tbody v-if="lines.length == 0">
        <tr>
          <td colspane="3">Loading...</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="line in lines" :key="line.name" :class="{ success: line.isBusy }">
          <td>{{ line.name }}</td>
          <td>{{ line.remoteNumber }}</td>
          <td>
            <a v-show="line.profileId" :href="`/production/profiles/${line.profileId}`">{{ line.profileName }}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from '@/store/API'
import RefreshButton from '@/general/components/RefreshButton.vue'

export default {
  name: 'PhoneSystemLines',
  components: {
    RefreshButton,
  },
  props: ['directorId'],
  data() {
    return {
      refreshRate: 2000,
      lines: [],
    }
  },
  mounted() {
    this.load()
    this.loadPeriodically()
  },
  methods: {
    async load() {
      const response = await api.get('/production/phone_lines', { params: { director: this.directorId } })
      this.lines = response.data.lines
    },

    loadPeriodically() {
      setTimeout(async () => {
        await this.load()
        this.loadPeriodically()
      }, this.refreshRate)
    },
  },
}
</script>
