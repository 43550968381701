<template>
  <div class="message">
    <div class="message__img" :class="colorClass">
      <span>{{ user?.initials }}</span>
    </div>

    <div class="message__bubble">
      <div class="message__info">{{ user?.name }}</div>
      <div class="message__text" v-html="formattedMessage" />
    </div>
  </div>
</template>

<script>
import { markdown } from '@/general/utils/markdown'
import { mapState } from 'vuex'

export default {
  name: 'UserMessage',
  props: ['message'],
  computed: {
    ...mapState('Users', { user: 'current' }),
    formattedMessage() {
      return markdown(this.message)
    },
    colorClass() {
      return `user-badge-color-${this.user.id % 7}`
    },
  },
}
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  margin-bottom: 10px;

  &:last-of-type {
    margin: 0;
  }

  &__img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 10px;

    span {
      color: white;
      font-size: 20px;
    }
  }

  &__bubble {
    max-width: 70%;
    padding: 15px;
    border-radius: 15px;
    background: var(--mediumblue);
    color: #fff;
    border-bottom-right-radius: 0;

    a {
      color: #fff;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;
    margin-bottom: 5px;
    font-weight: bold;
  }
}
</style>
