<template>
  <div>
    <span v-for="(width, x) in bits" :key="x" :style="{ width: `${width}px` }" />
  </div>
</template>

<script>
export default {
  data() {
    const baseNumberOfBits = 8
    // Random number between 0 and 15
    const randomNumberOfBits = Math.floor(Math.random() * 15)
    const numberOfBits = randomNumberOfBits + baseNumberOfBits

    return {
      bits: Array.apply(null, Array(numberOfBits)).map(() => {
        const baseWidth = 25
        // Random with between 0 and 85
        const randomWidth = Math.floor(Math.random() * 85)
        return baseWidth + randomWidth
      }),
    }
  },
}
</script>

<style lang="scss" scoped>
div {
  display: flex;
  flex-wrap: wrap;

  height: 15px;
  width: 100%;

  overflow: hidden;

  > span {
    background: linear-gradient(110deg, #ababab 8%, #a1a1a1 18%, #ababab 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    height: 15px;
    margin-right: 5px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>
