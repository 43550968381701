<template>
  <div v-for="[groupAKey, groupB] in Object.entries(grouped)" :key="groupAKey">
    <h3>{{ groupAKey }}</h3>
    <div v-for="[groupBKey, groupBList] in Object.entries(groupB)" :key="groupAKey + groupBKey">
      <div class="panel panel-road-name">
        <div class="panel-heading">
          <Road
            v-if="groupBList[0].road === groupBKey"
            :road="groupBKey"
            :roadLetter="groupBList[0].roadLetter"
            :country="country"
          />
          <h4 v-else>{{ groupBKey }}</h4>
        </div>
      </div>
      <TrafficList :list="groupBList" :country="country" :showRoad="false" />
    </div>
  </div>
</template>

<script>
import { groupBy } from '../general/utils/collections'
import Road from './Road.vue'
import TrafficList from './TrafficList.vue'

export default {
  components: { Road, TrafficList },
  props: {
    groupBy: Array,
    list: Array,
    country: String,
  },
  computed: {
    grouped() {
      const levelA = groupBy(this.list, this.groupBy[0])
      const levelB = Object.fromEntries(
        Object.entries(levelA).map(([key, list]) => [key, groupBy(list, this.groupBy[1])])
      )
      return levelB
    },
  },
}
</script>

<style scoped>
h3 {
  font-weight: bold;
  margin-top: 2.5rem;
  margin-bottom: -1rem;
}

h4 {
  font-weight: bold;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.panel-road-name {
  margin-top: 2rem;
  margin-bottom: 0.4rem;
}
</style>
