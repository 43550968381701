<template>
  <button type="button" class="btn" :class="buttonClassName" :disabled="loading" @click="onClick">
    <template v-if="loading">
      <i class="fa fa-spin fa-spinner" />
    </template>
    <template v-else>
      <i v-if="icon" :class="iconClassName" />
      <span v-if="title">{{ title }}</span>
    </template>
  </button>
</template>

<script>
export default {
  name: 'DarioButton',
  props: {
    type: String,
    title: String,
    icon: String,
    confirm: String,
    loading: Boolean,
  },
  emits: ['click'],
  computed: {
    iconClassName() {
      return `fa fa-${this.icon} ${this.title ? '' : 'fa-lg'}`
    },
    buttonClassName() {
      return this.type ? `btn-${this.type}` : ''
    },
  },
  methods: {
    onClick() {
      if (this.confirm && !confirm(this.confirm)) {
        return
      }
      this.$emit('click')
    },
  },
}
</script>

<style scoped>
.btn {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.fa {
  pointer-events: none;
}
</style>
