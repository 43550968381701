<template>
  <div>
    <textarea
      ref="textareaElement"
      :value="modelValue"
      :class="{ autoresize: autoResize, [inputClass]: inputClass }"
      v-bind="$attrs"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <button type="button" @click="pickerVisible = !pickerVisible">
      <img src="../../../assets/images/emojipicker.svg" />
    </button>
    <DarioEmojiPicker v-if="pickerVisible" class="emoji-picker" @select="appendEmoji" />
  </div>
</template>

<script>
import DarioEmojiPicker from './DarioEmojiPicker.vue'

export default {
  components: { DarioEmojiPicker },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    autoResize: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pickerVisible: false,
      resizeEventListenerSet: false,
    }
  },
  watch: {
    modelValue: {
      handler() {
        if (!this.autoResize) {
          return
        }

        this.$nextTick(this.resize)
      },
      immediate: true,
    },
    autoResize: {
      handler() {
        if (this.autoResize && !this.resizeEventListenerSet) {
          this.$refs.textareaElement.addEventListener('input', this.resize)
          this.resizeEventListenerSet = true
        }

        if (!this.autoResize && this.resizeEventListenerSet) {
          this.$refs.textareaElement.removeEventListener('input', this.resize)
          this.resizeEventListenerSet = false
        }
      },
    },
  },
  methods: {
    resize() {
      this.$refs.textareaElement.style.height = 'auto'
      this.$refs.textareaElement.style.height = this.$refs.textareaElement.scrollHeight + 'px'
    },
    appendEmoji({ i: emoji }) {
      const { selectionStart, selectionEnd } = this.$refs.textareaElement
      const before = this.modelValue.substring(0, selectionStart)
      const after = this.modelValue.substring(selectionEnd, this.modelValue.length)
      const newText = before + emoji + after

      this.$emit('update:modelValue', newText)

      this.$nextTick(() => {
        this.focus()
        this.$refs.textareaElement.selectionStart = this.$refs.textareaElement.selectionEnd =
          selectionStart + emoji.length
      })
    },
    focus() {
      this.$refs.textareaElement.focus()
    },
  },
}
</script>

<style lang="scss" scoped>
.autoresize {
  height: auto;
  resize: none;
}

div {
  position: relative;

  textarea {
    padding-right: 32px;
  }

  button {
    position: absolute;
    top: 4px;
    right: 4px;

    width: 24px;
    height: 24px;
    padding: 0;

    background: none;
    border: none;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .emoji-picker {
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 99999;
  }
}
</style>
