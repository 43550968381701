import socket from './PublicSocket'
import api from './API'

export default {
  namespaced: true,
  state: {
    polls: {},
    subscribedPolls: [],
  },
  getters: {
    pollById: (state) => (id) => state.polls[id],
  },
  mutations: {
    setPoll(state, poll) {
      state.polls[poll.id] = poll
    },
    addSubscribedPoll(state, pollId) {
      state.subscribedPolls.push(pollId)
    },
  },
  actions: {
    async setPoll({ commit }, poll) {
      commit('setPoll', poll)
    },
    async fetchPoll({ commit }, id) {
      const response = (await api.get(`/production/poll_questions/${id}`)).data
      commit('setPoll', response)

      return response
    },
    async subscribeToPoll({ commit, state }, id) {
      if (!this.$socket) {
        this.$socket = await socket()
      }

      if (!state.subscribedPolls.includes(id)) {
        this.$socket.subscribe({ poll: id }).on('vote', (poll) => commit('setPoll', poll))
        commit('addSubscribedPoll', id)
      }
    },
  },
}
