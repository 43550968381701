<template>
  <component :is="importedPageComponent" v-bind="pageData" />
</template>

<script setup>
// Using the setup syntax allows us to use shallow refs which we use to not make the full imported component reactive.

import { onMounted, shallowRef } from 'vue'

const props = defineProps(['pageComponent', 'pageData'])

const importedPageComponent = shallowRef(null)

onMounted(async () => {
  const [directory, file] = props.pageComponent.split('/')

  const module = await import(`../_pages/${directory}/${file}.vue`)

  importedPageComponent.value = module.default
})
</script>
