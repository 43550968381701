import { Mark, mergeAttributes } from '@tiptap/core'

export const FontMark = Mark.create({
  name: 'font',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: 'font',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['font', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },
})
