// Delays a function for <wait> ms before calling it.
// if the function is called again before the delay is over, the delay is reset.
export function debounce(fn, wait) {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    const context = this
    timeout = setTimeout(() => {
      timeout = null
      fn.apply(context, args)
    }, wait)
  }
}
