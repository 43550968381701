<template>
  <li class="list-group-item" :title="`${tag.name} (${tag.id})`" :class="colorClass(tag.color)">
    <div class="tag-wrapper" @click.prevent="toggle">
      <span class="checker-wrapper">
        <i v-if="enabled" class="fa fa-check" />
      </span>
      {{ tag.name }}
    </div>
    <div class="favorite-wrapper">
      <a href="#" class="circle-hover" @click.prevent="togglePersonalFavorite">
        <i v-if="tag.personal_favorite" class="fa fa-star" />
        <i v-else class="fa fa-star-o" />
      </a>
    </div>
    <div class="edit-wrapper">
      <a href="#" class="circle-hover" @click.prevent="edit"><i class="fa fa-pencil" /></a>
    </div>
  </li>
</template>

<script>
import Helpers from './Helpers.js'

export default {
  name: 'EditRow',
  props: ['tag', 'messageId', 'messageIdsCallback'],
  computed: {
    enabled() {
      return this.$store.getters['MessageTags/enabled']([this.messageId, this.tag])
    },
  },
  methods: {
    edit() {
      this.$emit('edit', this.tag)
    },
    toggle() {
      this.$store.dispatch('MessageTags/toggle', [this.messageId, this.tag, this.messageIdsCallback])
    },
    async togglePersonalFavorite() {
      await this.$store.dispatch('ColorTags/togglePersonalFavorite', [this.tag.id])
    },
    ...Helpers,
  },
}
</script>

<style lang="scss" scoped>
.list-group-item {
  padding: 8px;
  border: none;
  border-radius: 7px;

  display: flex;
  align-items: center;

  margin-bottom: 4px;

  > * + * {
    margin-left: 10px;
  }
}

.tag-wrapper {
  flex: 1 1 auto;
  cursor: pointer;

  font-weight: 600;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  .checker-wrapper {
    display: inline-block;
    width: 13px;
  }
}

.edit-wrapper {
  .fa-pencil {
    color: rgba(255, 255, 255, 1);
  }
}

.favorite-wrapper {
  text-align: right;

  a {
    text-decoration: none;
    color: white;

    .fa {
      position: relative;
      z-index: 2;
    }

    .fa-star {
      color: white;
    }
  }
}

.circle-hover {
  position: relative;

  .fa {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    background: rgba(0, 0, 0, 0.2);
    width: 100%;

    border-radius: 100%;

    aspect-ratio: 1;

    margin: auto;

    transform: scale(0);
    transition: transform 0.2s ease-out;
  }

  &:hover:before {
    transform: scale(2);
  }
}
</style>
