<template>
  <div :data-attachment-url="image.media_url">
    <br />
    <a href="#">
      <div
        class="cropcontainer thumbnail"
        :style="{ backgroundImage: 'url(' + image.thumb_url + ')' }"
        :data-image-modal="image.media_url"
        data-no-check=""
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'PreviewVideo',
  props: ['image'],
}
</script>
