<template>
  <div v-if="signUpMoment" class="alert alert-info">
    <div v-if="!isHidden">
      Moment is <strong>zichtbaar</strong> in de app.<br />
      Huidige status: <strong>{{ state }}</strong> <strong v-if="isRunning">({{ timeRemaining }}s)</strong>
    </div>
    <div v-else>Dit moment is <strong>niet zichtbaar</strong> in de app</div>
    <p>
      Aantal inschrijvingen: <strong>{{ count }}</strong>
    </p>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SignUpStatus',
  props: ['momentId'],
  computed: {
    ...mapState('SignUps', {
      signUps: (state) => state.signUps,
    }),
    ...mapGetters({
      signUpMomentForId: 'SignUps/signUpMomentForId',
    }),
    signUpMoment() {
      return this.signUpMomentForId(this.momentId)
    },
    count() {
      return this.signUpMoment.signupCount
    },
    state() {
      return this.signUpMoment.momentState
    },
    timeRemaining() {
      return this.signUpMoment.duration
    },
    isRunning() {
      return this.state === 'running'
    },
    isHidden() {
      return this.state === 'hidden'
    },
  },
}
</script>
