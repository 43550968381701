import api from './API'

export default {
  namespaced: true,
  state: {
    messages: {},
  },
  getters: {
    enabled:
      (state) =>
      ([messageId, tag]) => {
        if (!state.messages[messageId]) return false
        return state.messages[messageId].indexOf(tag.id) > -1
      },
    colorTags: (state, getters, rootState) => (messageId) => {
      const colorTagIds = state.messages[messageId]
      return colorTagIds ? rootState.ColorTags.tags.filter((colorTag) => colorTagIds.indexOf(colorTag.id) > -1) : []
    },
  },
  mutations: {
    add(state, [messageIds, messageTag]) {
      for (let messageId of messageIds) {
        if (!state.messages[messageId]) {
          state.messages[messageId] = []
        }
        state.messages[messageId].push(messageTag.id)
      }
    },
    remove(state, [messageIds, messageTag]) {
      for (let messageId of messageIds) {
        if (!state.messages[messageId]) return
        state.messages[messageId] = state.messages[messageId].filter((tagId) => tagId !== messageTag.id, 1)
      }
    },
    setCurrentTagIds(state, [messageId, tagIds]) {
      state.messages[messageId] = tagIds
    },
  },
  actions: {
    toggle({ commit, state }, [messageId, messageTag, messageIdsCallback]) {
      const messageIds = messageIdsCallback ? [messageId, ...messageIdsCallback()] : [messageId]

      if (state.messages[messageId] && state.messages[messageId].indexOf(messageTag.id) > -1) {
        commit('remove', [messageIds, messageTag])
      } else {
        commit('add', [messageIds, messageTag])
      }

      if (messageIdsCallback) {
        api.put(`/production/messages/tag`, {
          color_tag_ids: state.messages[messageId],
          message_ids: messageIds,
        })
      } else {
        api.put(`/production/messages/${messageId}/tag`, { color_tag_ids: state.messages[messageId] })
      }
    },
    setCurrentTagIds({ commit }, [messageId, tagIds]) {
      commit('setCurrentTagIds', [messageId, tagIds])
    },
  },
}
