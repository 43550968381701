<template>
  <li class="profile-card">
    <div class="profile-section">
      <div v-if="profile.user_picture_url" class="profile-avatar">
        <img :src="profile.user_picture_url" />
      </div>
      <div v-else class="profile-avatar profile-avatar--no-image">
        {{ profile.initials }}
      </div>
      <div class="profile-info">
        <div class="profile-name">{{ profile.name }}</div>
        <div v-if="profile.win_date">
          <div>
            {{ isBlacklisted ? 'Mag niet winnen' : 'Mag wel winnen' }}
          </div>
          <div class="profile-last-won">
            <span class="profile-last-win-date" :class="[{ blacklisted: isBlacklisted }]">
              Won de wedstrijd "{{ lastWonContest }}" op {{ lastWonDate }}
            </span>
          </div>
        </div>
        <div v-else>Nog nooit gewonnen</div>
      </div>
    </div>
    <input class="form-control" placeholder="Voeg hier een opmerking toe" @input="updateComment" />
  </li>
</template>

<script>
export default {
  name: 'ProfileCard',
  props: {
    profile: Object,
  },
  computed: {
    lastWonDate() {
      return this.profile.win_date && new Date(this.profile.win_date.won_at).toLocaleDateString('nl-BE')
    },
    lastWonContest() {
      return this.profile.win_date && this.profile.win_date.contest.name
    },
    isBlacklisted() {
      return new Date(this.profile.win_date.blacklisted_until).getTime() > new Date().getTime()
    },
  },
  methods: {
    updateComment(e) {
      this.$store.dispatch('SelectWinners/setComment', { profileId: this.profile.id, comment: e.target.value })
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-card {
  display: flex;
  flex-direction: column;
  min-height: 73px;
  border: 1px solid #d6d6d6;
  padding: 9px;
  margin-bottom: 5px;
  font-size: 18px;
}

.profile-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-avatar {
  width: 54px;
  height: 54px;
  margin-right: 8px;
  &--no-image {
    color: #ffffff;
    line-height: 54px;
    text-align: center;
    background-color: #666;
  }
}
.profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
}
.profile-name {
  font-weight: bold;
}
.profile-last-won {
  font-size: 12px;
}
.profile-last-win-date {
  color: #417505;
}
.blacklisted {
  color: #d0021b;
}
</style>
