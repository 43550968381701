<template>
  <TransitionGroup tag="div" name="fade">
    <TrafficItem v-for="item in sortedList" :key="item.id" :item="item" :showRoad="showRoad" :country="country" />
  </TransitionGroup>
</template>

<script>
import TrafficItem from './TrafficItem.vue'

export default {
  components: { TrafficItem },
  props: {
    list: Array,
    showRoad: Boolean,
    country: String,
  },
  computed: {
    sortedList() {
      return [...this.list].sort((a, b) => {
        if (a.ignored && !b.ignored) return 1
        if (!a.ignored && b.ignored) return -1
        return 0
      })
    },
  },
}
</script>

<style scoped>
div {
  position: relative;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.fade-leave-active {
  position: absolute;
}
</style>
