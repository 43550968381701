const $ = window.jQuery

class CheckableTable extends HTMLTableElement {
  #lastSelected = null

  constructor() {
    super()

    this.addSelectHeader()
    this.addCheckboxColumn()
  }

  get checkboxes() {
    return this.querySelectorAll('.m-chck input')
  }

  get resourceName() {
    return this.getAttribute('resource-name')
  }

  get selectedRows() {
    return Array.from(this.querySelectorAll('tr.selected'))
  }

  get selectedIds() {
    return this.selectedRows.map((row) => row.dataset.id)
  }

  addCheckboxColumn() {
    const checkboxCellTemplate = document.createElement('template')
    checkboxCellTemplate.innerHTML = '<td class="m-chck"><input type="checkbox"></td>'

    // Empty th
    this.querySelector('thead tr').prepend(document.createElement('th'))

    this.querySelectorAll('tbody tr').forEach((tr) => {
      const checkboxCell = checkboxCellTemplate.content.cloneNode(true)
      checkboxCell.querySelector('input').addEventListener('click', this.checkboxClick.bind(this))
      tr.prepend(checkboxCell)
    })
  }

  addSelectHeader() {
    const actions = this.querySelector('template[data-role="actions"]').content.cloneNode(true)
    actions.querySelector('[data-role="checkable-link"]').addEventListener('click', this.checkableLinkClick.bind(this))

    const selectAllRow = document.createElement('tr')
    const selectAll = document.createElement('th')
    selectAll.innerHTML = '<input type="checkbox" data-role="select-all">'
    selectAll.querySelector('input').addEventListener('change', this.selectAll.bind(this))

    const actionsCell = document.createElement('th')
    actionsCell.colSpan = this.querySelectorAll('th').length

    const countSpan = document.createElement('span')
    actionsCell.append(countSpan, actions)
    selectAllRow.append(selectAll, actionsCell)

    this.querySelector('thead').appendChild(selectAllRow)

    this.countMessageSpan = countSpan
    this.updateSelectCountMessage()
  }

  // Let Rails UJS handle the remote request
  checkableLinkClick(e) {
    e.stopImmediatePropagation()
    e.preventDefault()

    const $link = $(e.currentTarget)

    if ($.rails.allowAction($link)) {
      // Append selected ids to the link href
      const url = new URL(e.currentTarget.href)
      url.searchParams.set('ids', this.selectedIds.join(','))
      $link.attr('href', url.toString())

      $.rails.handleRemote($link)

      if (e.currentTarget.dataset.after == 'remove') {
        this.removeSelectedRows()
      }
    }
  }

  checkboxClick(e) {
    const currentSelected = e.target
    this.updateRow(currentSelected)

    if (e.shiftKey && currentSelected.checked && this.#lastSelected && this.#lastSelected.checked) {
      this.shiftCheckboxClick(currentSelected, this.#lastSelected)
    }

    this.#lastSelected = currentSelected
    this.updateSelectCountMessage()
  }

  shiftCheckboxClick(current, last) {
    const currentIndex = Array.from(this.checkboxes).indexOf(current)
    const lastIndex = Array.from(this.checkboxes).indexOf(last)
    const start = Math.min(currentIndex, lastIndex)
    const end = Math.max(currentIndex, lastIndex)

    for (let i = start + 1; i < end; i++) {
      this.checkboxes[i].checked = true
      this.updateRow(this.checkboxes[i])
    }
    this.updateSelectCountMessage()
  }

  updateRow(checkbox) {
    checkbox.closest('tr').classList.toggle('selected', checkbox.checked)
  }

  updateSelectCountMessage() {
    const count = this.selectedRows.length
    this.countMessageSpan.textContent = window.i18n.t('checkable.execute_an_action_on_selected', {
      count,
      resources_name: this.resourceName,
    })
  }

  selectAll(e) {
    const checkAll = e.currentTarget.checked == true
    this.checkboxes.forEach((checkbox) => {
      checkbox.checked = checkAll
      this.updateRow(checkbox)
    })
    this.updateSelectCountMessage()
  }

  removeSelectedRows() {
    this.selectedRows.forEach((row) => {
      row.classList.add('fadeOut')
      row.addEventListener('animationend', () => row.remove())
    })
  }
}

customElements.define('checkable-table', CheckableTable, { extends: 'table' })
