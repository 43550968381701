<template>
  <div class="panel" :class="{ 'is-ignored': item.ignored }" :data-fm-id="item.id">
    <div class="panel-body">
      <div class="traffic-item">
        <div class="traffic-item-content">
          <div v-if="showRoad" class="traffic-item-road">
            <Road
              :road="item.road"
              :roadLetter="item.roadLetter"
              :hmp="item.type === 'speedtrap' && item.hmp"
              :country="country"
            />
          </div>
          <div class="traffic-item-details">
            <h4>{{ item.direction }}</h4>
            <p v-if="item.segment">tussen {{ item.segment }}</p>
            <p v-else>ter hoogte van {{ item.location }}</p>
            <strong v-if="item.cause">
              Oorzaak: <span class="cause">{{ item.cause }}</span>
            </strong>
            <p v-if="item.text">
              <em>{{ item.text }}</em>
            </p>
          </div>
        </div>
        <div class="traffic-item-length">
          <div v-if="item.delay" class="duration">
            +
            <span v-if="delay.hours">{{ delay.hours }} <span class="duration-unit">uur en</span></span>
            {{ delay.minutes }} <span class="duration-unit">min</span>
          </div>
          <div v-if="item.length" class="length">{{ item.length }} km</div>
          <div v-if="item.updates" class="length">{{ item.updates }}x</div>
        </div>
      </div>

      <button class="traffic-item-ignore" @click="toggleIgnore">
        <i v-if="!item.ignored" class="fa fa-times" />
        <i v-else class="fa fa-check" />
      </button>
    </div>
  </div>
</template>

<script>
import Road from './Road.vue'
import api from '@/store/API'

export default {
  components: { Road },
  props: ['item', 'showRoad', 'country'],
  computed: {
    delay() {
      return {
        hours: Math.floor(this.item.delay / 60),
        minutes: this.item.delay % 60,
      }
    },
  },
  methods: {
    toggleIgnore() {
      if (this.item.ignored) {
        api.delete(`/production/traffic/ignores/${this.item.id}`)
      } else {
        api.post('/production/traffic/ignores', { id: this.item.id })
      }
    },
  },
}
</script>

<style scoped>
.panel {
  padding: 0;
  margin-bottom: 5px;

  transition: opacity 0.2s;

  &.is-ignored {
    opacity: 0.2;
  }

  &:hover {
    opacity: 1;
  }
}

.traffic-item {
  container-type: inline-size;
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;
}

.traffic-item-content {
  display: flex;
  flex: 1 1 0%;
  padding-right: 0.5em;
}

.traffic-item-road {
  margin-right: 1em;
}

.traffic-item-details h4 {
  margin: 0 0 0.2em 0;
  font-weight: 600;
  font-size: 1.4em;
}

.cause {
  color: #c01000;
}

.traffic-item-length {
  text-align: right;
}

@container (min-width: 750px) {
  .traffic-item-length {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .traffic-item-length .duration {
    margin-left: 15px;
  }
}

.duration {
  color: #c01000;
  font-weight: bold;
  font-size: 1.4em;
}

.duration-unit {
  font-size: 0.8em;
}

.length {
  color: gray;
}

.panel {
  .traffic-item-ignore {
    position: absolute;
    right: 0;
    top: 0;

    border: 1px solid transparent;
    border-top: 0;
    border-right: 0;
    background: white;

    color: #d6d6d6;

    transition: border-color 0.2s;

    &:hover {
      border-color: #d6d6d6;
    }

    i {
      width: 12px;
    }
  }
}
</style>
