<template>
  <tr class="mail-item">
    <td class="m-chk">
      <label class="px-single"><input v-model="selected" type="checkbox" /></label>
    </td>
    <td class="m-from">
      <a href="#" @click.prevent="modalVisible = true">{{ comment.from.name }}</a>
      <div ref="modal">
        <ReplyModal v-if="modalVisible" :comment="comment" @close="modalVisible = false" />
      </div>
    </td>
    <td @click="toggleCheck">{{ contentTitle }}</td>
    <td class="m-subject" @click="toggleCheck">
      <CommentTextAndMedia :comment="comment" />
    </td>
    <td class="m-date" @click="toggleCheck">{{ formatTime(comment.commentedAt) }}</td>
  </tr>
</template>

<script>
import { format, parseISO } from 'date-fns'
import ReplyModal from './ReplyModal.vue'
import CommentTextAndMedia from './CommentTextAndMedia.vue'

export default {
  name: 'ContentCommentRow',
  components: {
    CommentTextAndMedia,
    ReplyModal,
  },
  props: {
    comment: Object,
  },
  data() {
    return {
      modalVisible: false,
    }
  },
  computed: {
    hasVideo() {
      return this.comment.media && this.comment.media.type === 'video'
    },
    hasImage() {
      return this.comment.media && this.comment.media.type === 'image'
    },
    hasAudio() {
      return this.comment.media && this.comment.media.type === 'audio'
    },
    contentTitle() {
      return this.$store.getters['ContentComments/contentTitle'](this.comment.contentId)
    },
    selected: {
      get() {
        return this.comment.selected
      },

      set(value) {
        this.$store.dispatch('ContentComments/toggleSelection', { selected: value, id: this.comment.id })
      },
    },
  },
  methods: {
    toggleCheck() {
      this.selected = !this.selected
    },
    formatTime(timeString) {
      return format(parseISO(timeString), 'dd MMM HH:mm:ss')
    },
  },
}
</script>
