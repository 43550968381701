import api from './API'

export default {
  namespaced: true,
  state: {
    djs: [],
  },
  mutations: {
    setDjs(state, { djs }) {
      state.djs = djs
    },
  },
  actions: {
    async fetch({ commit }) {
      const response = await api.get('/2.4/djs')
      const djs = response.data.djs

      commit('setDjs', { djs: djs })
    },
  },
}
