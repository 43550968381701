<template>
  <div class="emoji-reply">
    <div v-show="selectedEmoji" ref="selectedEmojiElement" class="emoji-reply__selected">
      {{ selectedEmoji }}
    </div>
    <div class="emoji-selector">
      <div class="emoji-selector__buttons">
        <button
          v-for="emoji in defaultEmojis"
          :key="emoji"
          :class="{ active: selectedEmoji === emoji }"
          @click="replyOrRemoveEmoji(emoji)"
        >
          {{ emoji }}
        </button>
        <button
          :class="{ active: nonDefaultSelectedEmoji }"
          @mouseenter="pickerVisible = true"
          @mouseleave="pickerVisible = false"
          @click="nonDefaultSelectedEmoji && replyOrRemoveEmoji(nonDefaultSelectedEmoji)"
        >
          {{ emojiPickerIcon }}
        </button>
      </div>
    </div>
    <div
      v-if="pickerVisible"
      class="emoji-picker"
      @mouseenter="pickerVisible = true"
      @mouseleave="pickerVisible = false"
    >
      <DarioEmojiPicker @select="pickEmoji" />
    </div>
  </div>
</template>

<script>
import api from '@/store/API'
import { airbrake } from '../general/airbrake'
import DarioEmojiPicker from '../general/components/DarioEmojiPicker.vue'

export default {
  name: 'EmojiReply',
  components: { DarioEmojiPicker },
  props: {
    messageId: Number,
    initialEmoji: String,
  },
  data() {
    return {
      defaultEmojis: ['👍', '❤️', '😂', '😮', '😢', '🙏'],
      selectedEmoji: this.initialEmoji,
      state: 'idle',
      pickerVisible: false,
    }
  },
  computed: {
    nonDefaultSelectedEmoji() {
      return this.defaultEmojis.includes(this.selectedEmoji) ? null : this.selectedEmoji
    },
    emojiPickerIcon() {
      return this.nonDefaultSelectedEmoji || '➕'
    },
  },
  mounted() {
    window.emojiReplyListeners[this.messageId] = [
      ...(window.emojiReplyListeners[this.messageId] || []),
      (emoji) => (this.selectedEmoji = emoji),
    ]

    // This component has no parent element yet when it is mounted due to the way Dario renders components.
    // Therefore, we must resort to this "hack" to get the background color of the parent element.
    this.$nextTick(() => {
      const parentBackground = window.getComputedStyle(this.$el.parentNode).backgroundColor
      this.$refs.selectedEmojiElement.style.backgroundColor =
        parentBackground == 'rgba(0, 0, 0, 0)' ? 'white' : parentBackground
    })
  },
  methods: {
    pickEmoji({ i: emoji }) {
      this.replyOrRemoveEmoji(emoji)
      this.pickerVisible = false
    },
    async replyOrRemoveEmoji(emoji) {
      if (emoji == this.selectedEmoji) {
        // Remove emoji when it's clicked a second time
        emoji = null
      }

      this.state = 'loading'
      try {
        await api.put(`/production/messages/${this.messageId}/emoji_reply`, { emoji })
        this.state = 'success'
        this.selectedEmoji = emoji
      } catch (error) {
        alert('Er is een fout opgetreden. De emojireactie kon niet worden geplaatst. Probeer het alsjeblieft opnieuw.')
        console.error(error)
        airbrake.notify(error)
        this.state = 'error'
      } finally {
        setTimeout(() => (this.state = 'idle'), 2500)
      }
    },
  },
}
</script>

<style lang="scss">
.message:hover .emoji-selector {
  width: 252px;
}
</style>

<style lang="scss" scoped>
.emoji-reply {
  position: relative;

  &__selected {
    position: absolute;
    top: -28px;
    right: 0;

    white-space: nowrap;

    background: inherit;
    border-radius: 16px 16px 0 0;

    border-top: 1px solid #e4e4e4;

    padding: 5px 10px 0px 10px;
  }
}

.emoji-selector {
  width: 0px;
  overflow: hidden;
  transition: width 0.1s ease-in-out;

  position: absolute;
  top: -28px;
  right: 0;

  white-space: nowrap;

  background: #fcefdd;
  border-radius: 16px 16px 0 0;

  border-top: 1px solid #e4e4e4;

  &__buttons {
    padding: 5px 10px 0 10px;

    > * + * {
      margin-left: 5px;
    }

    button {
      background: #fffcf9;
      border: 0;
      border-radius: 999999px;

      &:hover {
        background: #ffd69e;
        scale: 1.5;
      }

      &.active {
        background: white;
        border: 2px solid #ffce8a;
      }

      &:hover .emoji-picker {
        display: block;
        scale: 1;
      }
    }
  }
}

.emoji-picker {
  position: absolute;
  z-index: 1000;
  right: 0;
}
</style>
