<template>
  <div class="search__box">
    <div class="search">
      <input
        ref="search"
        v-model="query"
        autofocus
        type="search"
        class="form-control"
        placeholder="Zoeken"
        @input="debounceSearch"
      />
      <button class="box__button" type="button">
        <i class="fa fa-search" />
      </button>
    </div>
    <div class="filter-row">
      <div class="filters">
        <multiselect
          v-model="filter.programs"
          :searchable="true"
          mode="multiple"
          :hideSelected="false"
          placeholder="In Programma:"
          :options="mappedPrograms"
          @input="searchBroadcastItems"
        >
          <template #multiplelabel="{ values }">
            <div class="multiselect-multiple-label">
              <span v-if="values.length === 1">
                {{ values[0].label }}
              </span>
              <span v-else> {{ values.length }} programma's geselecteerd </span>
            </div>
          </template>
        </multiselect>
        <multiselect
          v-model="filter.date_range"
          mode="single"
          :canClear="false"
          :options="dateRangeOptions"
          @input="searchBroadcastItems"
        />
      </div>
      <div class="sort">
        <multiselect
          v-model="sort"
          mode="single"
          :canClear="false"
          :options="sortOptions"
          @input="searchBroadcastItems"
        />
      </div>
    </div>
  </div>
  <div v-if="loading" class="loading">
    <p>Laden...</p>
  </div>
  <div v-else-if="broadcastItems.length" class="result-list" :class="{ 'result-list--modal': inModal }">
    <BroadcastItem v-for="broadcastItem in broadcastItems" :key="broadcastItem.id" :item="broadcastItem" />
    <ApiPagination v-if="totalPages > 1" :totalPages="totalPages" :currentPage="page" @changePage="changePage" />
  </div>
  <div v-else class="no-results">
    <p v-if="query">Geen resultaten gevonden</p>
  </div>
</template>

<script>
import api from '../store/API'
import Multiselect from '@vueform/multiselect'
import BroadcastItem from './BroadcastItem.vue'
import { mapState, mapActions } from 'vuex'
import { debounce } from '@/general/utils/debounce'
import ApiPagination from '../general/components/ApiPagination.vue'

export default {
  name: 'BroadcastSearch',
  components: { BroadcastItem, Multiselect, ApiPagination },
  props: {
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      totalPages: null,
      filter: {
        date_range: 'all_time',
        programs: [],
      },
      query: '',
      dateRangeOptions: [
        { label: 'Vandaag', value: 'today' },
        { label: 'laatste 24 uren', value: 'last_24h' },
        { label: 'laatste 7 dagen', value: 'last_week' },
        { label: 'laatste 30 dagen', value: 'last_month' },
        { label: 'Alles', value: 'all_time' },
      ],
      sort: '',
      sortOptions: [
        { label: 'Relevantie', value: '' },
        { label: 'Oudste eerst', value: 'date_asc' },
        { label: 'Nieuwste eerst', value: 'date_desc' },
      ],
      broadcastItems: [],
      loading: false,
    }
  },
  computed: {
    ...mapState('Programs', {
      programs: (state) => state.programs,
    }),
    mappedPrograms() {
      return this.programs.map(({ id, title }) => ({
        value: id,
        label: title,
      }))
    },
  },
  mounted() {
    this.$refs.search.focus()
    this.fetchPrograms()
  },
  methods: {
    ...mapActions('Programs', ['fetchPrograms']),
    async searchBroadcastItems() {
      this.loading = true

      if (this.query === '') {
        this.loading = false
        this.page = 1
        this.broadcastItems = []
        return
      }

      const { data } = await api.get('/production/broadcast_items', {
        params: {
          page: this.page,
          q: this.query,
          filter: this.filter,
          sort: this.sort,
        },
      })
      this.broadcastItems = data.items
      this.page = data.page
      this.totalPages = data.total_pages
      this.loading = false
    },
    debounceSearch: debounce(function () {
      this.searchBroadcastItems()
    }, 500),
    changePage(page) {
      this.page = page
      this.searchBroadcastItems()
    },
  },
}
</script>

<style lang="scss">
@import '@vueform/multiselect/themes/default.css';

.search {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;

  input {
    flex: 1;
    border-right-color: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  button {
    width: 50px;
    background-color: none;
    border: 1px solid #e0e0e0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

:root {
  --ms-option-bg-selected: var(--mediumblue);
  --ms-option-bg-selected-pointed: var(--mediumblue);
}

.filter-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-content: start;
  gap: 10px;

  .multiselect {
    width: auto;
    min-width: 220px;
    font-size: 0.9em;
    margin: 0;
  }
}

.sort {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-content: start;
  gap: 10px;

  .multiselect {
    width: auto;
    min-width: 190px;
    font-size: 0.9em;
    margin: 0;
  }
}

.result-list--modal {
  overflow: scroll;
  max-height: 60vh;
}

.result-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-results,
.loading {
  margin-top: 20px;
  text-align: center;
}
</style>
