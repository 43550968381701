import { Notifier } from '@airbrake/browser'

export let airbrake = null

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  airbrake = new Notifier({
    projectId: 95285,
    projectKey: 'e0bf8235089091e340256cb7b6156bdc',
    environment: process.env.NODE_ENV,
  })

  airbrake.addFilter((notice) => {
    notice.session.user = window.userConfig?.user

    return notice
  })
}

export const airbrakeVuePlugin = {
  install(app) {
    if (!airbrake) return

    app.config.errorHandler = function (err, vm, info) {
      airbrake.notify({
        error: err,
        params: { info: info },
      })
    }
  },
}
