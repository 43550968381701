<template>
  <div class="signups">
    <div class="pull-right signup-actions">
      <div v-if="isTimed && isStartable">
        <button v-if="isWaitingToStart" class="btn btn-sm btn-success" disabled>Start...</button>
        <button v-else-if="!isRunning" class="btn btn-sm btn-success" @click="startSignUpMoment">Start</button>
        <button v-else class="btn btn-sm btn-success">Actief ({{ timeRemaining }}s)</button>
      </div>

      <button class="btn btn-sm" @click="chooseRandomWinner">Kies random luisteraar</button>
      <button class="btn btn-sm btn-with-icon" @click="refresh"><i class="fa fa-rotate-left" /> Refresh</button>
    </div>
    <div class="sign-up-container">
      <div class="heading">
        <h5>
          Aantal inzendingen: <strong>{{ count }}</strong>
        </h5>
      </div>
    </div>
    <div class="sign-up-container">
      <div class="heading">
        <h5>Geselecteerd voor uitzending</h5>
      </div>
      <table v-if="contacted.length > 0" class="signup-table">
        <tbody>
          <SignUpRow v-for="signup in contacted" v-bind="signup" :key="signup.id" :momentId="id" />
        </tbody>
      </table>
      <p v-else>Nog niemand gecontacteerd</p>
    </div>

    <div class="sign-up-container">
      <div class="heading">
        <h5>Willekeurig gekozen</h5>
      </div>
      <table v-if="selected.length > 0" class="signup-table">
        <tbody>
          <SignUpRow v-for="signup in selected" v-bind="signup" :key="signup.id" :momentId="id" />
        </tbody>
      </table>
      <p v-else>Nog niemand geselecteerd</p>
    </div>
    <div class="sign-up-container">
      <a class="btn btn-with-icon" :href="signUpMomentUrl"> Bekijk alle aanmeldingen </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { subMinutes, addHours } from 'date-fns'
import { useNow } from '@/general/utils/useNow'
import SignUpRow from './SignUpRow.vue'

export default {
  name: 'SignUps',
  components: {
    SignUpRow,
  },
  props: ['id', 'type', 'visibleAt', 'hiddenAt', 'startHourItem'],
  setup() {
    const now = useNow()

    return { now }
  },
  computed: {
    ...mapGetters({
      signUpMomentForId: 'SignUps/signUpMomentForId',
    }),
    signUpMoment() {
      return this.signUpMomentForId(this.id)
    },
    signUpMomentUrl() {
      return `/production/sign_up_moments/${this.id}/sign_ups`
    },
    count() {
      return this.signUpMoment.totalUnfilteredCount
    },
    signUpItems() {
      return this.signUpMoment.signUps
    },
    contacted() {
      return this.signUpItems.filter((signup) => signup.selectedAt && signup.contactedAt)
    },
    selected() {
      return this.signUpItems.filter((signup) => signup.selectedAt && !signup.contactedAt)
    },
    isTimed() {
      return this.type === 'timed'
    },
    isStartable() {
      const visibleAt = new Date(this.visibleAt)
      const hiddenAt = new Date(this.hiddenAt)

      return visibleAt <= this.now && this.now < hiddenAt
    },
    isWaitingToStart() {
      return this.signUpMoment.waitingToStart
    },
    isRunning() {
      return this.signUpMoment.momentState === 'running'
    },
    timeRemaining() {
      return this.signUpMoment.duration
    },
  },
  mounted() {
    this.subscribe(this.id)
    this.fetchSignups()
  },
  methods: {
    ...mapActions({
      selectWinner: 'SignUps/selectWinner',
      subscribe: 'SignUps/subscribe',
      fetchSignupsForHour: 'SignUps/fetchSignups',
      refreshSignups: 'SignUps/refreshSignups',
      startMoment: 'SignUps/startSignUpMoment',
    }),
    chooseRandomWinner() {
      return this.selectWinner(this.id)
    },
    startSignUpMoment() {
      return this.startMoment(this.id)
    },
    refresh() {
      this.fetchSignups()
    },
    fetchSignups() {
      const begin = new Date(this.startHourItem)
      const end = addHours(begin, 1)
      this.fetchSignupsForHour({
        momentId: this.id,
        params: {
          begin: subMinutes(begin, 20).toISOString(),
          end: end.toISOString(),
        },
      })
    },
  },
}
</script>

<style lang="scss">
.signups {
  h5 {
    font-weight: 600;
  }

  .sign-up-container {
    margin-bottom: 20px;
  }
}

.signup-actions {
  display: flex;

  .btn {
    margin: 0 2px;
  }
}

.signup-table {
  width: 100%;

  tbody {
    width: 100%;

    tr {
      display: grid;
      min-width: 100%;
      grid-template-columns: 1% 2fr 2fr 2fr 1fr 200px;
      padding: 5px 0;
      border-top: 1px #d6d6d6 solid;
    }
  }
}
</style>
