<template>
  <component :is="editor" v-model="editorContent" />

  <textarea v-model="editorContent" :name="name" style="display: none" />
</template>

<script>
import * as editors from './index.js'

export default {
  name: 'WysiwygTextarea',
  components: {
    ...editors,
  },
  props: {
    name: String,
    content: String,
    editor: {
      type: String,
      default: 'WysiwygEditor',
    },
  },
  data() {
    return {
      editorContent: null,
    }
  },
  mounted() {
    this.editorContent = this.content
  },
}
</script>
