<template>
  <div class="options chat_settings">
    <div class="mode">
      <h4>
        Modus
        <DarioTooltip>
          <i class="fa fa-question-circle" />
          <template #tooltip-content>
            <b>Strict</b><br />
            Doria geeft meer gerichte, consistente reacties en houdt meer rekening met de structuur die je meegeeft in
            voorbeelden
            <br /><br />
            <b>Creatief</b><br />
            Doria probeert haar teksten zo creatief mogelijk te schrijven, je geeft hen dus meer vrijheid om te
            experimenteren. De kans op hallucinatie is groot.
          </template>
        </DarioTooltip>
      </h4>
      <DarioButtonGroup name="modes" :defaultValue="chat.settings.mode" :items="modes" @select="setMode" />
    </div>
    <div class="profile">
      <h4>
        Profiel
        <DarioTooltip>
          <i class="fa fa-question-circle" />
          <template #tooltip-content>
            <b>DJ</b><br />
            Doria weet dat die voor een radiozender werkt en hoe we communiceren. Teksten worden geschreven in passende
            stijl.<br /><br />
            <b>Anoniem</b><br />
            Doria houdt in anonieme modus geen rekening met de context van radio, zenders, acties, etc.
          </template>
        </DarioTooltip>
      </h4>
      <DarioButtonGroup
        name="profile"
        :defaultValue="chat.settings.with_context"
        :items="profiles"
        @select="setProfile"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DarioButtonGroup from '../../general/components/DarioButtonGroup.vue'
import DarioTooltip from '../../general/components/DarioTooltip.vue'

export default {
  name: 'ChatSettings',
  components: {
    DarioButtonGroup,
    DarioTooltip,
  },
  props: ['chatId'],
  data() {
    return {
      modes: [
        {
          value: 'Strict',
          id: 'precise',
          icon: 'quote-right',
        },
        {
          value: 'Gebalanceerd',
          id: 'normal',
          icon: 'flash',
        },
        {
          value: 'Creatief',
          id: 'creative',
          icon: 'magic',
        },
      ],
      profiles: [
        {
          value: 'DJ',
          id: true,
          icon: 'headphones',
        },
        {
          value: 'Anoniem',
          id: false,
          icon: 'flash',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      chatForId: 'LlmConversation/chatForId',
    }),
    chat() {
      return this.chatForId(this.chatId)
    },
  },
  methods: {
    ...mapActions({
      updateChatSettings: 'LlmConversation/updateChatSettings',
    }),
    setMode(mode) {
      this.updateChatSettings({ chatId: this.chatId, settings: { mode } })
    },
    setProfile(profile) {
      this.updateChatSettings({ chatId: this.chatId, settings: { with_context: profile } })
    },
  },
}
</script>

<style lang="scss" scoped>
.options {
  display: flex;
  gap: 30px;
}

i.fa {
  width: 7px;
  height: 7px;
  font-size: 14px;
}

@media screen and (max-width: 900px) {
  .options {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
