<template>
  <div class="wrapper">
    <img :src="imageUrl" :alt="option.name" />
    <span v-text="option.name" />
  </div>
</template>

<script>
import { cdnUrl } from '@/general/utils/cdn'

export default {
  props: {
    option: { type: Object, required: true },
  },

  computed: {
    imageUrl() {
      return cdnUrl(this.option.image, 'cover/w300')
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;

  img {
    height: 20px;
    width: 20px;
    margin-right: 10px;

    object-fit: cover;
    border-radius: 100%;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
