<template>
  <li class="dropdown">
    <a href="#" class="dropdown-toggle aa-icon" data-toggle="dropdown"> Aa </a>
    <ul class="dropdown-menu">
      <li class="setting setting-select">
        <button class="descriptor" @click.stop="font = 'Default'"><i class="fa fa-font" /> Lettertype</button>
        <div class="value-change">
          <button
            v-for="(label, value) in fontOptions"
            :key="value"
            :class="{ active: font === value }"
            :style="{ fontFamily: value }"
            @click.stop="font = value"
          >
            {{ label }}
          </button>
        </div>
      </li>
      <li class="divider" />
      <li class="setting setting-number setting-zoom">
        <button class="descriptor" @click.stop="zoom = 100"><i class="fa fa-search" /> Tekstgrootte</button>
        <div class="value-change">
          <button @click.stop="zoomOut"><i class="fa fa-minus" /></button>
          {{ zoom }} %
          <button @click.stop="zoomIn"><i class="fa fa-plus" /></button>
        </div>
      </li>
      <li class="divider" />
      <li class="setting setting-number setting-zoom">
        <button class="descriptor" @click.stop="textTransform = 'none'">
          <i class="fa fa-text-height" /> HOOFDLETTERS
        </button>
        <div class="value-change">
          <n-switch
            v-model:value="textTransform"
            checkedValue="uppercase"
            uncheckedValue="none"
            :railStyle="railStyle"
            @click.stop
          />
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import api from '@/store/API'
import { NSwitch } from 'naive-ui'

const ZOOM_STEP = 10

const LOCAL_STORAGE_KEYS = {
  ZOOM: 'scenario-zoom',
  FONT: 'scenario-font',
  TEXT_TRANSFORM: 'scenario-text-transform',
}

const FONT_OPTIONS = {
  Default: 'Standaard',
  Arial: 'Arial',
  'Times New Roman': 'Times New Roman',
  Helvetica: 'Helvetica',
  'Fast Sans': 'Fast Modern (snellezen)',
  'Fast Serif': 'Fast Klassiek (snellezen)',
}

const cssVariables = {
  get(key) {
    return getComputedStyle(document.querySelector(':root')).getPropertyValue(`--${key}`)
  },
  set(key, value) {
    document.querySelector(':root').style.setProperty(`--${key}`, value)
  },
}

export default {
  name: 'FontControls',
  components: { NSwitch },
  data() {
    return {
      zoom: parseInt(cssVariables.get('scenario-zoom')) || 100,
      font: cssVariables.get('scenario-font') || 'Default',
      fontOptions: FONT_OPTIONS,
      textTransform: cssVariables.get('scenario-text-transform') || 'none',
    }
  },
  watch: {
    zoom() {
      cssVariables.set('scenario-zoom', this.zoom)
      this.persistPreferences()
    },
    font() {
      cssVariables.set('scenario-font', this.font)
      this.persistPreferences()
    },
    textTransform() {
      cssVariables.set('scenario-text-transform', this.textTransform)
      this.persistPreferences()
    },
  },
  mounted() {
    // Listen to local storage changes for cross-tab synchronization of preferences
    window.addEventListener('storage', (e) => {
      if (Object.values(LOCAL_STORAGE_KEYS).includes(e.key)) {
        this.zoom = parseFloat(localStorage.getItem(LOCAL_STORAGE_KEYS.ZOOM)) || this.zoom
        this.font = localStorage.getItem(LOCAL_STORAGE_KEYS.FONT) || this.font
        this.textTransform = localStorage.getItem(LOCAL_STORAGE_KEYS.TEXT_TRANSFORM) || this.textTransform
      }
    })
  },
  methods: {
    zoomIn() {
      this.zoom = Math.min(this.zoom + ZOOM_STEP, 500)
    },
    zoomOut() {
      this.zoom = Math.max(this.zoom - ZOOM_STEP, 10)
    },
    persistPreferences() {
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.ZOOM, this.zoom)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.FONT, this.font)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TEXT_TRANSFORM, this.textTransform)

      api.put(`/production/backend/user/preferences`, {
        user: {
          zoom_preference: this.zoom,
          font_preference: this.font,
          text_transform_preference: this.textTransform,
        },
      })
    },
    railStyle({ checked }) {
      return { background: checked ? '#5bc0de' : '#f1fcff' }
    },
  },
}
</script>

<style lang="scss" scoped>
.aa-icon {
  font-weight: 600;
  font-size: 1.2em;
}

.dropdown-menu {
  min-width: 300px;
}

.setting {
  padding-left: 16px;
  padding-right: 16px;

  button {
    background: none;
    border: none;
  }

  .descriptor {
    flex: 1;
    text-align: left;
    font-weight: 600;

    i {
      margin-right: 5px;
    }
  }
}

.setting-select {
  padding-top: 6px;
  padding-bottom: 6px;

  .descriptor {
    margin-bottom: 8px;
  }

  .value-change {
    button {
      display: block;
      margin-top: 5px;
      width: 100%;
      height: 40px;
      font-size: 1.3em;
      border: 1px solid #5bc0de;
      border-radius: 10px;

      &:hover {
        background: #f1fcff;
      }

      &.active {
        background: #5bc0de;
        color: white;
      }
    }
  }
}

.setting-number {
  display: flex;
  padding-top: 3px;
  padding-bottom: 3px;

  .value-change {
    button {
      border: 1px solid #ccc;
      border-radius: 100%;

      &:hover {
        background: #eee;
      }
    }
  }
}

.setting-zoom {
  display: none;

  @supports (zoom: 20%) {
    display: flex;
  }
}
</style>
