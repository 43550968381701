<template>
  <div v-if="stopwatch" class="stopwatch">
    <div v-if="isInTheFuture">Gaat live op: {{ startTime }}</div>
    <div v-else class="stopwatch__time">
      {{ formattedTime }}
    </div>
  </div>
  <div v-if="isRunning" class="actions">
    <button class="float-right btn btn-with-icon btn-danger" @click="stop">Stop</button>
  </div>
</template>

<script>
import api from '../store/API'
import { useNow } from '@/general/utils/useNow'
import { differenceInMilliseconds, addMilliseconds, intervalToDuration } from 'date-fns'

export default {
  name: 'Stopwatch',
  props: ['stopwatchId', 'stationId'],
  setup() {
    const now = useNow()

    return { now }
  },
  data() {
    return {
      stopwatch: null,
      timeDifference: null,
    }
  },
  computed: {
    time() {
      const start = new Date(this.stopwatch.startTime)
      const end = this.stopwatch.endTime ? new Date(this.stopwatch.endTime) : this.currentServerTime
      return intervalToDuration({ start, end })
    },
    isRunning() {
      return this.stopwatch && !this.isInTheFuture && !this.stopwatch.endTime
    },
    currentServerTime() {
      return addMilliseconds(this.now, this.timeDifference)
    },
    isInTheFuture() {
      return new Date(this.stopwatch.startTime) > this.currentServerTime
    },
    startTime() {
      return new Date(this.stopwatch.startTime).toLocaleString()
    },
    formattedTime() {
      const parts = ['days', 'hours', 'minutes', 'seconds']

      return parts.map((part) => `0${this.time[part]}`.slice(-2)).join(':')
    },
  },
  async mounted() {
    this.getStopwatch()
    this.listenToSockets()
  },
  methods: {
    async getStopwatch() {
      this.stopwatch = (await api.get(`/admin/stopwatches/${this.stopwatchId}.json`)).data.stopwatch
      this.timeDifference = differenceInMilliseconds(new Date(this.stopwatch.currentTime), this.now)
    },
    listenToSockets() {
      const socket = window.Q.connect(this.stationId)

      socket.subscribe({ stopwatch: this.stopwatchId }).on('stop', (message) => {
        this.stopwatch.endTime = message.end_time
      })
    },
    stop() {
      this.stopwatch.endTime = this.currentServerTime
      api.post(`/admin/stopwatches/${this.stopwatchId}/stop.json`, { end_time: this.stopwatch.endTime })
    },
  },
}
</script>

<style lang="scss" scoped>
.stopwatch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 2rem;

  &__time {
    padding: 1rem;
    font-size: 8rem;
    font-weight: bold;
  }
}

.actions {
  bottom: 0;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: end;
}
</style>
