<template>
  <div class="duration-input">
    <div class="col-sm-6 input-field">
      <input v-model="minutes" type="number" class="string required form-control" name="minutes" />
    </div>
    <span class="duration-label">m</span>
    <div class="col-sm-6 input-field">
      <input v-model="seconds" type="number" class="string required form-control" name="seconds" />
    </div>
    <span class="duration-label">s</span>
  </div>
</template>

<script>
export default {
  name: 'DurationInput',
  props: ['modelValue'],
  computed: {
    minutes: {
      get() {
        return Math.floor((this.modelValue % 3600) / 60)
      },
      set(minutes) {
        this.$emit('update:modelValue', minutes * 60 + this.seconds)
      },
    },
    seconds: {
      get() {
        return Math.floor(this.modelValue % 60)
      },
      set(seconds) {
        this.$emit('update:modelValue', this.minutes * 60 + seconds)
      },
    },
  },
}
</script>

<style scoped>
.duration-input {
  display: flex;
  align-items: center;
}

.input-field {
  padding: 0;
}

.duration-label {
  margin: 0 5px 0 2px;
}
</style>
