<template>
  <div>
    <div class="programs panel">
      <div class="panel-heading">
        <div class="row">
          <h4 class="col-sm-3">Geselecteerde melding</h4>
        </div>
      </div>
      <table class="table">
        <tbody>
          <ForbiddenWordRow
            v-for="detection in selectedDetectionList"
            v-bind="detection"
            :key="detection.id"
            :forbiddenWordId="id"
          />
        </tbody>
      </table>
    </div>

    <div class="programs panel">
      <div class="panel-heading">
        <div class="row">
          <h4 class="col-sm-3">Aantal meldingen ({{ count }})</h4>
          <div class="col-sm-5 pull-right">
            <DarioButton
              class="pull-right btn-margin"
              title="Refresh"
              icon="arrows-rotate"
              @click="refreshDetections"
            />
            <DarioButton
              class="pull-right"
              title="Willekeurige luisteraar selecteren"
              icon=""
              @click="selectRandomListener"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 pull-right">
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-calendar" /></span>
              <ApiDateTimeRange v-model="dateRange" />
            </div>
          </div>
        </div>
      </div>
      <table class="table">
        <tbody>
          <ForbiddenWordRow
            v-for="detection in detectionList"
            v-bind="detection"
            :key="detection.id"
            :forbiddenWordId="id"
          />
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5">
              <ApiPagination
                :totalPages="forbiddenWord.totalPages"
                :currentPage="forbiddenWord.currentPage"
                @changePage="changePage($event)"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ApiPagination from '@/general/components/ApiPagination.vue'
import ForbiddenWordRow from './ForbiddenWordRow.vue'
import ApiDateTimeRange from '../general/components/ApiDateTimeRange.vue'
import DarioButton from '../general/components/DarioButton.vue'

export default {
  name: 'ForbiddenWord',
  components: {
    DarioButton,
    ApiDateTimeRange,
    ApiPagination,
    ForbiddenWordRow,
  },
  props: ['id'],
  data() {
    return {
      searchTimeout: null,
    }
  },
  computed: {
    ...mapState('ForbiddenWord', {
      forbiddenWord: (state) => state.forbiddenWord,
    }),
    count() {
      return this.forbiddenWord.detectionsCount
    },
    detectionList() {
      return this.forbiddenWord.detections
    },
    selectedDetectionList() {
      return this.forbiddenWord.selectedDetections
    },
    dateRange: {
      get() {
        return this.forbiddenWord?.params?.dateRange
      },
      set(value) {
        this.updateParams({ begin: new Date(value[0]), end: new Date(value[1]) })
        this.fetchDetections(this.id)
      },
    },
  },
  mounted() {
    this.fetchDetections(this.id)
  },
  methods: {
    ...mapActions({
      fetchDetections: 'ForbiddenWord/fetchDetections',
      updateParams: 'ForbiddenWord/updateParams',
      selectWinner: 'ForbiddenWord/selectWinner',
    }),
    changePage(page) {
      this.updateParams({ page })
      this.fetchDetections(this.id)
    },
    refreshDetections() {
      this.updateParams({ page: 1 })
      this.fetchDetections(this.id)
    },
    async selectRandomListener() {
      await this.selectWinner(this.id)
      this.refreshDetections()
    },
  },
}
</script>

<style lang="scss">
.btn-margin {
  margin-left: 10px;
}
</style>
