import socket from './PublicSocket'
import api from './API'

function getChatIndexForId(chats, id) {
  return chats.findIndex((chat) => chat.uuid === id)
}

export default {
  namespaced: true,
  state: {
    chats: [],
    subscribedChats: {},
  },
  getters: {
    chatForId: (state) => (id) => {
      return state.chats.find((chat) => chat.uuid === id)
    },
    generatedContentForChat:
      (_state, getters) =>
      ({ uuid }) => {
        const chat = getters.chatForId(uuid)
        return chat?.generatedContent?.join('')
      },
  },
  actions: {
    async subscribe({ commit, state }, chatId) {
      if (!this.$socket) {
        this.$socket = await socket()
      }

      if (!state.subscribedChats[chatId]) {
        const llmConversationSocket = this.$socket.subscribe({
          'dario:llm_conversation': chatId,
        })
        llmConversationSocket.on('start', () => {
          commit('START_GENERATING_CONTENT', chatId)
        })
        llmConversationSocket.on('change', ({ content, idx }) => {
          commit('SET_GENERATING_CONTENT_CHAT', {
            chatId,
            idx,
            content: content ?? '',
          })
        })
        llmConversationSocket.on('name', ({ name }) => {
          commit('SET_CHAT_NAME', {
            chatId,
            name,
          })
        })
        llmConversationSocket.on('finish', () => {
          commit('SET_GENERATED_CONTENT_CHAT', {
            chatId,
            status: 'stopped',
          })
        })
        commit('SET_SUBSCRIBED_CHAT', chatId)
      }
    },
    async fetchChats({ commit }) {
      const { data } = await api.get('/production/llm/conversations')
      commit('SET_CHATS', data.conversations)
    },
    async fetchChat({ commit }, chatId) {
      const { data } = await api.get(`/production/llm/conversations/${chatId}`)
      commit('CHAT_UPDATE', { chatId, chat: data.conversation })
    },
    async updateChatSettings({ commit }, { chatId, settings }) {
      commit('UPDATE_CHAT_SETTINGS', { chatId, settings })
    },
    addChat({ commit }, chatId) {
      commit('ADD_CHAT', chatId)
    },
    async deleteChat({ commit }, chatId) {
      await api.delete(`/production/llm/conversations/${chatId}`)
      commit('REMOVE_CHAT', chatId)
    },
    async sendMessage({ commit, getters }, { chatId, content }) {
      const settings = getters.chatForId(chatId).settings ?? {}
      await api.post(`/production/llm/conversations/${chatId}/messages`, {
        ...settings,
        content,
      })

      commit('ADD_MESSAGE', {
        chatId,
        message: {
          content,
          role: 'user',
        },
      })
    },
  },
  mutations: {
    SET_CHATS(state, chats) {
      state.chats = chats
    },
    CHAT_UPDATE(state, { chatId, chat }) {
      const chatIndex = getChatIndexForId(state.chats, chatId)
      state.chats[chatIndex] = chat
    },
    UPDATE_CHAT_SETTINGS(state, { chatId, settings }) {
      const chatIndex = getChatIndexForId(state.chats, chatId)
      state.chats[chatIndex].settings = {
        ...state.chats[chatIndex].settings,
        ...settings,
      }
    },
    SET_SUBSCRIBED_CHAT(state, chatId) {
      state.subscribedChats[chatId] = true
    },
    ADD_CHAT(state, chatId) {
      state.chats.unshift({
        messages: [],
        name: '',
        uuid: chatId,
        settings: {
          mode: 'normal',
          with_context: true,
        },
      })
    },
    REMOVE_CHAT(state, chatId) {
      state.chats = state.chats.filter((chat) => chat.uuid !== chatId)
    },
    UNSUBSCRIBED_CHAT(state, chatId) {
      state.subscribedChats[chatId] = false
    },
    START_GENERATING_CONTENT(state, chatId) {
      const chatIndex = getChatIndexForId(state.chats, chatId)
      state.chats[chatIndex].status = 'running'
    },
    SET_CHAT_NAME(state, { chatId, name }) {
      const chatIndex = getChatIndexForId(state.chats, chatId)
      state.chats[chatIndex].name = name
    },
    ADD_MESSAGE(state, { chatId, message }) {
      const chatIndex = getChatIndexForId(state.chats, chatId)
      state.chats[chatIndex].messages.push(message)
    },
    SET_GENERATING_CONTENT_CHAT(state, { chatId, idx, content }) {
      const chatIndex = getChatIndexForId(state.chats, chatId)
      const chat = state.chats[chatIndex]
      if (!chat.generatedContent) {
        chat.generatedContent = []
      }

      chat.generatedContent[idx] = content
    },
    SET_GENERATED_CONTENT_CHAT(state, { chatId }) {
      const chatIndex = getChatIndexForId(state.chats, chatId)
      let chat = state.chats[chatIndex]
      state.chats[chatIndex] = {
        ...chat,
        messages: [
          ...chat.messages,
          {
            role: 'assistant',
            content: chat.generatedContent.join(''),
          },
        ],
        status: 'idle',
        generatedContent: null,
      }
    },
  },
}
