<template>
  <component :is="tagName" ref="app" class="color-tag-component">
    <div v-if="mode === 'stripes'">
      <div
        v-for="(tag, index) in firstCurrentTags"
        :key="tag.id"
        :title="titleTags"
        :class="colorTag(tag, index)"
        class="message-tag-stripes"
        @click.prevent="togglePopup()"
      />
    </div>

    <span v-if="mode === 'icon'" :class="iconColor" class="message-tag-icon" @click.prevent="togglePopup()">
      <i class="fa fa-tag rotate-45" />
    </span>

    <a
      v-if="mode === 'batch'"
      href="#"
      class="btn btn-with-icon btn-default batch-button"
      title="Tag"
      @click.prevent="togglePopup()"
    >
      <i class="fa fa-lg fa-tag rotate-45" />
    </a>

    <div v-if="open" class="dropdown--backdrop" @click.prevent="close" />
    <div v-if="open" class="popupWrapper dropdown dropleft open">
      <div ref="popup" class="dropdown-menu popup" :class="{ 'dropdown-menu-right': mode === 'icon' }">
        <div v-if="!editingTag">
          <div>
            <span class="title">Tags</span>
            <button class="close" @click.prevent="close"><i class="fa fa-times" /></button>
          </div>
          <div class="form-group">
            <input ref="query" v-model="query" type="text" class="form-control" placeholder="Zoek een tag" />
          </div>
          <ul class="list-group tag-list">
            <EditRow
              v-for="tag in tags"
              :key="tag.id"
              :mode="mode"
              :messageId="messageId"
              :messageIdsCallback="messageIdsCallback"
              :tag="tag"
              @edit="edit"
            />
          </ul>
          <button class="create-btn" href="#" @click.prevent="newTag">Maak een nieuwe tag</button>
        </div>
        <div v-if="editingTag">
          <div>
            <a href="#" class="back" @click.prevent="editingTag = false"><i class="fa fa-angle-left" /></a>
            <span class="title">Nieuwe Tag</span>
            <button class="close" @click.prevent="close"><i class="fa fa-times" /></button>
          </div>
          <MessageTagForm
            :messageIdsCallback="messageIdsCallback"
            :tag="editingTag"
            :messageId="messageId"
            @hide="editingTag = null"
          />
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import EditRow from './app/EditRow.vue'
import MessageTagForm from './app/MessageTagForm.vue'
import Helpers from './app/Helpers.js'
import './assets/stylesheets/button.scss'

export default {
  name: 'MessageTag',
  components: {
    MessageTagForm,
    EditRow,
  },
  props: {
    tagIds: Array,
    messageId: [String, Number],
    mode: String,
    messageIdsCallback: Function,
  },
  data() {
    return {
      query: '',
      editingTag: null,
      open: false,
    }
  },
  computed: {
    tagName() {
      if (this.mode === 'batch') {
        return 'span'
      } else {
        return 'td'
      }
    },

    tags() {
      return this.$store.getters['ColorTags/query'](this.query)
    },
    currentTags() {
      return this.$store.getters['MessageTags/colorTags'](this.messageId)
    },
    firstCurrentTags() {
      return this.currentTags.slice(0, 3)
    },
    iconColor() {
      const firstTag = this.currentTags[0]
      if (firstTag) {
        return `icon-color-${firstTag.color}`
      } else {
        return 'icon-color-default'
      }
    },
    titleTags() {
      return this.currentTags
        .map((tag) => {
          return tag.name
        })
        .join('\n')
    },
  },
  created() {
    this.$store.dispatch('MessageTags/setCurrentTagIds', [this.messageId, this.tagIds])
    this.$store.dispatch('ColorTags/fetchTags')
  },
  methods: {
    newTag() {
      this.query = ''
      this.editingTag = {
        name: '',
        color: '',
      }
    },
    edit(tag) {
      this.editingTag = tag
    },
    close() {
      this.open = false
      this.editingTag = null
      if (this.mode === 'batch') {
        this.$store.dispatch('MessageTags/setCurrentTagIds', [this.messageId, []])
      }
    },
    togglePopup() {
      if (this.open) {
        this.close()
      } else {
        this.open = true
        this.$nextTick(() => this.$refs.query.focus())
      }
    },
    colorTag(tag, index) {
      return `color-tag color-tag-${index + 1} ${Helpers.colorClass(tag.color)}`
    },
    ...Helpers,
  },
}
</script>

<style lang="scss" scoped>
.popup {
  width: 300px;
  border-radius: 5px;
  padding: 10px;

  .title {
    color: rgb(131, 140, 145);
    display: block;
    height: 37px;
    text-align: center;
    padding: 0px 32px;
  }

  .close {
    color: rgb(131, 140, 145);
    display: block;
    height: 18px;
    left: 267px;
    position: absolute;
    top: 0px;
    width: 19px;
    padding: 10px 10px 10px 4px;
  }
  .back {
    color: rgb(131, 140, 145);
    display: block;
    height: 18px;
    right: 267px;
    position: absolute;
    top: 0px;
    width: 19px;
    padding: 10px 10px 10px 4px;
  }
}
.tag-list {
  margin-bottom: 5px;
}
.color-tag {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
}
.color-tag-1 {
  left: 0;
}
.color-tag-2 {
  left: 5px;
}
.color-tag-3 {
  left: 10px;
}

.form-control {
  border-radius: 7px;
}

.popupWrapper {
  position: absolute;
  left: 15px;
}
td {
  position: relative;
}
.fa-times,
.fa-angle-left,
.fa-tag {
  font-size: 20px;
}
.batch-button {
  margin-left: -2px;
}
td.color-tag-component .icon-color-default .fa-tag {
  color: #bbbbbb;
}
.message-tag-stripes,
.message-tag-icon {
  cursor: pointer;
}
.fa-tag.rotate-45 {
  transform: rotate(-45deg);
}

.dropdown--backdrop {
  position: absolute;
  left: -100vw;
  top: -100vh;
  bottom: -100vh;
  right: -100vw;
  z-index: 999;
}

.create-btn {
  width: 100%;
  padding: 8px;
  background-color: #bbbbbb;
  color: #333;
  border: none;
  border-radius: 7px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
</style>
