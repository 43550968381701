import socket from './PublicSocket'
import api from './API'

export default {
  namespaced: true,
  state: {
    subscribed: false,
    forbiddenWord: {
      detections: [],
      detectionsCount: 0,
      totalPages: 0,
      params: {
        page: 1,
      },
    },
  },
  actions: {
    async subscribe({ commit, state }, id) {
      if (!this.$socket) {
        this.$socket = await socket()
      }

      if (!state.subscribed) {
        this.$socket.subscribe({ 'dario:forbidden_word_detections': id }).on('detection', () => commit('ADD_DETECTION'))
        commit('SET_SUBSCRIBED_FORBIDDEN_WORD')
      }
    },
    async fetchDetections({ commit, state }, id) {
      const { data } = await api.get(`/production/forbidden_words/${id}/detections.json`, {
        params: state.forbiddenWord.params,
      })
      commit('DETECTIONS_FETCHED', data)
    },
    async updateParams({ commit, state }, params) {
      commit('PARAMS_CHANGE', {
        ...state.forbiddenWord.params,
        page: 1,
        ...params,
      })
    },
    // eslint-disable-next-line no-empty-pattern
    async selectWinner({}, forbiddenWordId) {
      await api.post(`/production/forbidden_words/${forbiddenWordId}/detections/select_winner`)
    },
    async clearPerson({ dispatch }, { forbiddenWordId, detectionId }) {
      await api.post(`/production/forbidden_words/${forbiddenWordId}/detections/${detectionId}/clear`)
      dispatch('updateParams', { page: 1 })
      dispatch('fetchDetections', forbiddenWordId)
    },
  },
  mutations: {
    ADD_DETECTION(state, count) {
      state.forbiddenWord.detectionsCount = count
    },
    DETECTIONS_FETCHED(state, data) {
      state.forbiddenWord = {
        ...state.forbiddenWord,
        detectionsCount: data.totalCount,
        totalPages: data.totalPages,
        totalUnfilteredCount: data.totalUnfilteredCount,
        detections: data.detections.map((su) => ({
          ...su,
          selectedAt: su.selectedAt ? new Date(su.selectedAt) : null,
        })),
        selectedDetections: data.selectedDetections.map((su) => ({
          ...su,
          selectedAt: su.selectedAt ? new Date(su.selectedAt) : null,
        })),
      }
    },
    PARAMS_CHANGE(state, params) {
      state.forbiddenWord.params = params
    },
    SET_SUBSCRIBED_FORBIDDEN_WORD(state) {
      state.subscribedForbiddenWords = true
    },
  },
}
