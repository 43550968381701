<template>
  <div class="wrapper">
    <v-select
      v-bind="$props"
      ref="vSelect"
      v-model="inputVal"
      label="name"
      :clearable="false"
      appendToBody
      :calculatePosition="withPopper"
      :style="vSelectVariables"
    >
      <template #selected-option-container="{ option }">
        <div class="vs__selected">
          <SenderOption :option="option" />
        </div>
      </template>
      <template #option="option">
        <SenderOption :option="option" />
      </template>
      <template #no-options><span /></template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import SenderOption from './SenderOption.vue'
import { createPopper } from '@popperjs/core'

const vSelectVariables = {
  '--vs-font-size': '1.3rem',
  '--vs-controls-color': 'white',
  '--vs-controls-size': 0.7,
  '--vs-selected-color': 'white',
  '--vs-border-radius': '5px',
  '--vs-disabled-bg': 'transparent',
  '--vs-dropdown-bg': '#6a6463',
  '--vs-dropdown-color': 'white',
  '--vs-dropdown-min-width': 0,
  '--vs-dropdown-z-index': 1060,
  '--vs-dropdown-option-padding': '3px 9px',
  '--vs-dropdown-option--active-bg': '#443d3c',
}

export default {
  components: { SenderOption, vSelect },

  props: {
    ...vSelect.props,
    label: undefined,
    appendToBody: undefined,
    calculatePosition: undefined,
    style: undefined,
  },

  data() {
    return {
      vSelectVariables,
    }
  },

  computed: {
    inputVal: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
        this.$emit('change')
      },
    },
  },

  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width

      Object.entries(vSelectVariables).forEach(([key, value]) => dropdownList.style.setProperty(key, value))

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          },
        ],
      })

      return () => popper.destroy()
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  color: white;

  :deep(.vs__dropdown-toggle) {
    background-color: #6a6463;

    .vs__selected-options {
      width: 0;
      flex-wrap: nowrap;

      .vs__selected {
        width: 100%;
      }

      .vs__search {
        padding-left: 36px;
      }
    }
  }
}
</style>

<style lang="scss">
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
