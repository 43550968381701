<template>
  <div v-if="editionTrack">
    <h3>#{{ editionTrack.position }}: {{ editionTrack.track.artistName }} - {{ editionTrack.track.title }}</h3>
    <p>
      Dit nummer staat al <strong>{{ editionTrack.previous.length + 1 }}</strong> edities in deze lijst<br />
      Deze artiest staat in totaal <strong>{{ editionTrack.otherArtistTracks.length + 1 }} keer</strong> in de lijst
    </p>

    <div class="row">
      <div class="col-md-4">
        <h4>Vorige posities</h4>
        <ul>
          <li v-for="{ position, editionName } in editionTrack.previous" :key="editionName">
            <strong>{{ editionName }}</strong
            >: {{ position }}
          </li>
        </ul>
      </div>

      <div class="col-md-4">
        <h4>Nog te spelen ({{ higherOtherTracks.length }})</h4>
        <ul>
          <li v-for="{ id, track_title: trackTitle } in higherOtherTracks" :key="id">
            <strong>{{ trackTitle }}</strong>
          </li>
        </ul>
      </div>

      <div class="col-md-4">
        <h4>Reeds gespeeld ({{ lowerOtherTracks.length }})</h4>
        <ul>
          <li v-for="{ id, position, track_title: trackTitle } in lowerOtherTracks" :key="id">
            <strong>{{ trackTitle }}</strong> - {{ position }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/store/API'

export default {
  name: 'EditionTrackDetails',
  props: {
    editionTrackId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editionTrack: null,
    }
  },
  computed: {
    otherTracks() {
      return this.editionTrack.otherArtistTracks.toSorted((a, b) => a.position - b.position)
    },
    higherOtherTracks() {
      return this.otherTracks.filter(({ position }) => position < this.editionTrack.position)
    },
    lowerOtherTracks() {
      return this.otherTracks.filter(({ position }) => position > this.editionTrack.position)
    },
  },
  async created() {
    const {
      data: { editionTrack },
    } = await api.get(`/production/edition_tracks/${this.editionTrackId}`)

    this.editionTrack = editionTrack
  },
}
</script>

<style lang="scss" scoped>
h3 {
  margin-top: 0;
}
</style>
