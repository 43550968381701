<template>
  <div class="search-item">
    <div :class="color">
      <i :class="icon" />
    </div>
    <span class="search-item__header">
      {{ item.name }}
    </span>
    <span class="search-item__subheader">
      <strong>
        {{ item.resource?.title }}
      </strong>
      - {{ date }}
    </span>
    <div ref="description" class="search-item__body" :class="openClass" v-html="item.description" />
    <button v-if="showMore" class="show-more" @click="open = !open">
      <i v-if="open" class="fa fa-chevron-up" />
      <i v-else class="fa fa-chevron-down" />
    </button>
    <a class="action" target="_blank" :href="item.url">
      <i class="fa fa-external-link" />
    </a>
  </div>
</template>

<script>
import { icon, color } from '../general/utils/broadcastItem'
import { format, parseISO } from 'date-fns'

export default {
  name: 'BroadcastSearch',
  props: ['item'],
  data() {
    return {
      open: false,
      showMore: false,
    }
  },
  computed: {
    color() {
      return ['search-item__icon', color(this.item.item_type)]
    },
    icon() {
      return ['fa', icon(this.item.item_type)]
    },
    openClass() {
      return { open: this.open }
    },
    date() {
      return format(parseISO(this.item.resource?.starts_at), 'dd MMM yyyy HH:mm:ss')
    },
  },
  mounted() {
    this.showMore = this.$refs.description.scrollHeight > 150
  },
}
</script>

<style lang="scss" scoped>
.search-item {
  width: 100%;
  background-color: white;
  margin-bottom: 8px;
  display: grid;
  grid-template-areas:
    'icon header'
    'icon details'
    'icon description'
    'icon show-more';
  grid-template-columns: 25px 1fr;
  gap: 8px;
  grid-template-rows: 15px 15px 1fr 5px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  padding: 10px 5px;
  position: relative;

  &:hover .show-more i {
    display: block;
  }
}

.search-item__icon {
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  color: white;
}

.search-item__header {
  grid-area: header;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  font-size: 1.3em;
  font-weight: 600;
}

.search-item__subheader {
  grid-area: details;
  font-size: 0.9em;
  color: #888;
}

.search-item__body {
  overflow: hidden;
  max-height: 150px;

  &.open {
    max-height: none;
  }
}

.show-more {
  grid-area: show-more;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;

  i {
    bottom: -7px;
    display: none;
    width: 20px;
    height: 20px;
    color: white;
    border-radius: 99px;
    background-color: #888;
    text-align: center;
    line-height: 18px;
    font-size: 10px;
  }
}

.action {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 35px;
  height: 25px;
  color: #555;
  border-radius: 2px;
  background-color: #ddd;
  border: 1px solid #bbb;
  text-align: center;
  line-height: 22px;
  font-size: 15px;
  cursor: pointer;
}
</style>
