<template>
  <div class="reply-wrapper">
    <div class="modal-with-sidebar">
      <div class="left-column theme-dust">
        <div class="modal-profile">
          <div ref="modalProfileContent" class="modal-profile-content">
            <slot name="modal-profile-content" />
          </div>

          <div class="modal-profile-reply-tabs">
            <ul id="reply-tabs" class="nav">
              <li v-for="tab in ['messages', 'notes']" :key="tab" :class="{ active: activeTab === tab }">
                <a href="#" @click="activeTab = tab" v-text="tabLabels[tab]" />
              </li>
            </ul>
          </div>

          <div class="modal-profile-footer">
            <slot name="modal-profile-footer" />
          </div>
        </div>
      </div>

      <div class="right-column">
        <div class="modal-close">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
        </div>
        <div class="widget-chat replies">
          <div class="tab-content">
            <div
              v-for="tab in ['messages', 'notes']"
              :key="tab"
              :ref="tab"
              class="tab-pane panel no-padding no-border fade in"
              :class="{ active: activeTab === tab }"
            >
              <slot :name="`tab-${tab}`" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReplyArea
      v-if="activeTab === 'messages'"
      class="reply-area"
      :appSenders="appSenders"
      :profile="profile"
      :messageId="messageId"
      :doriaEnabled="doriaEnabled"
      @replySent="addReply"
    />
  </div>
</template>

<script>
import ReplyArea from './ReplyArea.vue'

export default {
  components: { ReplyArea },

  props: {
    tabLabels: { type: Object, required: true },
    appSenders: { type: Object, required: true },
    profile: { type: Object, required: true },
    messageId: { type: Number, required: true },
    doriaEnabled: { type: Boolean, required: true },
  },

  data() {
    return {
      activeTab: 'messages',
    }
  },

  mounted() {
    new window.Production.ProfileRater({
      el: this.$refs.modalProfileContent.getElementsByClassName('profile-rater-wrapper')[0],
    })

    this.$refs.messages[0].getElementsByClassName('panel-body')[0].scrollTo(0, Number.MAX_SAFE_INTEGER)
  },

  methods: {
    addReply(message) {
      const panel = this.$refs.messages[0].getElementsByClassName('panel-body')[0]

      panel.innerHTML += message
      panel.scrollTo(0, Number.MAX_SAFE_INTEGER)
    },
  },
}
</script>

<style lang="scss" scoped>
.reply-wrapper {
  max-height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;

  .modal-with-sidebar {
    flex: 1;

    .right-column {
      display: flex;
      flex-direction: column;

      .widget-chat {
        flex: 1;
        overflow: hidden;

        .tab-content {
          height: 100%;

          .tab-pane {
            height: 100%;

            :deep > div {
              height: 100%;

              .panel-body {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  .reply-area {
    flex-shrink: 0;
  }
}
</style>
