import { createApp, h } from 'vue'
import { store } from './store/index.js'
import { airbrakeVuePlugin } from './general/airbrake'
import { i18nVuePlugin } from './general/i18n'
import detectDeprecatedImageOrigins from './detectDeprecatedImageOrigins.js'
import MessageTag from './message-tags/MessageTag.vue'
import ContentCommentsOverview from './content-comments/ContentCommentsOverview.vue'
import SelectContestWinners from './select-contest-winners/SelectContestWinners.vue'
import SignUps from './sign_ups/SignUps.vue'
import SignUpScenario from './sign_ups/SignUpScenario.vue'
import ScheduleNews from './news/ScheduleNews.vue'
import ScenarioRequests from './requests/ScenarioRequests.vue'
import TrackImport from './track-import/TrackImport.vue'
import AmountCaster from './amount-caster/AmountCaster.vue'
import TrackAutocomplete from './dario-autocomplete/TrackAutocomplete.vue'
import PhoneSystemLines from './phone-system/PhoneSystemLines.vue'
import PhoneCallModal from './phone-system/PhoneCallModal.vue'
import PhoneCallButton from './phone-system/PhoneCallButton.vue'
import TrafficBE from './traffic/TrafficBE.vue'
import TrafficNL from './traffic/TrafficNL.vue'
import TrafficBroadcastItem from './traffic/TrafficBroadcastItem.vue'
import MessageCounterWidget from './dashboard/MessageCounterWidget.vue'
import Panel from './general/components/Panel.vue'
import ForbiddenWord from './forbidden_word/ForbiddenWord.vue'
import AudioPlayer from './general/components/AudioPlayer.vue'
import AudioPlayerWithOmniplayerExport from './general/components/AudioPlayerWithOmniplayerExport.vue'
import LlmChatButton from './llm-chat/LlmChatButton.vue'
import WysiwygTextarea from './general/components/Editor/WysiwygTextarea.vue'
import EmojiReply from './messages/EmojiReply.vue'
import DarioEmojiPickerNativeTextArea from './general/components/DarioEmojiPickerNativeTextArea.vue'
import FontControls from './scenario/FontControls.vue'
import BroadcastItemSearch from './broadcast-item-search/BroadcastItemSearch.vue'
import BroadcastItemSearchTrigger from './broadcast-item-search/BroadcastItemSearchTrigger.vue'
import ScenarioEditionTrackInfo from './scenario/ScenarioEditionTrackInfo.vue'
import RepliesModalContent from './replies/RepliesModalContent.vue'
import LocationTracker from './location_trackers/LocationTracker.vue'
import Stopwatch from './stopwatch/Stopwatch.vue'
import DynamicVuePage from './general/DynamicVuePage.vue'
import PollDetail from './polls/PollDetail.vue'
import './content/global_notification'
import './forms/forms-util'
import './general/utils/chip_input'
import './confetti/confetti'
import './scenario/scenario-edit-util'
import './phone-system/phoneCallHandler'

import './checkable-table'

detectDeprecatedImageOrigins()

const componentSelector = '[data-component]'
const components = {
  AmountCaster,
  AudioPlayer,
  AudioPlayerWithOmniplayerExport,
  BroadcastItemSearch,
  BroadcastItemSearchTrigger,
  ContentCommentsOverview,
  DarioEmojiPickerNativeTextArea,
  EmojiReply,
  FontControls,
  ForbiddenWord,
  LlmChatButton,
  MessageCounterWidget,
  MessageTag,
  Panel,
  PhoneCallModal,
  PhoneCallButton,
  PhoneSystemLines,
  ScenarioEditionTrackInfo,
  ScenarioRequests,
  ScheduleNews,
  SelectContestWinners,
  SignUps,
  SignUpScenario,
  TrackAutocomplete,
  TrackImport,
  TrafficBE,
  TrafficBroadcastItem,
  TrafficNL,
  WysiwygTextarea,
  RepliesModalContent,
  LocationTracker,
  Stopwatch,
  DynamicVuePage,
  PollDetail,
}

const initializeComponents = (element) => {
  const foundComponents = element.querySelectorAll
    ? element.querySelectorAll(componentSelector)
    : element.find(componentSelector)

  Array.prototype.slice.call(foundComponents).forEach((el) => {
    // Setup
    const dataset = el.dataset
    const component = components[dataset.component]
    const data = {}
    for (let key in dataset) {
      if (key !== 'component') {
        let value = dataset[key]
        try {
          value = JSON.parse(value)
        } catch (e) {
          // do nothing
        }
        data[key] = value
      }
    }

    if (component) {
      renderVueComponent(el, component, data)
    }
  })
}

const generateSlotsFromEl = (el) =>
  Object.fromEntries(
    Object.values(el.querySelectorAll('template'))
      .map((template) => {
        const nameAttribute = Object.entries(template.attributes).find(([_, el]) => el.name?.startsWith?.('#'))

        if (!nameAttribute) {
          return
        }

        let slotName = nameAttribute[1]?.name

        return [slotName.substring(1, slotName.length), () => h('div', { innerHTML: template.innerHTML })]
      })
      .filter((x) => x)
  )

export const renderVueComponent = (el, component, attributes) => {
  const app = createApp({
    name: `${component.name}-root`,
    setup() {
      return () =>
        h(component, attributes, {
          default: () => h('div', { innerHTML: el.innerHTML }),
          ...generateSlotsFromEl(el),
        })
    },
  })
  const fragment = document.createDocumentFragment()

  app.use(airbrakeVuePlugin)
  app.use(store)
  app.use(i18nVuePlugin)
  app.mount(fragment)

  el.replaceWith(fragment)

  return app
}

// Monitor the DOM for new components
const observer = new MutationObserver((mutations) => {
  for (const mutation of mutations) {
    for (const node of mutation.addedNodes) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        initializeComponents(node)
      }
    }
  }
})

document.addEventListener('DOMContentLoaded', () => {
  initializeComponents(document)

  observer.observe(document.body, { subtree: true, childList: true })
})

window.initializeMessageTagsBatch = (el, messageIdsCallback) => {
  renderVueComponent(el, MessageTag, {
    messageIdsCallback,
    mode: 'batch',
  })
}

window.initializeSelectWinners = (el, profileIdsCallback, afterSubmitCallback = null) => {
  return renderVueComponent(el, SelectContestWinners, { profileIdsCallback, afterSubmitCallback })
}

// Fix Leaflet markers in combination with vite build
// https://github.com/Leaflet/Leaflet/issues/4968
//
// We import the icons, then set them back to Leaflet (the `L` should
// already have been set), which will use the base64 encoded marker
// images
import L from 'leaflet'
import markerIconUrl from 'leaflet/dist/images/marker-icon.png'
import markerIconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import markerShadowUrl from 'leaflet/dist/images/marker-shadow.png'

L.Icon.Default.prototype.options.iconUrl = markerIconUrl
L.Icon.Default.prototype.options.iconRetinaUrl = markerIconRetinaUrl
L.Icon.Default.prototype.options.shadowUrl = markerShadowUrl
L.Icon.Default.imagePath = '' // necessary to avoid Leaflet adds some prefix to image path.
