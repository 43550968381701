<template>
  <apiModal size="large" class="llm-modal" @close="$emit('close')">
    <template #header>Doria</template>
    <div class="llm__container">
      <div class="llm__sidebar">
        <button @click="startNewChat()">
          <i class="fa fa-plus" />
          Nieuwe Chat
        </button>

        <div class="history-list">
          <div v-for="chat in startedChats" :key="chat.uuid" class="history-item" @click="openChat(chat.uuid)">
            <i class="fa fa-comment" />
            <span>{{ chat.name || 'Nieuwe chat' }}</span>
            <i class="delete_button fa fa-close" @click.stop="confirmDeleteChat(chat.uuid)" />
          </div>
        </div>
      </div>

      <LlmChat v-if="chatId" :key="chatId" :chatId="chatId" />
    </div>
  </apiModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import apiModal from '../general/components/ApiModal.vue'
import LlmChat from './LlmChat.vue'

export default {
  name: 'LlmChatModal',
  components: {
    LlmChat,
    apiModal,
  },
  emits: ['close'],
  data() {
    return {
      chatId: null,
    }
  },
  computed: {
    ...mapState('LlmConversation', ['chats']),
    startedChats() {
      return this.chats.filter((chat) => !Object.hasOwn(chat, 'messages') || chat.messages?.length > 0)
    },
  },
  async mounted() {
    await this.fetchChats()
    this.startNewChat()
  },
  methods: {
    ...mapActions({
      fetchChats: 'LlmConversation/fetchChats',
      fetchChat: 'LlmConversation/fetchChat',
      addChat: 'LlmConversation/addChat',
      deleteChat: 'LlmConversation/deleteChat',
    }),
    startNewChat() {
      this.chatId = crypto.randomUUID()
      this.addChat(this.chatId)
    },
    openChat(chatId) {
      this.chatId = chatId
      this.fetchChat(this.chatId)
    },
    confirmDeleteChat(chatId) {
      if (confirm('Weet je zeker dat je deze chat wilt verwijderen?')) {
        this.deleteChat(chatId)
        if (chatId === this.chatId) {
          this.startNewChat()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.llm {
  &__container {
    height: calc(100% - 43.5px);
    overflow: hidden;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
  }

  &__sidebar {
    width: 300px;
    height: 100%;
    grid-area: sidebar;
    background: white;
    padding: 25px 20px;

    .history-list {
      max-height: 90%;
      padding: 10px 0;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }

    .history-item {
      cursor: pointer;
      display: flex;
      padding: 15px 10px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      align-items: center;

      span {
        flex: 1;
      }

      .delete_button {
        visibility: hidden;
      }

      &:hover {
        .delete_button {
          visibility: inherit;
        }
      }
    }

    i {
      margin-right: 5px;
    }

    button {
      background: var(--mediumblue);
      color: white;
      border-radius: 7px;
      width: 100%;
      padding: 12px 30px;
      margin-bottom: 10px;
      border: none;
    }
  }
}
</style>
