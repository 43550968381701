<template>
  <div id="messageswrapper">
    <form id="search_form" @submit.prevent>
      <div class="panel input-group custom-search-form">
        <input v-model="query" class="search form-control" type="text" @keyup="search" />
        <span class="input-group-btn">
          <RefreshButton :count="newComments.length" @click="showNewComments" />
        </span>
      </div>
    </form>
    <div class="panel">
      <div class="panel-header clearfix">
        <a href="#" @click="toggleCheck()"><i class="btn fa-check-square-o fa" /></a>
        <span>Voer een actie uit op de {{ selectedComments.length }} geselecteerde berichten:</span>
        <a
          href="#"
          class="btn btn-with-icon btn-default"
          icon="trash"
          title="Verwijder"
          @click.prevent="destroySelected"
        >
          <i class="fa fa-lg fa-trash" />
        </a>
      </div>
      <table id="comments" class="checkable table-messages bla">
        <transition-group name="comment-list" tag="tbody">
          <ContentCommentRow v-for="comment in visibleComments" :key="comment.id" :comment="comment" />
        </transition-group>
        <tfoot>
          <tr>
            <td colspan="5">
              <a
                href="#"
                class="btn load-more"
                @click.prevent="$store.dispatch('ContentComments/fetch', { older: true, query: query })"
              >
                Meer laden
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import ContentCommentRow from './app/ContentCommentRow.vue'
import RefreshButton from '@/general/components/RefreshButton.vue'
import { mapGetters } from 'vuex'

let _searchTimeout

export default {
  name: 'ContentCommentsOverview',
  components: {
    ContentCommentRow,
    RefreshButton,
  },
  data() {
    return {
      toggleStatus: false,
      query: null,
    }
  },
  computed: {
    ...mapGetters('ContentComments', ['visibleComments', 'newComments', 'selectedComments']),
  },
  mounted() {
    this.$store.dispatch('Djs/fetch', {})
  },
  created() {
    // Load initial comments
    this.$store.dispatch('ContentComments/fetch', {})
  },
  methods: {
    toggleCheck() {
      this.toggleStatus = !this.toggleStatus
      this.$store.dispatch('ContentComments/toggleAll', { selected: this.toggleStatus })
    },

    showNewComments() {
      this.$store.dispatch('ContentComments/showNewComments')
    },

    destroySelected() {
      if (confirm(`Ben je zeker dat je ${this.selectedComments.length} reacties wilt verwijderen?`)) {
        this.$store.dispatch('ContentComments/hide', { ids: this.selectedComments.map((comment) => comment.id) })
      }
    },

    search() {
      clearTimeout(_searchTimeout)
      _searchTimeout = setTimeout(() => {
        this.$store.dispatch('ContentComments/fetch', { query: this.query })
      }, 300)
    },
  },
}
</script>

<style lang="scss" scoped>
$stransition-duration: 0.75s;

.comment-list-enter-active,
.comment-list-leave-active {
  transition: all $stransition-duration;
}
.comment-list-enter,
.comment-list-leave-to {
  opacity: 0;
}

.comment-list-move {
  transition: $stransition-duration;
}
.load-more {
  display: block;
  width: 120px;
  margin: 0 auto;
}
</style>
