export function color(broadcastItemType) {
  switch (broadcastItemType) {
    case 'track':
      return 'bg-info'
    case 'traffic':
      return 'bg-warning'
    default:
      return 'bg-success'
  }
}

export function icon(broadcastItemType) {
  switch (broadcastItemType) {
    case 'track':
      return 'fa-music'
    case 'note':
    case 'selector-note':
      return 'fa-quote-left'
    case 'guest':
      return 'fa-user'
    case 'action':
      return 'fa-calendar'
    case 'performance':
      return 'fa-microphone'
    case 'item':
      return 'fa-info'
    case 'game':
      return 'fa-gamepad'
    case 'text':
      return 'fa-quote-left'
    case 'phonecall':
      return 'fa-mobile'
    case 'traffic':
      return 'fa-car'
    case 'poll':
    case 'news':
      return 'fa-tasks'
    default:
      return 'fa-quote-right'
  }
}
