<template>
  <form action="#" method="post" class="select-contest-winners-form" @submit.prevent="submitForm()">
    <div class="form-group">
      <template v-if="dateSelection">
        <label class="control-label" for="contest-moment">
          <div>
            Wedstrijdmomenten
            <a href="#" @click.prevent="dateSelection = false">(huidige wedstrijdmomenten)</a>
          </div>
        </label>
        <ApiDatepicker v-model="momentsDate" placeholder="Kies een andere dag" />
      </template>
      <label v-else class="control-label" for="contest-moment">
        Huidige wedstrijdmomenten
        <a href="#" @click.prevent="dateSelection = true">(kies een datum)</a>
      </label>

      <select v-if="displayContestMoments" id="contest-moment" v-model="selectedContestMoment" class="form-control">
        <option
          v-for="contestMoment in contestMoments"
          :key="contestMoment.id"
          :value="contestMoment"
          :selected="contestMoment.id === selectedContestMoment.id"
        >
          {{ contestMoment.name }}
        </option>
      </select>
      <p v-else-if="!dateSelection || momentsDate">
        Er zijn op dit moment geen wedstrijdmomenten actief (<a href="/production/contests/"
          >voeg wedstrijdmomenten toe</a
        >).
      </p>
    </div>

    <p v-if="tooManyWinnersSelected" class="alert alert-danger">
      Je staat op het punt om {{ pluralizePrizes(profiles.length) }} weg te geven, maar we hebben in dit wedstrijdmoment
      (nog) maar {{ pluralizePrizes(selectedContestMoment.availablePrizes) }} weg te geven.
    </p>

    <p v-if="pressedSubmit && selectedPrizes.length == 0" class="alert alert-danger">
      Je hebt geen prijs geselecteerd.
    </p>

    <p v-if="validationMessage" class="alert alert-danger">
      {{ validationMessage }}
    </p>

    <div v-if="selectedContestMoment" class="form-group">
      <label class="control-label" for="prizes">Prijzen</label>
      <template v-for="prize in availablePrizes" :key="prize.id">
        <Prize :prize="prize" :selectedContestMoment="selectedContestMoment" />
        <AlertSplitPrize
          v-if="prize.checked && prize.type == 'money'"
          :key="'alert' + prize.id"
          :profiles="profiles"
          :prize="prize"
        />
      </template>
    </div>

    <ProfileList :profiles="profiles" />

    <button class="btn btn-primary" :disabled="!canSubmit">Selecteer winnaars</button>
    <button class="btn btn-secondary" @click.prevent="$emit('close')">Annuleren</button>
  </form>
</template>

<script>
import Prize from './Prize.vue'
import AlertSplitPrize from './AlertSplitPrize.vue'
import ProfileList from './ProfileList.vue'
import { mapState } from 'vuex'
import ApiDatepicker from '../../general/components/ApiDatepicker.vue'

export default {
  name: 'SelectContestWinnersForm',
  components: {
    ProfileList,
    AlertSplitPrize,
    Prize,
    ApiDatepicker,
  },
  props: {
    profileIds: Array,
  },
  data() {
    return {
      errors: [],
      availablePrizes: [],
      pressedSubmit: false,
      validationMessage: null,
      submitting: false,
      dateSelection: false,
    }
  },
  computed: {
    ...mapState('SelectWinners', ['profiles', 'contestMoments']),
    displayContestMoments() {
      return this.contestMoments.length > 0 && (!this.dateSelection || this.momentsDate)
    },
    selectedPrizes() {
      return this.availablePrizes.filter((prize) => prize.checked)
    },
    selectedContestMoment: {
      get() {
        return this.$store.state.SelectWinners.selectedContestMoment
      },
      set(contestMoment) {
        this.$store.dispatch('SelectWinners/setContestMoment', contestMoment)
      },
    },
    momentsDate: {
      get() {
        return this.$store.state.SelectWinners.momentsDate
      },
      set(momentsDate) {
        this.$store.dispatch('SelectWinners/setMomentsDate', momentsDate)
      },
    },
    tooManyWinnersSelected: function () {
      if (!this.selectedContestMoment) {
        return false
      }
      return this.profiles.length > this.selectedContestMoment.availablePrizes
    },
    canSubmit() {
      return this.selectedContestMoment && this.profiles.length > 0 && !this.submitting
    },
  },
  watch: {
    selectedContestMoment: {
      handler: function (newContestMoment) {
        if (newContestMoment && newContestMoment.prizes.length) {
          this.availablePrizes = newContestMoment.prizes.map((prize) => ({ ...prize, checked: !prize.optional }))
        }
      },
    },
    dateSelection: {
      handler() {
        this.momentsDate = null
      },
    },
  },
  created() {
    this.$store.dispatch('SelectWinners/fetchProfiles', [this.profileIds])
    this.$store.dispatch('SelectWinners/fetchContestMoments')
  },
  methods: {
    reset() {
      this.dateSelection = false
      this.momentsDate = null
    },
    submitForm() {
      this.submitting = true
      this.pressedSubmit = true
      if (this.selectedPrizes.length) {
        this.$store
          .dispatch('SelectWinners/submitForm', this.selectedPrizes)
          .then(() => {
            this.$emit('afterSubmit')
            this.$emit('close')
            window.jQuery('[data-role=select-all]').data('checkAll', true)
            window.jQuery('[data-role=select-all]').click()
          })
          .catch((error) => {
            this.submitting = false
            this.validationMessage = error.response.data.error
          })
      }
    },
    winnersTranslation(winners) {
      if (winners === 1) {
        return `${winners} winnaar`
      }
      return `${winners} winnaars`
    },
    pluralizePrizes(prizes) {
      if (prizes === 1) {
        return `1 prijs`
      }
      return `${prizes} prijzen`
    },
  },
}
</script>

<style lang="scss" scoped>
.distribution-message {
  margin-bottom: 10px;
}
.select-contest-winners-form {
  font-size: 15px;
}
.control-label {
  font-weight: bold;
}
.form-errors {
  margin-top: 10px;
}
</style>
