export const cdnUrl = (path, version = '') => {
  if (!path?.length || path.startsWith('http')) return path

  if (path[0] != '/') {
    path = `/${path}`
  }

  if (version.length && version[0] != '/') {
    version = `/${version}`
  }

  return `${window.userConfig.cdnUrl}${version}${path}`
}
