import { store } from './index'

window.QSocket &&
  window.QSocket.ready((e, q) => {
    q.subscribe('dario:message').on('tag_change', (message) => {
      store.dispatch('MessageTags/setCurrentTagIds', [message.id, message.color_tag_ids])
    })

    q.subscribe('dario:color_tag').on('create', (colorTag) => {
      store.dispatch('ColorTags/create', { tag: colorTag, sockets: true })
    })

    q.subscribe('dario:color_tag').on('update', (colorTag) => {
      store.dispatch('ColorTags/updateWithoutApiCall', [colorTag.id, colorTag])
    })

    q.subscribe('dario:color_tag').on('destroy', (colorTag) => {
      store.dispatch('ColorTags/destroy', { tag: colorTag, sockets: true })
    })

    q.subscribe('comment').on('new', (comment) => {
      store.dispatch('ContentComments/liveCommentsAdded', comment)
    })
  })
