import Bold from '@tiptap/extension-bold'

export const CustomBold = Bold.extend({
  parseHTML() {
    const rules = Bold.config.parseHTML.call(this)
    rules.push({
      tag: 'b',
    })

    return rules
  },
})
