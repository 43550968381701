<template>
  <ApiModal @close="$emit('close')">
    <template #header>
      <h4 class="modal-title">Stem details</h4>
    </template>
    <div class="modal-body">
      <div v-if="loading">
        <i class="fa fa-spinner fa-spin" />
        Stem details zijn aan het laden...
      </div>
      <template v-else>
        <div class="section-title">Naam</div>
        <span>{{ voteDetails.name }}</span>

        <div class="section-title">Telefoonnummer</div>
        <PhoneToLink :number="voteDetails.mobile" :name="voteDetails.name" />
        <DarioConsentBadge :hasPermission="voteDetails.consent" />

        <div class="section-title">Woonplaats</div>
        <span>{{ voteDetails.city }}</span>

        <h4 class="votes-title">Stemmen</h4>
        <div class="votes-container">
          <div v-for="vote in voteDetails.votes" :key="vote.position">
            <div class="track">#{{ vote.position }} - {{ vote.track }} - {{ vote.artist }}</div>
            <p>{{ vote.comment }}</p>
          </div>
        </div>
      </template>
    </div>
  </ApiModal>
</template>

<script>
import api from '@/store/API'
import ApiModal from '@/general/components/ApiModal.vue'
import PhoneToLink from '../general/components/PhoneToLink.vue'
import DarioConsentBadge from '../general/components/DarioConsentBadge.vue'

export default {
  name: 'ScenarioVotes',
  components: {
    ApiModal,
    PhoneToLink,
    DarioConsentBadge,
  },
  props: ['editionVoteId'],
  data() {
    return {
      loading: false,
      voteDetails: {},
    }
  },
  mounted() {
    this.fetchVoteDetails()
  },
  methods: {
    async fetchVoteDetails() {
      if (this.loading) {
        return
      }
      this.loading = true

      try {
        this.voteDetails = (await api.get(`/enrichment/votes/${this.editionVoteId}.json`)).data
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.label {
  margin-left: 5px;
}

.section-title {
  font-weight: bold;
  margin: 5px 0;
}

.track {
  font-weight: bold;
}

.votes-container {
  margin-left: 10px;
}

.votes-title {
  margin-top: 15px;
}
</style>
