export default {
  namespaced: true,
  state: {
    current: window.userConfig?.user,
    colors: ['#FFB300', '#803E75', '#FF6800', '#A6BDD7', '#C10020', '#CEA262', '#817066'],
  },
  getters: {
    userColor(state) {
      if (!state.current) return '#000000'

      return state.colors[state.current.id % state.colors.length]
    },
  },
}
