<template>
  <TrafficBase @items="items = $event" @majorRoadsOnlyChange="majorRoadsOnly = $event">
    <div class="row">
      <div class="col-md-6">
        <div class="panel">
          <div class="panel-heading">
            <strong>Files</strong>
            <DelaySelector
              v-model="delayThreshold"
              :traffic="byCategory('traffic')"
              :delayThresholds="[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60]"
              :showSuffix="false"
            />
          </div>
          <div class="count-announcer">
            <span>
              <strong>{{ totalTrafficCount }} files</strong> in Nederland
            </span>
            <span>
              <strong>{{ thresholdTrafficCount }} files</strong> van <strong>{{ delayThreshold }} minuten</strong> of
              langer
            </span>
            <div class="pull-right">
              <i class="fa fa-sort" />
              <OptionSelector v-model="sortBy" class="pull-right" :options="sortOptions" />
            </div>
          </div>
        </div>

        <div v-if="!traffic.length" class="alert alert-info">
          Er zijn op dit moment geen files met een vertraging langer dan {{ delayThreshold }} minuten.
        </div>

        <TrafficList :list="traffic" :showRoad="true" country="nl" />

        <div v-if="incidents.length">
          <div class="panel">
            <div class="panel-heading">
              <strong>Incidenten</strong>
            </div>
          </div>
          <TrafficList :list="incidents" :showRoad="true" country="nl" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="panel">
          <div class="panel-heading">
            <strong>Flitsers</strong>
            <OptionSelector v-model="reportsThreshold" class="pull-right" :options="reportsThresholdOptions" />
          </div>
          <div class="count-announcer">
            <span>
              <strong>{{ totalSpeedtrapsCount }} flitsers</strong> in Nederland
            </span>
            <span>
              <strong>{{ thresholdSpeedtrapsCount }} flitsers</strong> meer dan
              <strong>{{ reportsThreshold }}x</strong> gemeld
            </span>
          </div>
        </div>
        <div v-if="!speedtraps.length" class="alert alert-info">
          Er zijn op dit moment geen flitsers met meer dan {{ reportsThreshold }} meldingen.
        </div>
        <TrafficList :list="speedtraps" :showRoad="true" country="nl" />
      </div>
    </div>
  </TrafficBase>
</template>

<script>
import { sortByDesc } from '@/general/utils/collections'
import { useNow } from '@/general/utils/useNow'
import TrafficBase from './TrafficBase.vue'
import OptionSelector from './OptionSelector.vue'
import TrafficList from './TrafficList.vue'
import DelaySelector from '@/traffic/DelaySelector.vue'

export default {
  name: 'Traffic',
  components: { DelaySelector, TrafficBase, OptionSelector, TrafficList },
  setup() {
    const currentDate = useNow()

    return { currentDate }
  },
  data() {
    const currentLayout = {
      country: 'nl',
      listByRoad: false,
    }

    return {
      items: [],

      majorRoadsOnly: true,
      delayThreshold: 5,
      reportsThreshold: 10,
      sortBy: 'road',

      sortOptions: {
        road: {
          label: 'Weg',
          fn: (list) =>
            list.sort((a, b) => a.road.localeCompare(b.road, undefined, { numeric: true, sensitivity: 'base' })),
        },
        duration: { label: 'Duur', fn: (list) => sortByDesc(list, 'delay') },
      },

      currentLayout,
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => !this.majorRoadsOnly || item.roadLetter === 'A')
    },
    traffic() {
      const items = this.trafficAboveThreshold(this.delayThreshold)
      return this.sortOptions[this.sortBy].fn(items)
    },
    totalTrafficCount() {
      return this.countTrafficAboveThreshold()
    },
    thresholdTrafficCount() {
      return this.countTrafficAboveThreshold(this.delayThreshold)
    },
    speedtraps() {
      return this.speedtrapsAboveThreshold(this.reportsThreshold)
    },
    totalSpeedtrapsCount() {
      return this.countSpeedtrapsAboveThreshold()
    },
    thresholdSpeedtrapsCount() {
      return this.countSpeedtrapsAboveThreshold(this.reportsThreshold)
    },
    incidents() {
      return this.byCategory('incidents')
    },
    reportsThresholdOptions() {
      return Object.fromEntries(
        [0, 10, 50, 100].map((threshold) => [
          threshold,
          {
            label: threshold === 0 ? 'Alle' : `> ${threshold}x`,
            count: this.countSpeedtrapsAboveThreshold(threshold),
          },
        ])
      )
    },
  },
  methods: {
    byCategory(category) {
      return this.filteredItems.filter((item) => item.typeCategory === category)
    },
    trafficAboveThreshold(threshold = 0) {
      return this.byCategory('traffic').filter((item) => item.delay >= threshold)
    },
    countTrafficAboveThreshold(threshold = 0) {
      return this.trafficAboveThreshold(threshold).length
    },
    speedtrapsAboveThreshold(threshold = 0) {
      return this.byCategory('speedtraps').filter((item) => item.updates >= threshold)
    },
    countSpeedtrapsAboveThreshold(threshold = 0) {
      return this.speedtrapsAboveThreshold(threshold).length
    },
  },
}
</script>

<style scoped>
.panel {
  padding: 0;
  margin-bottom: 5px;
}

.count-announcer {
  padding: 10px;
  font-size: 0.9em;
  text-align: center;
  text-transform: uppercase;
  background-color: #f5f5f5;
}

.count-announcer > * + * {
  margin-left: 15px;
}
</style>
