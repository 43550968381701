import Color from '@tiptap/extension-color'

export const CustomColor = Color.extend({
  addGlobalAttributes() {
    return [
      {
        types: ['font', 'textStyles', 'bold'],
        attributes: {
          color: {
            default: null,
            parseHTML: (element) => element.color,
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {}
              }

              return {
                color: attributes.color,
              }
            },
          },
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['font', HTMLAttributes, 0]
  },

  addCommands() {
    return {
      setColor:
        (color) =>
        ({ chain }) => {
          return chain().setMark('font', { color }).run()
        },
      unsetColor:
        () =>
        ({ chain }) => {
          return chain().setMark('font', { color: null }).removeEmptyTextStyle().run()
        },
    }
  },
})
