<template>
  <div class="audio" :data-attachment-url="audio.media_url">
    <audio controls="" preload="none">
      <source :src="audio.media_url" type="audio/wav" />
    </audio>
  </div>
</template>

<script>
export default {
  name: 'PreviewVideo',
  props: ['audio'],
}
</script>
