<template>
  <div v-if="item" class="mgp-form">
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-4">
            <label class="string required control-label">Duur</label>
            <DurationInput v-model="item.duration" />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-1">
            <label class="control-label">Winner</label>
          </div>

          <div class="col-sm-11">
            <label class="control-label">Track titel</label>
          </div>
        </div>

        <div v-for="track of item.items" :key="track.id" class="row">
          <div class="col-sm-1">
            <input :id="`winner-${track.id}`" type="radio" :checked="track.winner" @change="selectWinner(track.id)" />
          </div>
          <div class="col-sm-11">
            <label :for="`winner-${track.id}`">
              <span class="artist_name">{{ track.artist_name }}</span> -
              <span class="track_title">{{ track.title }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DurationInput from '../general/components/DurationInput.vue'

export default {
  components: { DurationInput },
  props: ['modelValue'],
  data() {
    return {
      item: null,
    }
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  mounted() {
    this.item = { ...this.modelValue }
  },
  methods: {
    selectWinner(id) {
      this.item.items.forEach((track) => {
        track.winner = track.id === id
      })
    },
  },
}
</script>
