<template>
  <NDatePicker v-model:value="date" type="datetimerange" clearable />
</template>

<script>
import { NDatePicker } from 'naive-ui'

export default {
  name: 'ApiDateTimeRange',
  components: { NDatePicker },
  props: ['modelValue', 'placeholder'],
  computed: {
    date: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
