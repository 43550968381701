<template>
  <div>
    <div class="form-group">
      <label for="name">Naam</label>
      <input id="name" ref="name" v-model="name" type="text" class="form-control" @keyup.enter="save" />
    </div>
    <div class="form-group">
      <div class="btn-group">
        <button
          v-for="a_color in colors"
          :key="a_color"
          type="button"
          class="btn"
          :class="colorClass(a_color)"
          @click.prevent="color = a_color"
        >
          <i v-if="a_color == color" class="fa fa-check" />
        </button>
      </div>
    </div>
    <button class="btn btn-success pull-right" @click.prevent="save">Opslaan</button>
    <button v-if="!new_record" class="btn btn-danger" @click.prevent="destroy">Verwijder</button>
  </div>
</template>

<script>
import Helpers from './Helpers.js'

export default {
  name: 'MessageTagForm',
  props: ['tag', 'messageId', 'messageIdsCallback'],
  data() {
    return {
      name: null,
      color: null,
      colors: [
        'black',
        'darkgray',
        'gray',
        'brown',
        'red',
        'orange',
        'goldenrod',
        'olive',
        'green',
        'teal',
        'blue',
        'pink',
      ],
    }
  },
  computed: {
    enabled() {
      return this.$store.getters.enabled([this.messageId, this.tag])
    },
    new_record() {
      return !this.tag.id
    },
  },
  created() {
    this.name = this.tag.name
    this.color = this.tag.color || this.colors[0]
  },
  mounted() {
    this.$nextTick(() => this.$refs.name.focus())
  },
  methods: {
    save() {
      if (!this.name) {
        return
      }

      if (this.new_record) {
        this.$store.dispatch('ColorTags/create', { tag: { name: this.name, color: this.color } }).then((colorTag) => {
          this.$store.dispatch('MessageTags/toggle', [this.messageId, colorTag, this.messageIdsCallback])
          this.$emit('hide')
        })
      } else {
        this.$store.dispatch('ColorTags/update', [this.tag.id, { name: this.name, color: this.color }])
        this.$emit('hide')
      }
    },
    destroy() {
      if (
        confirm('Ben je zeker dat je deze tag overal wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.')
      ) {
        this.$store.dispatch('ColorTags/destroy', { tag: this.tag })
        this.$emit('hide')
      }
    },
    ...Helpers,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.color-button {
  height: 43px;
  aspect-ratio: 1;
  border-radius: 50% !important;
}

.form-control {
  border-radius: 7px;
}

label {
  padding-left: 3px;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
</style>
