<template>
  <div :class="roadContainerClass">
    <div class="road" :class="`road-${country}-${roadLetter}`">
      <div class="road-number">
        {{ road }}
      </div>
      <div v-if="hmp" class="hmp">
        {{ hmp.toFixed(1) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['road', 'roadLetter', 'hmp', 'country'],
  computed: {
    roadContainerClass() {
      if (!this.roadLetter) {
        return
      }
      if (this.hmp) {
        return 'road-hmp'
      }
      return `road-${this.country}`
    },
  },
}
</script>

<style scoped>
.road-nl {
  width: 5em;
}

.road-be {
  width: 10em;
}

.road-hmp {
  width: 8.5em;
}

.road {
  border-radius: 0.25em;
  background: lightgray;
  display: inline-flex;
  overflow: hidden;
}

.road-number {
  padding: 0.1em 0.3em;
  font-weight: bold;
}

.road-nl-A {
  font-size: 1.6em;
  background: #f03415;
  color: white;
}

.road-nl-N {
  font-size: 1.6em;
  background: #fcae1b;
  color: black;
}
.road-be-A {
  font-size: 1.6em;
  background: #f3f3f3;
  color: black;
  border: 2px solid black;
}

.road-be-R {
  font-size: 1.2em;
  background: #f3f3f3;
  color: black;
  border: 2px solid black;
}

.road-be-N {
  font-size: 1.6em;
  background: #006bff;
  color: white;
}
.road-be-E {
  font-size: 1.4em;
  background: #00a10a;
  color: white;
}

.hmp {
  padding: 0.1em 0.3em;
  background: #036200;
  color: white;
  font-size: 0.8em;
  font-weight: 600;

  display: flex;
  align-items: center;
}
</style>
