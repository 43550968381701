<template>
  <div class="message">
    <div class="message" :class="{ left: reply, 'no-avatar': !reply }">
      <div class="message-body">
        <div class="message-heading">
          {{ comment.from.name }} schreef:
          <span class="pull-right timeago" :title="comment.commentedAt" />
        </div>
        <div class="message-text">
          <CommentTextAndMedia :comment="comment" />
          <div class="pull-right">
            <a v-if="!reply" href="#" class="react btn btn-xs" @click="editing = !editing">
              <i class="fa fa-comment" /> Reageer
            </a>
          </div>
        </div>
      </div>
    </div>

    <ReplyModalMessage
      v-for="other_comment in comment.replies"
      :key="other_comment.id"
      :comment="other_comment"
      :reply="true"
    />
    <ReplyForm v-if="editing" :comment="comment" />
  </div>
</template>

<script>
import ReplyForm from './ReplyForm.vue'
import CommentTextAndMedia from './CommentTextAndMedia.vue'

export default {
  name: 'ReplyModalMessage',
  components: {
    ReplyForm,
    CommentTextAndMedia,
  },
  props: ['comment', 'reply'],
  data() {
    return {
      editing: false,
    }
  },
}
</script>

<style>
.react {
  position: absolute;
  top: 9px;
  right: 9px;
}
</style>
