import api from './API'

export default {
  namespaced: true,
  state: {
    broadcasts: [],
  },
  getters: {
    currentDjs(state) {
      return state.broadcasts
        .map((broadcast) => {
          return broadcast.presenters
        })
        .flat()
    },
  },
  mutations: {
    setBroadcasts(state, { broadcasts }) {
      state.broadcasts = broadcasts
    },
  },
  actions: {
    async fetch({ commit }) {
      const twoHourAgo = window.moment(new Date()).subtract({ hours: 2 }).format('DD/MM/YYYY HH:mm')
      const response = await api.get('/2.4/broadcasts', { begin: twoHourAgo })
      const broadcasts = response.data.broadcasts
      commit('setBroadcasts', { broadcasts: broadcasts })
    },
  },
}
