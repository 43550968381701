import './LiveUpdates.js'
import { createStore } from 'vuex'
import AudioPlayers from './AudioPlayers.js'
import BroadcastItems from './BroadcastItems.js'
import Broadcasts from './Broadcasts.js'
import ColorTags from './ColorTag.js'
import ContentComments from './ContentComments.js'
import Djs from './Djs.js'
import ForbiddenWord from './ForbiddenWord.js'
import LlmConversation from './LlmConversation.js'
import MessageTags from './MessageTag.js'
import Programs from './Programs.js'
import SelectWinners from './SelectWinners.js'
import SignUps from './SignUps.js'
import TrackImport from './TrackImport.js'
import Users from './Users.js'
import Polls from './Polls.js'

export const store = createStore({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    AudioPlayers,
    BroadcastItems,
    Broadcasts,
    ColorTags,
    ContentComments,
    Djs,
    ForbiddenWord,
    LlmConversation,
    MessageTags,
    Programs,
    SelectWinners,
    SignUps,
    TrackImport,
    Users,
    Polls,
  },
})
