<template>
  <div :class="progressClass">
    <div class="progress-bar progress-bar-striped" :class="progressType" :style="progressBarStyle" />
    <span class="answer">{{ label }}</span>

    <div class="score-block">
      <slot name="score" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DarioProgressBar',
  props: {
    label: String,
    percentage: String,
    type: String,
    size: {
      type: String,
      default: 'large',
    },
  },
  computed: {
    progressBarStyle() {
      return {
        width: `${this.percentage}`,
      }
    },
    progressType() {
      return `progress-bar-${this.type}`
    },
    progressClass() {
      return {
        progress: true,
        'progress-striped': true,
        'progress-large': this.size === 'large',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.progress-large {
  position: relative;
  height: 30px;
  font-size: 16px;
}

.score-block {
  position: absolute;
  top: 3px;
  right: 10px;
  font-size: 13px;
}

.answer {
  position: absolute;
  top: 5px;
  left: 10px;
}
</style>
