import api from './API'

export default {
  namespaced: true,
  state: {
    programs: [],
  },
  mutations: {
    setPrograms(state, programs) {
      state.programs = programs
    },
  },
  actions: {
    async fetchPrograms({ commit }) {
      const { programs } = await (await api.get('/production/programs')).data
      commit('setPrograms', programs)
    },
  },
}
