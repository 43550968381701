<template>
  <Panel icon="envelope" :collapsable="true" :defaultOpen="false" headerColor="purple">
    <template #title>
      <i class="fa fa-envelope" />
      Berichten
      <span class="bubble"> {{ messageCount }} </span>
    </template>
    <div>
      <div class="message-stats">
        <LineGraph labelFormat="{value}h" :datapoints="dataPoints" class="graph" />
      </div>
      <div class="stat-row">
        <div class="stat-counters bordered text-center">
          <div class="stat-cell col-xs-12 padding-sm no-padding-hr">
            <span class="text-bg">
              <strong>{{ messageCount }}</strong>
            </span>
            <br />
            <span class="text-xs text-muted">Berichten</span>
          </div>
        </div>
      </div>
    </div>
  </Panel>
</template>

<script>
import LineGraph from '../general/components/LineGraph.vue'
import Panel from '../general/components/Panel.vue'

export default {
  name: 'MessageCounter',
  components: { Panel, LineGraph },
  props: ['messageCount', 'dataPoints'],
}
</script>

<style lang="scss">
.graph {
  width: 100%;
  height: 350px;
}

.bubble {
  margin-left: 10px;
  padding: 1px 12px;
  border-radius: 20px;
  background-color: #635373;
}
</style>
