export function cutAudioBuffer(audioBuffer, startTimeInMilliseconds, endTimeInMilliseconds) {
  const startOffset = Math.floor((startTimeInMilliseconds * audioBuffer.sampleRate) / 1000)
  const endOffset = Math.floor((endTimeInMilliseconds * audioBuffer.sampleRate) / 1000)

  const newBuffer = new AudioBuffer({
    length: endOffset - startOffset,
    numberOfChannels: audioBuffer.numberOfChannels,
    sampleRate: audioBuffer.sampleRate,
  })

  channelsData(audioBuffer).forEach((channelData, channelNumber) =>
    newBuffer.copyToChannel(channelData.slice(startOffset, endOffset), channelNumber)
  )

  return newBuffer
}

/**
 * Convert an `AudioBuffer` to a raw WAV file.
 *
 * Based on https://russellgood.com/how-to-convert-audiobuffer-to-audio-file/, rewritten in modern JS.
 */
export function audioBufferToWav(audioBuffer) {
  const numOfChannels = audioBuffer.numberOfChannels
  const length = audioBuffer.length * numOfChannels * 2 + 44
  const buffer = new ArrayBuffer(length)
  const view = new DataView(buffer)
  let position = 0
  let offset = 0

  // Helper functions to set data in the DataView
  const setUint16 = (data) => {
    view.setUint16(position, data, true)
    position += 2
  }

  const setUint32 = (data) => {
    view.setUint32(position, data, true)
    position += 4
  }

  // Write WAVE header
  setUint32(0x46464952) // "RIFF"
  setUint32(length - 8) // file length - 8
  setUint32(0x45564157) // "WAVE"

  setUint32(0x20746d66) // "fmt " chunk
  setUint32(16) // length = 16
  setUint16(1) // PCM (uncompressed)
  setUint16(numOfChannels)
  setUint32(audioBuffer.sampleRate)
  setUint32(audioBuffer.sampleRate * 2 * numOfChannels) // avg. bytes/sec
  setUint16(numOfChannels * 2) // block-align
  setUint16(16) // 16-bit (hardcoded in this demo)

  setUint32(0x61746164) // "data" - chunk
  setUint32(length - position - 4) // chunk length

  const channels = channelsData(audioBuffer)

  // Write interleaved data
  while (position < length) {
    channels.forEach((channel) => {
      // interleave channels
      let sample = Math.max(-1, Math.min(1, channel[offset])) // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0 // scale to 16-bit signed int
      view.setInt16(position, sample, true) // update data chunk
      position += 2
    })
    offset++ // next source sample
  }

  // Create Blob
  return new Blob([buffer], { type: 'audio/wav' })
}

export function channelsData(audioBuffer) {
  return Array.from({ length: audioBuffer.numberOfChannels }, (_, i) => audioBuffer.getChannelData(i))
}

export async function renderOfflineAudio(audioBuffer, nodeHandler = (c, n) => n) {
  const context = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate)

  const source = context.createBufferSource()
  source.buffer = audioBuffer

  const finalNode = nodeHandler(context, source)

  finalNode.connect(context.destination)

  source.start()

  const renderedBuffer = await context.startRendering()

  return renderedBuffer
}

export function monoChannel(context, source, channelNumber) {
  const splitter = context.createChannelSplitter(2)
  source.connect(splitter)

  const merger = context.createChannelMerger(2)

  splitter.connect(merger, channelNumber, 0)
  splitter.connect(merger, channelNumber, 1)

  return merger
}
