export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  )

export const sortBy = (items, key) => items.sort((a, b) => a[key] - b[key])
export const sortByDesc = (items, key) => items.sort((a, b) => b[key] - a[key])
