const confettiCount = 200
const gravityConfetti = 0.3
const dragConfetti = 0.075
const terminalVelocity = 3
const colors = [
  { front: '#7b5cff', back: '#6d4eff' },
  { front: '#ff89dd', back: '#ff7cd0' },
  { front: '#93ff54', back: '#41f845' },
  { front: '#ff955c', back: '#ff9041' },
  { front: '#fd4242', back: '#ff1f1f' },
  { front: '#ffde5c', back: '#ffd225' },
]
let animationRendering = false
const confetti = []

const canvas = document.createElement('canvas')
const ctx = canvas.getContext('2d')
canvas.width = window.innerWidth
canvas.height = window.innerHeight
canvas.id = 'confetti-container'

document.body.appendChild(canvas)

function randomRange(min, max) {
  return Math.random() * (max - min) + min
}

// helper function to get initial velocities for confetti
function initconfettiVelocity(xRange, yRange) {
  const x = randomRange(xRange[0], xRange[1])
  const range = yRange[1] - yRange[0] + 1
  let y = yRange[1] - Math.abs(randomRange(0, range) + randomRange(0, range) - range)
  if (y >= yRange[1] - 1) {
    y += Math.random() < 0.25 ? randomRange(1, 3) : 0
  }
  return { x: x, y: -y }
}

class Confetti {
  constructor() {
    const side = Math.random() > 0.5 ? 'left' : 'right'
    this.randomModifier = randomRange(0, 99)
    this.color = colors[Math.floor(randomRange(0, colors.length))]
    this.dimensions = {
      x: randomRange(5, 9),
      y: randomRange(8, 15),
    }
    this.position = {
      x: side === 'left' ? randomRange(0, canvas.width / 2) : randomRange(canvas.width / 2, canvas.width),
      y: randomRange(canvas.height / 5, canvas.height / 4),
    }
    this.rotation = side === 'left' ? randomRange(0, Math.PI / 2) : randomRange((Math.PI * 2) / 3, Math.PI * 2)
    this.scale = {
      x: 1,
      y: 1,
    }
    this.velocity = initconfettiVelocity([-9, 9], [6, 11])
  }

  update() {
    this.velocity.x -= this.velocity.x * dragConfetti
    this.velocity.y = Math.min(this.velocity.y + gravityConfetti, terminalVelocity)
    this.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random()

    this.position.x += this.velocity.x
    this.position.y += this.velocity.y
    this.scale.y = Math.cos((this.position.y + this.randomModifier) * 0.09)
  }
}

function initBurst() {
  for (let i = 0; i < confettiCount; i++) {
    confetti.push(new Confetti())
  }

  if (!animationRendering) {
    animationRendering = true
    render()
  }
}

function render() {
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  ctx.restore()

  confetti.forEach((confetti) => {
    const width = confetti.dimensions.x * confetti.scale.x
    const height = confetti.dimensions.y * confetti.scale.y

    ctx.translate(confetti.position.x, confetti.position.y)
    ctx.rotate(confetti.rotation)

    confetti.update()
    ctx.fillStyle = confetti.scale.y > 0 ? confetti.color.front : confetti.color.back
    ctx.fillRect(-width / 2, -height / 2, width, height)
    ctx.setTransform(1, 0, 0, 1, 0, 0)
  })
  confetti.forEach((conf, index) => {
    if (conf.position.y >= canvas.height) confetti.splice(index, 1)
  })

  if (confetti.length === 0) {
    animationRendering = false
  } else {
    window.requestAnimationFrame(render)
  }
}

window.startConfetti = () => initBurst()

const onLoad = () => {
  // Dashboard will have this selector, only start confetti automatically on the dashboard
  if (document.querySelector('.js-it-s-your-birthday')) {
    window.startConfetti()
  }
  // If the user presses the cake, show confetti
  document.querySelectorAll('.js-confetti-button').forEach((el) => {
    el.addEventListener('click', () => {
      window.startConfetti()
    })
  })
}

document.addEventListener('DOMContentLoaded', onLoad)
