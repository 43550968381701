function initListeners() {
  document.addEventListener(
    'change',
    (e) => {
      if (e.target.getAttribute('data-form-toggle-fields')) {
        toggleFields(e.target.getAttribute('data-form-toggle-fields'), e.target.value)
      }
    },
    { passive: true }
  )
}

function toggleFields(key, value) {
  document.querySelectorAll('[data-form-show-on-field]').forEach((elem) => {
    const field = elem.getAttribute('data-form-show-on-field').split(':')
    if (field[0] === key) {
      if (field[1] === value) {
        elem.style.display = 'block'
      } else {
        elem.style.display = 'none'
      }
    }
  })
}

function hideFieldsOnLoad() {
  document.querySelectorAll('[data-form-toggle-fields]').forEach((elem) => {
    if ((elem.type === 'radio' || elem.type === 'checkbox') && !elem.checked) {
      return
    }

    toggleFields(elem.getAttribute('data-form-toggle-fields'), elem.value)
  })
}

window.addEventListener(
  'load',
  () => {
    hideFieldsOnLoad()
    initListeners()
  },
  { passive: true }
)
