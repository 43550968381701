<template>
  <div>
    <div class="modal-backdrop fade in" @click.self="closeModal" />
    <div class="modal fade modal-alert call-handler in" :class="modalClasses" @click.self="closeModal">
      <div class="modal-dialog phone-call-modal">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close-btn close" @click="closeModal">×</button>
            <i class="fa fa-phone" />
          </div>
          <div
            v-if="stateRepresentation.text"
            class="alert alert-page alert-dark"
            :class="`alert-${stateRepresentation.severity}`"
          >
            <strong>{{ stateRepresentation.text }}</strong>
          </div>

          <div class="modal-body">
            <div class="phone-number-input">
              <h3>Wie wil je bellen?</h3>
              <input v-model="number" type="text" class="form-control" placeholder="Telefoonnummer" />
            </div>

            <div class="text-left">
              <n-tabs ref="tabs" v-model:value="activeTab" type="segment" animated>
                <n-tab-pane v-if="studioCallsEnabled" name="studio-call" tab="Studio">
                  <StudioCall
                    :name="name"
                    :number="number"
                    :gigyaId="gigyaId"
                    @state="state = $event"
                    @noClients="disableStudioCalls"
                  />
                </n-tab-pane>
                <n-tab-pane v-if="privateCallsEnabled" name="private-call" tab="Eigen Telefoon">
                  <PrivateCall :number="number" @state="state = $event" />
                </n-tab-pane>
              </n-tabs>
              <duv v-if="!studioCallsEnabled && !privateCallsEnabled" class="alert alert-warning">
                Er zijn geen telefoonsystemen beschikbaar voor dit station.
              </duv>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NTabs, NTabPane } from 'naive-ui'
import StudioCall from './StudioCall.vue'
import PrivateCall from './PrivateCall.vue'

export default {
  name: 'PhoneCallModal',
  components: {
    NTabs,
    NTabPane,
    StudioCall,
    PrivateCall,
  },
  props: ['name', 'initialNumber', 'gigyaId'],
  emits: ['close'],
  data() {
    return {
      number: this.initialNumber,
      state: 'idle',
      activeTab: 'studio-call',
      studioCallsEnabled: true,
      privateCallsEnabled: window.userConfig.features.privateCalls,
    }
  },
  computed: {
    modalClasses() {
      return {
        'call-failed': this.state === 'failed',
        calling: this.state === 'calling',
        [`modal-${this.stateRepresentation.severity}`]: true,
      }
    },
    stateRepresentation() {
      return {
        idle: {
          severity: 'success',
        },
        connecting: {
          text: 'Verbinden...',
          severity: 'info',
        },
        calling_first: {
          text: 'Bellen naar jouw telefoon...',
          severity: 'warning',
        },
        calling_second: {
          text: 'Nu bellen naar de ander...',
          severity: 'warning',
        },
        active: {
          text: 'Je bent verbonden.',
          severity: 'success',
        },
        ended: {
          text: 'Gesprek beëindigd.',
          severity: 'danger',
        },
        calling: {
          text: 'De lijn gaat nu over!',
          severity: 'success',
        },
        failed: {
          text: `Er ging iets mis! Heb je alles goed ingevuld?`,
          severity: 'danger',
        },
      }[this.state]
    },
  },
  watch: {
    state() {
      // Reset state after a while to present the user with a clean slate if no call is active.
      clearTimeout(this.resetStateTimeout)
      if (this.state === 'connecting') {
        this.resetStateTimeout = setTimeout(() => (this.state = 'idle'), 20000)
      }
      if (this.state === 'ended') {
        this.resetStateTimeout = setTimeout(() => (this.state = 'idle'), 5000)
      }
    },
  },
  async mounted() {
    document.addEventListener('keydown', this.handleKeyDown)
  },
  unmounted() {
    document.removeEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    handleKeyDown(e) {
      if (e.key === 'Escape') {
        this.closeModal()
      }
    },
    disableStudioCalls() {
      this.studioCallsEnabled = false
      this.activeTab = 'private-call'
    },
  },
}
</script>

<style lang="scss" scoped>
.call-handler {
  z-index: 2000;
  display: block;
}

.close-btn {
  margin-right: 20px;
}

.phone-number-input {
  margin-bottom: 20px;

  h3 {
    font-size: 14px;
    font-weight: 600;
  }

  input {
    font-size: 27px;
    color: #888;
    text-align: center;
    height: auto;
  }
}
</style>

<style lang="scss">
.n-tabs-nav--segment-type {
  margin-left: -20px;
  margin-right: -20px;
}

.n-tabs .n-tabs-rail {
  padding: 5px 20px;
  background-color: #edece7;
  border-radius: 0;
}

.n-tabs.n-tabs--top .n-tab-pane {
  padding-top: 20px;
}
</style>
