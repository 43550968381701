<template>
  <DarioEmojiPickerTextArea v-model="text" v-bind="$attrs" />
</template>

<script>
import DarioEmojiPickerTextArea from './DarioEmojiPickerTextArea.vue'

export default {
  components: { DarioEmojiPickerTextArea },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      text: this.value,
    }
  },
  mounted() {
    this.$el.querySelector('textarea').addEventListener('clear', () => {
      this.text = ''
    })
  },
}
</script>
