<template>
  <div v-if="distributionType() === 'fully' || distributionType() === 'partly'" class="alert">
    <div v-if="profiles.length > 1">
      We verdelen de prijs van {{ formatPrice(totalPrizeValue) }} EUR over {{ pluralizeWinners(profiles.length) }}. Elke
      winnaar krijgt <strong>{{ formatPrice(prizeValue) }} EUR</strong>.
    </div>
    <div v-else>
      De winnaar krijgt <strong>{{ formatPrice(prizeValue) }} EUR</strong>.
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertSplitPrize',
  props: {
    profiles: null,
    prize: Object,
  },
  computed: {
    prizeValue: function () {
      let value = 0
      switch (this.distributionType()) {
        case 'partly':
          value = this.totalPrizeValue / this.profiles.length
          break
        case 'arbitrary':
          value = this.customPrizeValue
          break
        default:
          value = this.totalPrizeValue
      }
      return parseFloat(value || 0)
    },
    totalPrizeValue: function () {
      let value = 0
      if (this.prize) {
        value = this.prize.totalPrizeValue
      }
      return parseFloat(value)
    },
  },
  methods: {
    distributionType: function () {
      if (this.prize && this.prize.type === 'money') {
        return this.prize.distributionType
      } else {
        return null
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    pluralizeWinners(count) {
      if (count === 1) {
        return '1 winnaar'
      }
      return `${count} winnaars`
    },
  },
}
</script>

<style lang="scss" scoped>
.alert {
  margin-top: 1em;
}
</style>
