<template>
  <div v-if="item" class="poll-form">
    <div class="row">
      <div class="col-sm-12">
        <h3>Poll details</h3>

        <label class="string required control-label" for="poll_question_image">Afbeelding</label>
        <DarioImageUploader v-model="item.image" />

        <label class="string required control-label" for="poll_question_title">Titel</label>
        <input
          id="poll_question_title"
          v-model="item.title"
          class="string required form-control"
          name="title"
          placeholder="bvb. 90s Battle, Favoriete fast-food, ..."
        />

        <label class="string required control-label" for="poll_question_content">Vraag</label>
        <textarea
          id="poll_question_content"
          v-model="item.content"
          class="string required form-control"
          name="content"
          placeholder="bvb. Welke 90s klassieker wil jij straks horen?"
          rows="2"
        />

        <label class="string required control-label" for="poll_question_answers">Antwoorden</label>
        <div class="answers striped-children">
          <component
            :is="answerComponent"
            v-for="answer in visibleAnswers"
            :key="answer.id || answer.uuid"
            v-model:body="answer.body"
            v-model:image="answer.image"
            :fixedWinner="answer.fixed_winner"
            class="answer striped-child nested-fields"
            @update:fixedWinner="setFixedWinner(answer)"
            @remove="removeAnswer(answer)"
          />
        </div>
        <div class="row">
          <div class="col-md-12">
            <button type="button" class="btn answer-btn" @click="addAnswer">Antwoord toevoegen</button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button
              class="btn pull-right"
              @click.prevent="showAdditionalQuestions = !showAdditionalQuestions"
              v-text="showAdditionalQuestions ? 'Minder opties' : 'Meer opties'"
            />
          </div>
        </div>

        <div v-if="showAdditionalQuestions">
          <label class="string required control-label" for="poll_question_hide_timeout_seconds">
            Hoelang blijft poll zichtbaar na het sluiten?
          </label>
          <select
            id="poll_question_hide_timeout_seconds"
            v-model="item.hide_timeout_seconds"
            class="string required form-control"
            name="hide_timeout_seconds"
          >
            <option v-for="option in timeoutOptions" :key="option.value" :value="option.value">
              {{ option.label }}
            </option>
          </select>

          <label class="string required control-label" for="poll_question_complete_message">Bericht na stemming</label>
          <textarea
            id="poll_question_complete_message"
            v-model="item.complete_message"
            class="string required form-control"
            name="complete_message"
            rows="2"
          />

          <div class="checkbox">
            <label>
              <input v-model="item.authentication_required" type="checkbox" /> Gebruiker moet aangemeld zijn om te
              stemmen
            </label>
          </div>

          <div class="checkbox">
            <label> <input v-model="item.publish_percentage" type="checkbox" /> Resultaten publiek </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Answer from './polls/Answer.vue'
import TrackAnswer from './polls/TrackAnswer.vue'
import DarioImageUploader from '../general/components/DarioImageUploader.vue'

export default {
  components: {
    Answer,
    TrackAnswer,
    DarioImageUploader,
  },
  props: ['modelValue', 'pollType'],
  data() {
    return {
      item: null,
      timeoutOptions: [
        { value: 60, label: '1 min' },
        { value: 120, label: '2 min' },
        { value: 300, label: '5 min' },
        { value: 600, label: '10 min' },
        { value: 900, label: '15 min' },
        { value: 1800, label: '30 min' },
        { value: 3600, label: '1 uur' },
        { value: 7200, label: '2 uur' },
      ],
      showAdditionalQuestions: false,
    }
  },
  computed: {
    isMusicPoll() {
      return this.pollType === 'music-poll'
    },
    answerComponent() {
      return this.isMusicPoll ? 'TrackAnswer' : 'Answer'
    },
    visibleAnswers() {
      return this.item.answers.filter((answer) => !answer._destroy)
    },
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  mounted() {
    this.item = { ...this.modelValue }

    // Start with 2 empty answers
    if (!this.item.answers) {
      this.item.answers = [
        { body: '', uuid: crypto.randomUUID() },
        { body: '', uuid: crypto.randomUUID() },
      ]
    }
  },
  methods: {
    removeAnswer(answer) {
      if (answer.id) {
        answer._destroy = true
      } else {
        this.item.answers = this.item.answers.filter((a) => a.uuid !== answer.uuid)
      }
    },
    addAnswer() {
      this.item.answers.push({
        body: '',
        uuid: crypto.randomUUID(),
      })
    },
    setFixedWinner(changedAnswer) {
      this.item.answers = this.item.answers.map((answer) => ({
        ...answer,
        fixed_winner: answer === changedAnswer ? !answer.fixed_winner : false,
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
.control-label,
.checkbox,
.answer-btn {
  margin-top: 10px;
}

textarea.form-control {
  height: 60px;
}
</style>
