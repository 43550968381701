<template>
  <div>
    <div class="panel">
      <div class="panel-heading">
        <span v-text="locationTracker.name" />

        <div class="labels">
          <template v-if="isLastLocationUpToDate">
            <span class="label" :class="lastLocation.batteryLabel">
              <span>
                <i :class="lastLocation.batteryIcon" />
              </span>
              {{ lastLocation.battery_level }}%
              <span v-if="lastLocation.isCharging" :title="$t('admin.location_trackers.show.charging')">
                <i class="fa fa-bolt" />
              </span>
            </span>
          </template>

          <span
            v-if="isLastLocationUpToDate"
            class="label label-success"
            :title="$t('admin.location_trackers.show.has_received_updates')"
          >
            <i class="fa fa-check" />
            {{ $t('admin.location_trackers.show.online') }}
          </span>

          <span v-else class="label label-danger" :title="$t('admin.location_trackers.show.has_not_received_updates')">
            <i class="fa fa-times" />
            {{ $t('admin.location_trackers.show.offline') }}
          </span>
        </div>
      </div>
      <div class="panel-body">
        <slot name="data" />
      </div>
    </div>

    <div
      v-if="notAllLocationsLoaded"
      class="alert alert-warning"
      v-text="$t('admin.location_trackers.show.not_all_locations_loaded')"
    />

    <location-tracker-locations
      :sortedLocations="locationsFormatted"
      :locationTracker="locationTracker"
      @locationDeleted="deleteLocation"
    />
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import LocationTrackerLocations from '@/location_trackers/LocationTrackerLocations.vue'
import { useNow } from '@/general/utils/useNow'
import socketPromise from '@/store/PublicSocket'

export default {
  name: 'LocationTracker',
  components: { LocationTrackerLocations },

  props: {
    locationTracker: { type: Object, required: true },
    initialLocations: { type: Array, required: true },
    totalLocationCount: { type: Number, required: true },
    stationId: { type: String, required: true },
  },

  setup() {
    const now = useNow()

    return { now }
  },

  data() {
    return {
      locations: this.initialLocations,

      // Determine this here, because we don't want socket updates to change the outcome
      notAllLocationsLoaded: this.initialLocations.length < this.totalLocationCount,
    }
  },

  computed: {
    locationsFormatted() {
      return this.locations
        .map((location) => ({
          ...location,
          recordedAt: new Date(location.recorded_at),
        }))
        .sort((a, b) => b.recordedAt - a.recordedAt)
    },

    lastLocation() {
      const lastLocation = this.locationsFormatted[0]

      return {
        ...lastLocation,
        isCharging: location.battery_status === 'charging',
        batteryIcon: this.batteryFaIcon(lastLocation.battery_level),
        batteryLabel: this.batteryLabel(lastLocation.battery_level),
      }
    },

    isLastLocationUpToDate() {
      return this.lastLocation && this.now - this.lastLocation.recordedAt < 5 * 60 * 1000
    },
  },

  created() {
    this.listenToSockets()
  },

  methods: {
    async listenToSockets() {
      // We need to wait for the socket to be connected, but cannot use the socket itself because in admin we don't have
      // a station and have to provide the station
      await socketPromise()

      const socket = window.Q.connect(this.stationId, window.userConfig.publicSocketUrl)

      socket.subscribe({ location_tracker: this.locationTracker.uuid }).on('new_location', (message) => {
        this.locations.push(message.location)
      })
    },

    deleteLocation(location) {
      this.locations = this.locations.filter((l) => l.id !== location.id)
    },

    batteryLabel(level) {
      if (level < 10) return 'label-danger'
      if (level < 30) return 'label-warning'

      return 'label-success'
    },

    batteryFaIcon(level) {
      return `fa fa-battery-${this.batteryIcon(level)}`
    },

    batteryIcon(level) {
      if (level < 25) return 'empty'
      if (level < 50) return 'quarter'
      if (level < 75) return 'half'
      if (level < 90) return 'three-quarters'
      if (90 <= level) return 'full'

      return 'empty'
    },
  },
}
</script>

<style scoped lang="scss">
.panel-heading {
  text-align: right;

  .labels {
    > .label {
      margin-left: 5px;
    }
  }
}
</style>
