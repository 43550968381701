import InlineScenarioForm from './InlineForm.vue'
import { renderVueComponent } from '../main'

function initListeners() {
  document.addEventListener('click', (event) => {
    const elem = event.target

    if (elem.dataset.role === 'inline-edit') {
      const container = elem.closest('.broadcast_item')
      loadInlineEdit(container)
    }
  })

  document.querySelectorAll('.broadcast_item').forEach((elem) => {
    elem.addEventListener('mouseenter', () => showAddButtons(elem))
  })

  document.querySelectorAll('.hour').forEach((elem) => {
    elem.addEventListener('mouseenter', () => showCreateButtonsIfNoItems(elem))
  })

  const mutationObserver = new MutationObserver(addEventListenerToItem)

  document.querySelectorAll('.timeline > .hour').forEach((elem) => {
    mutationObserver.observe(elem, { childList: true })
  })
}

function addEventListenerToItem(mutationList) {
  for (const mutation of mutationList) {
    mutation.addedNodes.forEach((elem) => {
      elem.addEventListener('mouseenter', () => showAddButtons(elem))
    })
  }
}

function loadInlineEdit(container) {
  const { resourceId, resourceType, id: itemId, type: itemType, showUrl, hour } = container.dataset
  const app = renderVueComponent(container, InlineScenarioForm, {
    resourceId,
    resourceType,
    itemId,
    itemType,
    showUrl,
    hour,
    onClose: () => app.unmount(),
  })
}

function showAddButtons(elem) {
  const container = elem.closest('.broadcast_item')

  if (!container) return

  const broadcastItemId = container.getAttribute('data-id')
  const broadcastItemType = container.getAttribute('data-type')
  const broadcastId = container.getAttribute('data-resource-id')
  createAddButton(container, 'before', broadcastId, broadcastItemId, broadcastItemType)
  createAddButton(container, 'after', broadcastId, broadcastItemId, broadcastItemType)
}

function showCreateButtonsIfNoItems(elem) {
  const container = elem.closest('.hour')
  const broadcast = elem.closest('.broadcast')
  const hour = container.dataset.hourOfBroadcast

  // The hour div is always present, so we need to check if there are any other items
  if (container.querySelectorAll('[data-resource-id]').length === 0) {
    createAddButton(container, 'new', broadcast.dataset.broadcastId, hour)
  }
}

function createAddButton(elem, position, broadcastId, hour) {
  const btn = document.createElement('div')
  btn.classList.add('tl-adder-icon')
  btn.innerHTML = '+'

  if (position === 'before') {
    btn.classList.add('add-button--before')
    btn.dataset.addPosition = 'insertBefore'
  } else if (position === 'after') {
    btn.classList.add('add-button--after')
    btn.dataset.addPosition = 'insertAfter'
  } else if (position === 'new') {
    btn.classList.add('add-button--new')
    btn.dataset.addPosition = 'insertAfter'
  }

  elem.prepend(btn)

  btn.addEventListener('click', () => {
    createNewItem(elem, position, broadcastId, hour)
  })

  elem.addEventListener('mouseleave', function removeButton() {
    btn.remove()
    elem.removeEventListener('mouseleave', removeButton)
  })
}

function createNewItem(el, position, broadcastId, hour) {
  const container = document.createElement('div')
  container.classList.add('broadcast_item', 'tl-entry', 'inline_new_form')
  container.dataset.resourceId = broadcastId
  container.dataset.hour = hour

  if (position === 'before') {
    el.insertAdjacentElement('beforebegin', container)
  } else {
    el.insertAdjacentElement('afterend', container)
  }

  loadInlineEdit(container)
}

window.addEventListener(
  'load',
  () => {
    initListeners()
  },
  { passive: true }
)
