import axios from 'axios'

let csrfToken, publicApiUrl
const instance = axios.create({
  headers: {
    Accept: 'application/json',
  },
  'Content-Type': 'application/json',
})

instance.interceptors.request.use((config) => {
  if (!csrfToken) {
    csrfToken = document.querySelector('meta[name="csrf-token"]').content
    publicApiUrl = window.userConfig?.apiUrl
  }

  config.headers['X-CSRF-Token'] = csrfToken
  if (config.url.match(/^\/\d+\.\d+/)) {
    // Using the public URL
    config.url = `//${publicApiUrl}${config.url}`
    delete config.headers['X-CSRF-Token']
  }

  return config
})

export default instance
