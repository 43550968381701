<template>
  <div>
    <a href="#" class="btn btn-with-icon btn-default requests-button" title="Toon Requests" @click.prevent="toggle">
      <i class="fa fa-music" /> <span v-if="label">{{ label }}</span>
    </a>
    <ApiModal v-if="display" @close="toggle">
      <template #header>
        <h4 class="modal-title">Requests</h4>
        <RefreshButton @click="refresh" />
      </template>
      <div v-if="loading" class="panel-body">
        <i class="fa fa-spinner fa-spin" />
        Requests laden...
      </div>
      <div v-else-if="!requests.length" class="panel-body">Er zijn geen requests voor deze track</div>
      <div v-else>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Naam</th>
              <th>Telefoonnummer</th>
              <th>Reden</th>
              <th v-if="forMoney">Bedrag</th>
              <th>Datum</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="request in requests" :key="request.id">
              <td>
                <strong>
                  <a v-if="request.profile_id" :href="`/production/profiles/${request.profile_id}`" target="_blank">
                    {{ request.full_display_name }}
                  </a>
                  <span v-else>{{ request.full_display_name }}</span>
                </strong>
                <br />
                {{ request.display_city }}
              </td>
              <td>
                {{ request.display_phone_number }}<br />
                <span class="label" :class="request.phone_consent ? 'label-success' : 'label-danger'">
                  {{ request.phone_consent ? 'Toestemming' : 'Geen Toestemming' }}
                </span>
              </td>
              <td>{{ request.reason }}</td>
              <td v-if="forMoney">€ {{ request.paid_amount / 100 }}</td>
              <td>{{ formatDate(request.created_at) }}</td>
              <td>
                <a href="#" @click.prevent="addToWysiwyg(request)"
                  ><i
                    class="fa fa-small"
                    :class="{ 'fa-heart-o': !request.addedToWysiwyg, 'fa-heart': request.addedToWysiwyg }"
                /></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ApiModal>
  </div>
</template>

<script>
import api from '@/store/API'
import { format, parseISO } from 'date-fns'
import ApiModal from '@/general/components/ApiModal.vue'
import RefreshButton from '@/general/components/RefreshButton.vue'

export default {
  name: 'ScenarioRequests',
  components: {
    ApiModal,
    RefreshButton,
  },
  props: ['requestEventId', 'trackCodeId', 'label'],
  data() {
    return {
      display: false,
      loading: false,
      forMoney: false,
      requests: [],
    }
  },
  computed: {
    toggleClosedClass() {
      return this.display ? '' : 'toggle-closed'
    },
  },
  methods: {
    toggle() {
      if (this.display) {
        this.display = false
      } else {
        this.display = true
        this.refresh()
      }
    },
    formatDate(date) {
      return format(parseISO(date), 'dd-MM-yyyy HH:mm')
    },
    async refresh() {
      if (this.loading) {
        return
      }
      this.loading = true

      try {
        const response = await api.get('/production/api/requests.json', {
          params: { request_event_id: this.requestEventId, track_code_id: this.trackCodeId },
        })
        this.forMoney = response.data.for_money
        this.requests = response.data.requests
      } finally {
        this.loading = false
      }
    },
    addToWysiwyg(request) {
      // Do a jQuery event so the Backbone view can pick it up
      const html = `
        <p>
          <strong>${request.full_display_name} (${request.display_phone_number ?? ''})</strong>: 
          ${request.reason ?? ''} - ${request.paid_amount ? request.paid_amount / 100 : ''}
        </p>
      `

      request.addedToWysiwyg = true

      window.jQuery(this.$el).trigger('loved', { contentHTML: html })
    },
  },
}
</script>

<style scoped>
.requests-button {
  width: 100%;
}

.modal-title {
  display: inline;
  margin-right: 1rem;
}
</style>
