import api from './API'

export default {
  namespaced: true,
  state: {
    broadcastTypes: [],
    broadcastStates: [],
  },
  mutations: {
    setBroadcastTypes(state, broadcastTypes) {
      state.broadcastTypes = broadcastTypes
    },
    setBroadcastStates(state, broadcastStates) {
      state.broadcastStates = broadcastStates
    },
  },
  actions: {
    async fetchBroadcastTypes({ commit }) {
      const { data } = await api.get('/production/broadcasts/broadcast_item_types')
      commit('setBroadcastTypes', data)
    },
    async fetchBroadcastStates({ commit }) {
      const { data } = await api.get('/production/broadcasts/broadcast_item_states')
      commit('setBroadcastStates', data)
    },
  },
}
