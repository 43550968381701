<template>
  <div v-if="clients.length > 1" class="form-group">
    <label>Client</label>
    <select v-model="clientId" class="grouped_select form-control" @change="onSelectClient">
      <option v-for="client in clients" :key="client.clientId" :value="client.clientId" :data-type="client.type">
        {{ client.name }}
      </option>
    </select>
  </div>
  <template v-if="isPhoneBox">
    <div class="form-group">
      <input v-model="anonymous" type="checkbox" />
      <label class="checkbox-label"> Bel anoniem</label>
    </div>
    <div class="faders">
      <button
        v-for="fader in phoneBoxFaders"
        :key="fader.id"
        :disabled="loading"
        class="btn btn-lg"
        :class="{ 'in-use btn-warning': !fader.idle, 'btn-success': !fader.isCallLog }"
        @click="startCall(fader)"
      >
        <i class="fa" :class="[fader.isCallLog ? 'fa-list' : 'fa-phone']" />
        {{ fader.name }}
      </button>
    </div>
  </template>
  <template v-else-if="isLiga">
    <div class="faders">
      <button
        v-for="line in selectedClient.lines"
        :key="line.id"
        :disabled="loading"
        class="btn btn-lg btn-success"
        @click="selectLineAndStartCall(line, selectedClient.faders[0])"
      >
        <i class="fa fa-phone" />
        {{ line.name }}
      </button>
    </div>
  </template>
  <template v-else>
    <div class="faders">
      <button class="btn btn-success btn-lg" :disabled="loading" @click="startCall(selectedClient.faders[0])">
        <i class="fa fa-phone" />
        Bellen
      </button>
    </div>
  </template>
</template>

<script>
import api from '@/store/API'

export default {
  name: 'StudioCall',
  props: ['name', 'number', 'gigyaId'],
  emits: ['state', 'noClients'],
  data() {
    return {
      clients: [],
      clientId: null,
      lineId: null,
      anonymous: false,
      loading: false,
      refetchClientsTimeout: null,
      error: null,
    }
  },
  computed: {
    selectedClient() {
      return this.clients.find((s) => s.clientId === this.clientId)
    },
    phoneBoxFaders() {
      if (this.selectedClient?.type != 'phone_box') return []

      return this.selectedClient.faders
    },
    isPhoneBox() {
      return this.selectedClient?.type === 'phone_box'
    },
    isLiga() {
      return this.selectedClient?.type === 'liga'
    },
  },
  watch: {
    clientId() {
      this.onSelectClient()
    },
  },
  async mounted() {
    await this.fetchClients()
    if (this.clients.length === 0) {
      this.$emit('noClients')
    }
    this.selectFirstClient()
  },
  unmounted() {
    this.isUnmounted = true
    clearTimeout(this.refetchClientsTimeout)
  },
  methods: {
    selectFirstClient() {
      const lastUsedClient = this.clients.find((client) => client.clientId === localStorage.getItem('last_used_client'))
      this.clientId = (lastUsedClient || this.clients[0]).clientId
    },
    selectLineAndStartCall(line, fader) {
      this.lineId = line.id
      this.startCall(fader)
    },
    async startCall(fader) {
      try {
        this.$emit('state', 'idle')
        this.loading = true
        this.error = null

        await api.post('/production/calls', {
          call: {
            number: this.number,
            name: this.name,
            gigya_id: this.gigyaId,
            device_id: fader.id,
            device_type: this.selectedClient.type,
            anonymous: this.anonymous, // Only for Phonebox
            line_id: this.lineId, // Only for Liga
          },
        })

        localStorage.setItem('last_used_client', this.clientId)

        this.$emit('state', 'calling')
      } catch (e) {
        this.$emit('state', 'failed')
        this.error = e.response?.data?.error
      } finally {
        this.loading = false
      }
    },
    async fetchClients() {
      this.clients = (await api.get('/production/api/phone_clients')).data.phone_clients.map((client) => ({
        ...client,
        clientId: `${client.type}-${client.id}`,
      }))

      if (this.isUnmounted) return // Sometimes the component is unmounted before the request is finished.

      this.refetchClientsTimeout = setTimeout(() => this.fetchClients(), 2000)
    },
    onSelectClient() {
      if (this.isLiga) {
        this.lineId = this.selectedClient.lines[0].id
      } else {
        this.lineId = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.faders {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
  margin-bottom: 20px;

  button {
    flex: 1 1 45%;
    margin: 0;

    &.in-use {
      i.fa.fa-phone {
        -webkit-animation: ring 1.5s infinite normal 0s;
        animation: ring 1.5s infinite normal 0s;
      }
    }
  }
}

.checkbox-label {
  margin: 0 0 0 10px;
}
</style>
