<template>
  <ApiModal v-if="modalVisible" @close="closeModal()">
    <template #header>
      <h3 class="modal-title">Selecteer winnaars</h3>
    </template>
    <div class="modal-body">
      <SelectContestWinnersForm
        ref="form"
        :profileIds="profileIdsCallback()"
        @close="closeModal()"
        @afterSubmit="afterSubmitCallback()"
      />
    </div>
  </ApiModal>
</template>

<script>
import ApiModal from '../general/components/ApiModal.vue'
import SelectContestWinnersForm from './app/SelectContestWinnersForm.vue'

export default {
  name: 'SelectContestWinners',
  components: {
    ApiModal,
    SelectContestWinnersForm,
  },
  props: {
    profileIdsCallback: Function,
    afterSubmitCallback: Function,
  },
  data() {
    return {
      modalVisible: true,
    }
  },
  methods: {
    closeModal() {
      this.modalVisible = false
      this.$refs.form.reset()
    },
    openModal() {
      this.modalVisible = true
    },
  },
}
</script>
