<template>
  <button class="phone-call-button" @click="open = true">
    <i class="fa fa-phone" />
  </button>
  <PhoneCallModal v-if="open" @close="open = false" />
</template>

<script>
import PhoneCallModal from './PhoneCallModal.vue'

export default {
  name: 'PhoneCallButton',
  components: {
    PhoneCallModal,
  },
  data() {
    return {
      open: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.phone-call-button {
  background-color: #5ebd5e;
  border: none;
  margin: 5px;
  border-radius: 100px;
  padding: 0 15px;
  line-height: 30px;
  height: 35px;
  color: #fff;
  float: right;
  font-size: 20px;

  &:hover {
    background-color: #4aa74a;
  }
}
</style>
