<template>
  <ApiModal @close="$emit('close')">
    <template #header>
      <h4 class="modal-title" />
      <div class="member">
        <ProfileAvatar :profile="comment.from" />
        <div class="body">
          <div class="member-controls">
            <span ref="profileRaterWrapper" class="profile-rater-wrapper">
              <a
                href="#"
                class="btn btn-outline btn-sm rating-button-only-smiley"
                :data-profile-id="comment.from.profileId"
                :data-profile-rating="comment.from.profileRating"
                data-role="rating-link"
              >
                <span :data-smiley-profile-id="comment.from.profileId">
                  <i :class="profileRatingClass" />
                </span>
              </a>
            </span>
            <a id="full-profile-link" :href="profileUrl" class="btn btn-outline btn-sm">Volledig profiel</a>
          </div>
          <span data-role="profile-rating">
            {{ comment.from.name }}
          </span>
          <br />
          <a :href="contentUrl">{{ contentTitle }}</a>
        </div>
      </div>
    </template>

    <div class="widget-chat panel replies">
      <div class="panel-body">
        <ReplyModalMessage
          v-for="other_comment in visibleProfileComments"
          :key="other_comment.id"
          :comment="other_comment"
        />
      </div>
    </div>
  </ApiModal>
</template>

<script>
import ReplyModalMessage from './ReplyModalMessage.vue'
import ProfileAvatar from './ProfileAvatar.vue'
import ApiModal from '../../general/components/ApiModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ReplyModal',
  components: {
    ApiModal,
    ReplyModalMessage,
    ProfileAvatar,
  },
  props: ['comment'],
  computed: {
    ...mapGetters('ContentComments', ['visibleProfileComments']),
    profileRatingClass() {
      if (this.comment.from.profileRating) {
        return `profile-rating-${this.comment.from.profileRating}`
      } else {
        return 'profile-rating-unset'
      }
    },
    contentTitle() {
      return this.$store.getters['ContentComments/contentTitle'](this.comment.contentId)
    },
    contentUrl() {
      return this.$store.getters['ContentComments/contentUrl'](this.comment.contentId)
    },
    profileUrl() {
      return `/production/profiles/${this.comment.from.profileId}`
    },
  },
  mounted() {
    this.$store.dispatch('Broadcasts/fetch', {})
    this.$store.dispatch('ContentComments/fetchProfileComments', {
      profileId: this.comment.from.profileId,
      contentId: this.comment.contentId,
    })
    new window.Production.ProfileRater({ el: this.$refs.profileRaterWrapper }) // eslint-disable-line no-new
    window.$('.timeago').timeago()
  },
}
</script>

<style lang="scss" scoped>
.widget-chat .panel-body {
  padding-bottom: 200px;
}
</style>
