<template>
  <button class="llm-chat-button" @click="open = true">
    <img class="doria-logo" src="./assets/doria.png" />
    Doria
  </button>
  <LlmChatModal v-if="open" @close="open = false" />
</template>

<script>
import LlmChatModal from './LlmChatModal.vue'

export default {
  name: 'LlmChatButton',
  components: {
    LlmChatModal,
  },
  data() {
    return {
      open: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.llm-chat-button {
  background-color: var(--mediumblue);
  border: none;
  margin: 5px;
  border-radius: 100px;
  padding: 0 15px;
  line-height: 30px;
  height: 35px;
  color: #fff;
  float: right;

  .doria-logo {
    height: 100%;
  }

  &:hover {
    background-color: var(--mediumblue-dark);
  }
}
</style>

<style>
:root {
  --mediumblue: #028a9e;
  --mediumblue-dark: #005e6d;
}
</style>
