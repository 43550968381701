<template>
  <div class="menu-item">
    <div class="menu-item-link" @click="toggle">Hartjes: {{ count }} <i class="ml-3 fa fa-heart-o" /></div>
    <Teleport to="body">
      <ApiModal v-if="showPopup" @close="toggle">
        <template #header>
          <h4 class="modal-title">{{ trackTitle }}: {{ count }} <i class="fa fa-heart" /></h4>
        </template>
        <div class="modal-body">
          <div v-if="loading" class="panel-body">
            <i class="fa fa-spinner fa-spin" />
            Hartjes laden...
          </div>
          <div v-else-if="!ratings.length" class="panel-body">Er zijn geen hartjes voor deze track</div>
          <table v-else class="table table-striped ratings-modal">
            <thead>
              <tr>
                <th />
                <th>Naam</th>
                <th>Woonplaats</th>
                <th>Telefoonnummer</th>
                <th>Datum</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="rating in ratings" :key="rating.id">
                <td>
                  <div class="tl-icon" :class="ratingClass(rating.score)">
                    <i class="fa" :class="ratingIcon(rating.score)" />
                  </div>
                </td>
                <td>
                  <strong>
                    <a :href="`/production/profiles/${rating.profile_id}`" target="_blank">
                      {{ rating.profile_name }}
                    </a>
                  </strong>
                </td>
                <td>
                  {{ rating.profile_city }}
                </td>
                <td>{{ rating.profile_mobile }}<br /></td>
                <td>{{ formatDate(rating.rated_at) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ApiModal>
    </Teleport>
  </div>
</template>

<script>
import api from '@/store/API'
import ApiModal from '../general/components/ApiModal.vue'
import { format, parseISO } from 'date-fns'

export default {
  name: 'ScenarioRatings',
  components: {
    ApiModal,
  },
  props: ['broadcastItemId', 'trackTitle'],
  data() {
    return {
      ratings: [],
      loading: false,
      showPopup: false,
    }
  },
  computed: {
    ratingsPageUrl() {
      return `/production/broadcast_items/${this.broadcastItemId}/ratings`
    },
    count() {
      return this.ratings.length
    },
  },
  mounted() {
    this.getRatings()
  },
  methods: {
    toggle() {
      this.showPopup = this.showPopup ? false : true
    },
    formatDate(date) {
      return format(parseISO(date), 'dd-MM-yyyy HH:mm')
    },
    async getRatings() {
      if (this.loading) {
        return
      }
      this.loading = true

      try {
        this.ratings = (await api.get(`/production/broadcast_items/${this.broadcastItemId}/ratings`)).data.ratings
      } finally {
        this.loading = false
      }
    },
    ratingClass(score) {
      return score > 0 ? 'bg-success' : 'bg-danger'
    },
    ratingIcon(score) {
      return score > 0 ? 'fa-heart' : 'fa-heart-o'
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-item-link {
  width: 100%;
}
</style>
