<template>
  <div class="form-group">
    <br />
    <div class="thumbnail-descripted-images video" :data-attachment-url="video.media_url">
      <a href="#">
        <div
          class="cropcontainer thumbnail"
          :style="{ backgroundImage: 'url(' + video.thumb_url + ')' }"
          :data-video-modal="video.media_url"
        >
          <i class="fa fa-play-circle-o fa-4x video" />
        </div>
      </a>
      <a :href="video.media_url" class="download btn btn-with-icon btn-default" download="" icon="download">
        <i class="fa fa-download" /> Download
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewVideo',
  props: ['video'],
}
</script>
