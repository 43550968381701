<template>
  <div class="row">
    <TrafficBase
      class="col-md-8 col-md-offset-2"
      @items="items = $event"
      @majorRoadsOnlyChange="majorRoadsOnly = $event"
    >
      <div>
        <div>
          <div class="panel">
            <div class="panel-heading">
              <strong>Files</strong>
              <DelaySelector
                v-model="delayThreshold"
                :traffic="byCategory('traffic')"
                :delayThresholds="[5, 10, 15, 20, 30]"
              />
            </div>
            <div class="count-announcer">
              <span>
                <strong>{{ totalTrafficCount }} files</strong> in het land
              </span>
              <span>
                <strong>{{ thresholdTrafficCount }} files</strong> van <strong>{{ delayThreshold }} minuten</strong> of
                langer
              </span>
            </div>
          </div>

          <div class="panel announcement">
            Er
            <span v-if="thresholdTrafficCount === 0">staan <strong>geen files</strong></span>
            <span v-else-if="thresholdTrafficCount === 1">staat <strong>1 file</strong></span>
            <span v-else>
              staan <strong>{{ thresholdTrafficCount }} files</strong>
            </span>
            op onze snelwegen waar je meer dan
            <strong>{{ delayThreshold }} minuten</strong> verliest.
            <span v-if="thresholdTrafficCount > 1">We beginnen met de langste file.</span>
          </div>

          <div v-if="!traffic.length" class="alert alert-info">
            Er zijn op dit moment geen files met een vertraging langer dan {{ delayThreshold }} minuten.
          </div>

          <GroupedTrafficList :list="traffic" :groupBy="['groupNameLevelA', 'groupNameLevelB']" country="be" />

          <div class="panel announcement">
            Als je nog op een andere plaats in de file staat dan verlies je daar op dit moment minder dan
            <strong>{{ delayThreshold }} minuten</strong>.
          </div>

          <div v-if="incidents.length">
            <div class="panel">
              <div class="panel-heading">
                <strong>Incidenten</strong>
              </div>
            </div>
            <TrafficList :list="incidents" country="be" />
          </div>
        </div>
      </div>
    </TrafficBase>
  </div>
</template>

<script>
import { sortByDesc } from '@/general/utils/collections'
import GroupedTrafficList from './GroupedTrafficList.vue'
import TrafficList from './TrafficList.vue'
import TrafficBase from './TrafficBase.vue'
import DelaySelector from '@/traffic/DelaySelector.vue'

export default {
  name: 'TrafficBE',
  components: { DelaySelector, GroupedTrafficList, TrafficList, TrafficBase },
  data() {
    return {
      items: [],

      majorRoadsOnly: true,
      delayThreshold: 5,
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) => !this.majorRoadsOnly || item.roadLetter !== 'N')
    },
    traffic() {
      const items = this.trafficAboveThreshold(this.delayThreshold).map((item) => ({
        ...item,
        groupNameLevelA: item.towards || item.road,
        groupNameLevelB: item.towards ? item.road : item.direction,
        // towards: item.towards || item.road,
        // road: item.towards ? item.road : item.direction,
        direction: null, // For display purposes
      }))
      return sortByDesc(items, 'delay')
    },
    totalTrafficCount() {
      return this.trafficAboveThreshold().length
    },
    thresholdTrafficCount() {
      return this.trafficAboveThreshold(this.delayThreshold).length
    },
    incidents() {
      return this.byCategory('incidents')
    },
  },
  methods: {
    byCategory(category) {
      return this.filteredItems.filter((item) => item.typeCategory === category)
    },
    trafficAboveThreshold(threshold = 0) {
      return this.byCategory('traffic').filter((item) => item.delay >= threshold)
    },
  },
}
</script>

<style scoped>
.panel {
  padding: 0;
  margin-bottom: 5px;
}

.panel-heading {
  display: flex;
  justify-content: space-between;
}
.count-announcer {
  padding: 10px;
  font-size: 0.9em;
  text-align: center;
  text-transform: uppercase;
  background-color: #f5f5f5;
}

.count-announcer > * + * {
  margin-left: 15px;
}

.announcement {
  margin-top: 2em;
  font-size: 1.6em;
  padding: 16px 20px;
  background-color: #f5f5f5;
}
</style>
