<template>
  <div v-cloak class="panel" @drop.prevent="addFile" @dragover.prevent>
    <input ref="file" type="file" style="display: none" multiple @change="addFile" />
    <div class="panel-heading">Importeer tracks</div>
    <div class="panel-body">
      Wat wil je importeren? <br />
      <label class="btn">
        <input v-model="type" name="type" type="radio" value="tracks" />
        Tracks
      </label>
      <label class="btn">
        <input v-model="type" name="type" type="radio" value="jingles" />
        Jingles
      </label>
      <p style="margin: 10px">
        Sleep hieronder je tracks heen of <span class="empty-track" @click="addEmptyFile">upload een lege track</span>
      </p>
      <div v-if="noTracks" class="dropzone">
        <p style="color: grey; text-align: center; cursor: pointer; line-height: 70px" @click="$refs.file.click()">
          Sleep hier je tracks heen of klik hier
        </p>
      </div>
      <table v-else class="table table-hover">
        <thead>
          <tr>
            <th>Naam</th>
            <th>Grootte</th>
            <th>Titel</th>
            <th>Artist</th>
            <th />
          </tr>
        </thead>
        <tbody :class="{ error: validationMode }">
          <TrackRow v-for="track in tracks" :key="track.id" :track="track" v-bind="track" />
        </tbody>
      </table>
      <a href="#" class="btn btn-success" @click.prevent="save">Opslaan</a>
    </div>
  </div>
</template>

<script>
import TrackRow from './TrackRow.vue'
import { mapState } from 'vuex'

export default {
  name: 'TrackImport',
  components: {
    TrackRow,
  },
  computed: {
    noTracks() {
      return this.tracks.length === 0
    },
    type: {
      get() {
        return this.$store.state.TrackImport.type
      },
      set(type) {
        this.$store.dispatch('TrackImport/changeType', type)
      },
    },
    ...mapState('TrackImport', ['tracks', 'validationMode', 'uploading']),
  },
  methods: {
    async save() {
      const saved = await this.$store.dispatch('TrackImport/saveTracks')
      if (saved) {
        document.location = '/enrichment/track_imports'
      }
    },
    async addFile(e) {
      let droppedFiles
      if (e.target && e.target.files) {
        droppedFiles = e.target.files
      } else {
        droppedFiles = e.dataTransfer.files
      }

      if (!droppedFiles) return
      this.$store.dispatch('TrackImport/addFiles', { files: droppedFiles })
    },
    async addEmptyFile() {
      this.$store.dispatch('TrackImport/addEmptyFile')
    },
  },
}
</script>

<style>
tbody.error .error {
  border: red 1px solid;
}

.wrapper {
  width: 150px;
}

.-progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  padding: 3px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.-progress-bar-fill {
  display: block;
  height: 10px;
  background-color: #659cef;
  border-radius: 3px;

  transition: width 500ms ease-in-out;
}

.empty-track {
  color: blue;
  text-decoration: blue;
  cursor: pointer;
}

.dropzone {
  border: 1px dashed rgb(197, 197, 197);
  margin-bottom: 10px;
  height: 80px;
}
</style>
