<template>
  <div>
    <ApiModal @close="$emit('close')">
      <template #header>
        <h4 class="modal-title">{{ count }} stemmen</h4>
      </template>
      <div class="modal-body">
        <div v-if="loading">
          <i class="fa fa-spinner fa-spin" />
          Stemmen laden...
        </div>
        <div v-else-if="!votes.length" class="modal-body">Er zijn geen stemmen voor deze track</div>
        <table v-else class="table table-striped modal-body">
          <thead>
            <tr>
              <th>#</th>
              <th>Stemmer</th>
              <th>Telefoonnummer</th>
              <th>Reden</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="vote in votes" :key="vote.id">
              <td>#{{ vote.position }}</td>
              <td class="profile-item">
                <strong>
                  <a v-if="vote.profile.id" href="#" @click.prevent="openVotesDetailModal(vote.edition_vote_id)">
                    {{ vote.profile.name }}
                  </a>
                  <span v-else>{{ vote.profile.name }}</span>
                </strong>
                <span v-if="vote.profile.city" class="city-label">
                  uit <strong>{{ vote.profile.city }}</strong>
                </span>
              </td>
              <td>
                <PhoneToLink :number="vote.profile.mobile" :name="vote.profile.name" />
                <DarioConsentBadge class="label" :consent="vote.consent" />
              </td>
              <td>{{ vote.comment }}</td>
              <td>
                <DarioButton :icon="vote.voteSelected ? 'heart' : 'heart-o'" @click="$emit('voteSelected', vote)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ApiModal>
    <VotesDetailModal v-if="showDetailVoterModal" :editionVoteId="votesDetailId" @close="closeVotesDetailModal" />
  </div>
</template>

<script>
import api from '@/store/API'
import ApiModal from '@/general/components/ApiModal.vue'
import PhoneToLink from '../general/components/PhoneToLink.vue'
import DarioButton from '../general/components/DarioButton.vue'
import DarioConsentBadge from '../general/components/DarioConsentBadge.vue'
import VotesDetailModal from './VotesDetailModal.vue'

export default {
  name: 'ScenarioVotes',
  components: {
    ApiModal,
    PhoneToLink,
    DarioButton,
    DarioConsentBadge,
    VotesDetailModal,
  },
  props: ['editionId', 'track'],
  data() {
    return {
      loading: false,
      votesDetailId: null,
      showDetailVoterModal: false,
      votes: [],
    }
  },
  computed: {
    count() {
      return this.votes.length
    },
  },
  mounted() {
    this.fetchVotes()
  },
  methods: {
    async fetchVotes() {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        this.votes = (
          await api.get(`/enrichment/editions/${this.editionId}/track_code/${this.track.track_id}/votes.json`)
        ).data.votes
      } finally {
        this.loading = false
      }
    },
    openVotesDetailModal(editionVoteId) {
      this.showDetailVoterModal = true
      this.votesDetailId = editionVoteId
    },
    closeVotesDetailModal() {
      this.showDetailVoterModal = false
    },
  },
}
</script>

<style scoped>
.menu-item-link {
  width: 100%;
}

.label {
  margin-left: 5px;
}

.profile-item {
  display: flex;
  flex-direction: column;
}

.city-label {
  font-style: italic;
  color: #666;
}
</style>
