import { airbrake } from './general/airbrake'

const deprecatedOrigins = [
  'https://radio-assets.fra1.digitaloceanspaces.com',
  'http://radio-assets.fra1.digitaloceanspaces.com',
]

const hasDeprecatedOrigin = (url) => {
  return deprecatedOrigins.some((origin) => url.startsWith(origin))
}

let reported = []
const report = (imageUrl, currentUrl = window.location.href) => {
  if (reported.includes(imageUrl)) {
    return
  }

  airbrake.notify({
    error: new Error(`An image was loaded from a deprecated origin.`),
    context: {
      _deprecatedOrigin: { imageUrl, currentUrl },
    },
  })

  reported.push(imageUrl)
}

export default function detectDeprecatedImageOrigins() {
  // Detect statically present images
  document.querySelectorAll('img').forEach((img) => {
    if (hasDeprecatedOrigin(img.src)) {
      report(img.src)
    }
  })

  // Detect dynamically added images
  document.addEventListener(
    'load',
    (event) => {
      if (event.target.tagName.toLowerCase() === 'img' && hasDeprecatedOrigin(event.target.src)) {
        report(event.target.src)
      }
    },
    true
  )
}
