import api from './API'

export default {
  namespaced: true,
  state: {
    momentsDate: null,
    profiles: [],
    contestMoments: [],
    selectedContestMoment: null,
  },
  mutations: {
    setProfiles(state, profiles) {
      profiles.forEach((profile) => {
        profile.comment = null
      })
      state.profiles = profiles
    },
    setComment(state, { profileId, comment }) {
      const profile = state.profiles.find((profile) => profile.id === profileId)
      profile.comment = comment
    },
    setContestMoments(state, contestMoments) {
      state.contestMoments = contestMoments
    },
    setSelectedContestMoment(state, selectedContestMoment) {
      state.selectedContestMoment = selectedContestMoment
    },
    setMomentsDate(state, momentsDate) {
      state.momentsDate = momentsDate
    },
  },
  actions: {
    setMomentsDate({ commit, dispatch }, momentsDate) {
      commit('setMomentsDate', momentsDate)
      dispatch('fetchContestMoments')
    },
    setContestMoment({ commit }, selectedContestMoment) {
      commit('setSelectedContestMoment', selectedContestMoment)
    },
    setComment({ commit }, { profileId, comment }) {
      commit('setComment', { profileId, comment })
    },
    async fetchProfiles({ commit }, [profileIds]) {
      const response = await api.get('/production/api/profiles', { params: { profileIds } })
      commit('setProfiles', response.data.profiles)
    },
    async fetchContestMoments({ commit, state }) {
      const response = await api.get('/production/contests/moments/active', { params: { date: state.momentsDate } })
      const currentHour = new Date().getHours()

      commit('setContestMoments', response.data.moments)
      commit(
        'setSelectedContestMoment',
        state.contestMoments.find((moment) => moment.hour === currentHour) || response.data.moments[0]
      )
    },
    async submitForm({ state }, prizes) {
      return api.post('/production/contests/prizes/assign', {
        winners: state.profiles,
        prizes: prizes,
        contest_moment_id: state.selectedContestMoment.id,
      })
    },
  },
}
