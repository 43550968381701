<template>
  <div>
    <img v-if="profile.imageUrl" class="member-avatar" :src="profile.imageUrl" />
    <span v-else class="member-avatar no-image">{{ initials }}</span>
  </div>
</template>

<script>
export default {
  name: 'ProfileAvatar',
  props: ['profile'],
  computed: {
    initials() {
      return this.profile.name
        .split(' ')
        .map((word) => {
          return word[0]
        })
        .join('')
        .toUpperCase()
    },
  },
}
</script>
