<template>
  <NDatePicker v-model:value="date" type="date" :placeholder="placeholder" />
</template>

<script>
import { NDatePicker } from 'naive-ui'

export default {
  name: 'ApiDatepicker',
  components: { NDatePicker },
  props: ['modelValue', 'placeholder'],
  computed: {
    date: {
      get() {
        return this.modelValue?.getTime()
      },
      set(value) {
        this.$emit('update:modelValue', new Date(value))
      },
    },
  },
}
</script>
