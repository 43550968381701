<template>
  <div>
    <n-auto-complete
      v-model:value="query"
      :inputProps="{ autocomplete: 'disabled' }"
      :options="options"
      :loading="loading"
      :placeholder="placeholder"
      :renderLabel="renderLabel"
      :onUpdate:value="updateValue"
      :onSelect="select"
      :themeOverrides="naiveUiThemeOverrides"
    />
    <input type="hidden" :name="hiddenFieldName" :value="selectedTrack?.[valueAttribute]" />
  </div>
</template>

<script>
import { debounce } from '@/general/utils/debounce'
import axios from 'axios'
import { NAutoComplete } from 'naive-ui'

export default {
  name: 'TrackAutocomplete',
  components: { NAutoComplete },
  props: {
    hiddenFieldName: String,
    initialValue: Object,
    valueAttribute: {
      default: 'id',
    },
    displayAttribute: {
      default: 'display_name',
    },
    type: {
      default: null,
    },
    modelValue: {
      default: '',
    },
    renderLabel: {
      default: (o) => o.label,
    },
    placeholder: {
      default: 'Zoek track',
    },
  },
  emits: ['selected', 'update:modelValue'],
  data() {
    return {
      query: this.modelValue,
      abortController: new AbortController(),
      selectedTrack: null,
      options: [],
      loading: false,
    }
  },
  computed: {
    naiveUiThemeOverrides() {
      return {
        peers: {
          Input: {
            fontSizeMedium: '13px',
            heightMedium: '32px',
          },
        },
      }
    },
  },
  watch: {
    modelValue(newValue) {
      this.query = newValue
    },
    query() {
      this.debouncedSearchTracks(this.query)
    },
  },
  mounted() {
    this.$nextTick(function () {
      if (this.initialValue) {
        this.selectedTrack = this.initialValue
        this.query = this.initialValue[this.displayAttribute]
      }
    })
  },
  methods: {
    select(track) {
      this.selectedTrack = track
      this.$emit('selected', track)
    },
    updateValue(newValue) {
      this.$emit('update:modelValue', newValue)
      this.query = newValue
    },
    debouncedSearchTracks: debounce(function (query) {
      this.searchTracks(query)
    }, 300),
    async searchTracks(query) {
      this.abortController.abort() // Cancel any previous request
      this.abortController = new AbortController()
      try {
        this.loading = true
        const response = await axios.get('/enrichment/searches.json', {
          params: { term: query, type: this.type },
          signal: this.abortController.signal,
        })
        this.options = response.data.results.map((track) => ({
          ...track,
          label: track[this.displayAttribute],
          value: track,
        }))
      } catch (e) {
        if (axios.isCancel(e)) {
          // That's fine
        } else {
          throw e
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
