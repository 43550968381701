<template>
  <span class="label" :class="consent ? 'label-success' : 'label-danger'">
    {{ consent ? 'Toestemming' : 'Geen Toestemming' }}
  </span>
</template>

<script>
export default {
  props: ['consent'],
}
</script>
