<template>
  <li class="trigger" @click="open = true">
    <i class="fa fa-search" />
  </li>
  <ApiModal v-if="open" class="broadcast-item-search-modal" hideHeader="true" @close="open = false">
    <broadcastItemSearch :inModal="true" />
  </ApiModal>
</template>

<script>
import ApiModal from '../general/components/ApiModal.vue'
import BroadcastItemSearch from './BroadcastItemSearch.vue'

export default {
  name: 'BroadcastSearch',
  components: {
    ApiModal,
    BroadcastItemSearch,
  },
  data() {
    return {
      open: false,
    }
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown)
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  methods: {
    onKeyDown(e) {
      if (e.ctrlKey && e.shiftKey && e.key === 'f') {
        this.open = true
      }
    },
  },
}
</script>

<style lang="scss">
.broadcast-item-search-modal {
  .modal-dialog {
    width: 80%;
    max-width: 1000px;
    margin-top: 150px;
  }
  .modal-content {
    padding: 10px;
    border-radius: 5px;
    overflow: inherit;
  }

  .modal-wrapper {
    vertical-align: top;
  }
}

.trigger {
  cursor: pointer;
  text-align: center;
  line-height: 46px;
  min-width: 50px;
  font-size: 18px;
  padding: 0 10px;
  background-color: #f5f5f5;
}
</style>
