<template>
  <div id="graph-container" ref="chart" />
</template>

<script>
import Chart from 'highcharts/es-modules/Core/Chart/Chart.js'
import 'highcharts/es-modules/Series/Line/LineSeries.js'

export default {
  name: 'LineGraph',
  props: ['datapoints', 'labelFormat'],
  data() {
    return {
      chart: null,
    }
  },
  mounted() {
    this.chart = new Chart(this.$refs.chart, {
      title: {
        text: '',
      },
      series: [
        {
          name: 'berichten',
          type: 'line',
          color: '#857198',
          data: this.datapoints,
        },
      ],
      yAxis: {
        min: 0,
        minRange: 0.1,
        startOnTick: true,
        title: {
          text: '',
        },
      },
      xAxis: {
        min: 0,
        minRange: 0.1,
        tickInterval: 1,
        startOnTick: true,
        labels: {
          format: this.labelFormat ? this.labelFormat : '{value}',
        },
        title: {
          text: '',
        },
      },
      tooltip: {
        formatHeader: 'point.key',
      },
      legend: { enabled: false },
      labels: { enabled: false },
    })
  },
}
</script>
