import { ref } from 'vue'

export function useNow({ interval = 1000 } = {}) {
  const now = ref(new Date())

  setInterval(() => {
    now.value = new Date()
  }, interval)

  return now
}
