<template>
  <div class="row">
    <div class="col-md-9 track">
      <div class="cover">
        <img v-if="imageUrl" :src="imageUrl" />
      </div>

      <TrackAutocomplete
        class="track-selector"
        :modelValue="body"
        type="TrackCode"
        @update:modelValue="$emit('update:body', $event)"
        @selected="selectTrack($event)"
      />
    </div>

    <div class="col-md-2">
      <label>
        <input
          type="checkbox"
          name="fixed_winner"
          :checked="fixedWinner"
          @change="$emit('update:fixedWinner', $event.target.value)"
        />
        Vaste winnaar
      </label>
    </div>

    <div class="col-md-1">
      <button type="button" class="btn btn-danger" @click.prevent="$emit('remove')">Verwijder</button>
    </div>
  </div>
</template>

<script>
import { cdnUrl } from '@/general/utils/cdn'
import TrackAutocomplete from '../../dario-autocomplete/TrackAutocomplete.vue'

export default {
  name: 'TrackAnswer',
  components: { TrackAutocomplete },
  props: ['body', 'image', 'fixedWinner'],
  emits: ['update:body', 'update:image', 'update:fixedWinner', 'remove'],
  computed: {
    imageUrl() {
      return cdnUrl(this.image, 'cover/w100')
    },
  },
  methods: {
    selectTrack(track) {
      this.$emit('update:body', track.display_name)
      this.$emit('update:image', track.thumbnail)
    },
  },
}
</script>

<style lang="scss" scoped>
.track {
  display: flex;

  .track-selector {
    flex-grow: 1;
  }

  .cover {
    width: 32px;
    height: 32px;
    margin-right: 5px;

    border: 1px solid #ccc;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
