<template>
  <input ref="file" type="file" style="display: none" @change="setFile" />
  <div :class="{ squared }">
    <div v-if="!uploadedImage && !modelValue" class="dropzone" @click="$refs.file.click()">
      <i v-if="squared" class="fa fa-lg fa-upload" />
      <p v-else>Sleep hier je afbeelding heen of klik hier</p>
    </div>
    <div v-else class="image-preview">
      <img :src="imageSrc" @click="$refs.file.click()" />
      <button v-if="!squared" type="button" @click.prevent="removeImage">
        <i class="fa fa-lg fa-close" />
      </button>
    </div>
  </div>
</template>

<script>
import { cdnUrl } from '@/general/utils/cdn'

export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    squared: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadedImage: null,
    }
  },
  computed: {
    imageSrc() {
      return this.uploadedImage || cdnUrl(this.modelValue)
    },
  },
  methods: {
    setFile(e) {
      const file = e.target.files[0]
      this.uploadedImage = URL.createObjectURL(file)

      this.$emit('update:modelValue', file)
    },
    removeImage() {
      this.uploadedImage = null
      this.$emit('update:modelValue', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.dropzone {
  border: 1px dashed rgb(197, 197, 197);
  margin-bottom: 10px;
  height: 32px;

  p {
    color: grey;
    text-align: center;
    cursor: pointer;
    line-height: 32px;
  }
}

.image-preview {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;

  img {
    width: auto;
    max-height: 32px;
  }

  button {
    height: 30px;
    border: none;
  }
}

.squared {
  background-color: transparent;
  aspect-ratio: 1/1;
  height: 100%;
  padding: 0;
  cursor: pointer;

  .dropzone {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      color: grey;
    }
  }

  .image-preview {
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    background-color: transparent;
  }

  img {
    aspect-ratio: 1/1;
  }

  button {
    background-color: transparent;
  }
}
</style>
