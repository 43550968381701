import api from './API'

const TYPES = {
  TRACKS: 'tracks',
  JINGLES: 'jingles',
}

export default {
  namespaced: true,
  state: {
    uploading: false,
    tracks: [],
    type: TYPES.TRACKS,
    validationMode: false,
  },
  mutations: {
    setType(state, type) {
      state.type = type
    },
    addTrack(state, { file, withoutAudio, artist = null, title = null }) {
      const track = {
        file,
        title,
        artist,
        withoutAudio,
        uploadPercentage: 0,
        filename: null,
        uploading: false,
      }
      state.tracks.push(track)
    },
    toggleArtistBlank(state) {
      state.tracks.forEach((track) => {
        if (state.type === TYPES.JINGLES) {
          if (track.artist) {
            track.artistBackup = track.artist
          }
          track.artist = null
        } else {
          track.artist = track.artistBackup
        }
      })
    },
    enableValidationMode(state) {
      state.validationMode = true
    },
    removeTrack(state, track) {
      state.tracks = state.tracks.filter((t) => {
        return t !== track
      })
    },
    setUploadPercentage(state, [track, uploadPercentage]) {
      track.uploadPercentage = uploadPercentage
    },
    uploadingTrack(state, { track, uploading, filename }) {
      if (filename) {
        track.filename = filename
      }
      track.uploading = uploading
    },
    uploadMode(state, uploading) {
      state.uploading = uploading
    },
    setArtist(state, { track, name }) {
      if (name) {
        track.artist = name.toUpperCase()
      }
    },
    setTitle(state, { track, title }) {
      track.title = title
    },
  },
  actions: {
    async addFiles({ commit, state, dispatch }, { files }) {
      ;[...files].forEach((f) => {
        // Try and extract artist & title from filename
        let artist, title
        if (state.type === TYPES.TRACKS) {
          ;[, artist, title] = f.name.match(/^(?:(?<artist>.*) - )?(?<title>.*)\.(?:[^.]+)$/) || []
          artist = artist?.toUpperCase()
        } else {
          ;[, title] = f.name.match(/^(?<title>.*)\.(?:[^.]+)$/) || []
        }

        commit('addTrack', { file: f, artist, title, withoutAudio: false })
      })
      if (!state.uploading) {
        commit('uploadMode', true)
        await dispatch('upload')
        commit('uploadMode', false)
      }
    },
    async addEmptyFile({ commit }) {
      commit('addTrack', { file: null, withoutAudio: true })
    },
    changeType({ commit }, type) {
      commit('setType', type)
      commit('toggleArtistBlank')
    },
    async uploadTrack({ commit }, track) {
      commit('uploadingTrack', { track: track, uploading: true })
      let formData = new FormData()
      formData.append('file', track.file)
      const result = await api
        .post('/enrichment/track_imports/tempfiles', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
            commit('setUploadPercentage', [track, uploadPercentage])
          },
        })
        .catch((ex) => alert(ex.message))
      commit('uploadingTrack', { track: track, uploading: false, filename: result.data.filename })
    },
    async upload({ state, dispatch }) {
      const unUploadedTracks = state.tracks.filter(
        (track) => track.uploadPercentage === 0 && track.withoutAudio === false
      )
      if (unUploadedTracks.length > 0) await dispatch('uploadTrack', unUploadedTracks[0])
      if (unUploadedTracks.length > 1) await dispatch('upload')
    },
    async saveTracks({ commit, state }) {
      commit('enableValidationMode')
      const invalid = state.tracks.filter((track) => {
        return (
          (!track.withoutAudio && !track.filename) || // If it's with audio, check we have a file
          (state.type === TYPES.TRACKS && !track.artist) || // We need an artistname
          !track.title
        )
      })

      if (!invalid.length && state.tracks.length > 0) {
        await api.post('/enrichment/track_imports/', { tracks: state.tracks })
        return true
      } else {
        return false
      }
    },
  },
}
