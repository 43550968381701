<template>
  <tr>
    <td :class="{ error: !filename }">
      <i v-if="filename" class="fa fa-check fa-success" /> {{ userFilename }}
      <div v-if="uploading" class="wrapper">
        <div class="-progress-bar">
          <span class="-progress-bar-fill" :style="{ width: uploadPercentage + '%' }" />
        </div>
      </div>
    </td>
    <td>{{ filesize }} kb</td>
    <td>
      <TrackAutocomplete
        v-model="trackTitle"
        displayAttribute="track_title"
        type="Track"
        :renderLabel="(t) => `${t.selector_code} | ${t.track_title} - ${t.artist_name}`"
        @selected="setArtist"
      />
    </td>
    <td>
      <TrackAutocomplete v-model="artistName" displayAttribute="artist_name" type="Artist" placeholder="Zoek artiest" />
    </td>
    <td>
      <button title="Remove" tabindex="-1" @click="removeTrack">X</button>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'
import TrackAutocomplete from '../dario-autocomplete/TrackAutocomplete.vue'

export default {
  name: 'TrackRow',
  components: {
    TrackAutocomplete,
  },
  props: ['track', 'title', 'artist', 'file', 'filename', 'uploading', 'uploadPercentage', 'withoutAudio'],
  computed: {
    ...mapState('TrackImport', ['type']),
    filesize() {
      if (this.file !== null) {
        return Math.floor(this.file.size / 1024)
      } else {
        return '-'
      }
    },
    userFilename() {
      return this.file ? this.file.name : 'Lege track'
    },
    trackTitle: {
      get() {
        return this.title
      },

      set(trackName) {
        this.$store.commit('TrackImport/setTitle', { track: this.track, title: trackName })
      },
    },
    artistName: {
      get() {
        return this.artist
      },

      set(artistName) {
        this.$store.commit('TrackImport/setArtist', { track: this.track, name: artistName })
      },
    },
  },
  methods: {
    setArtist(suggestion) {
      this.artistName = suggestion.artist_name
    },
    log() {
      this.source.push({ id: 'new', name: this.$refs.auto.$el.querySelector('input').value })
    },
    removeTrack() {
      this.$store.commit('TrackImport/removeTrack', this.track)
    },
  },
}
</script>
