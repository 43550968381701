<template>
  <EmojiPicker
    :native="true"
    v-bind="$attrs"
    :additionalGroups="{ most_used_emoji: mostUsedEmojiList }"
    :groupNames="groupNames"
    @select="select"
  />
</template>

<script>
import EmojiPicker from 'vue3-emoji-picker'

import 'vue3-emoji-picker/css'

const MOST_USED_KEY = 'emoji-picker-most-used'

export default {
  components: { EmojiPicker },
  emits: ['select'],
  data() {
    return {
      mostUsedEmoji: {},
      groupNames: {
        most_used_emoji: 'Favorieten',
        // Disable all other groups
        smileys_people: null,
        animals_nature: null,
        food_drink: null,
        activities: null,
        travel_places: null,
        objects: null,
        symbols: null,
        flags: null,
      },
    }
  },
  computed: {
    mostUsedEmojiList() {
      return Object.entries(this.mostUsedEmoji)
        .sort(([, a], [, b]) => b.c - a.c)
        .map(([k, o]) => ({ u: k, n: o.n }))
        .slice(0, 8)
    },
  },
  created() {
    this.mostUsedEmoji = JSON.parse(localStorage.getItem(MOST_USED_KEY) || '{}')
  },
  methods: {
    increaseEmojiUse({ u, n }) {
      this.mostUsedEmoji[u] ??= { n, c: 0 }
      this.mostUsedEmoji[u].c += 1

      localStorage.setItem(MOST_USED_KEY, JSON.stringify(this.mostUsedEmoji))
    },
    select(emoji) {
      this.increaseEmojiUse(emoji)

      this.$emit('select', emoji)
    },
  },
}
</script>

<style lang="scss">
.v3-emoji-picker {
  .v3-body .v3-body-inner.is-mac .v3-emojis button {
    // Prevents the emoji picker from using the native emoji font.
    font-family: inherit;
  }
  .v3-footer {
    display: none;
  }
}
</style>
