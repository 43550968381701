<template>
  <button
    class="menu-item"
    :class="{ 'is-active': isActive, [className]: true }"
    :title="title"
    @click.prevent="$emit('click')"
  >
    <i :class="iconName" />
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: null,
    },

    className: {
      type: String,
      default: null,
    },
  },
  emits: ['click'],
  computed: {
    iconName() {
      return `fa fa-${this.icon}`
    },
  },
}
</script>

<style lang="scss">
.menu-item {
  height: 3.5rem;
  color: #0d0d0d;
  border: none;
  padding: 0.5rem 1rem;
  background-color: transparent;
  margin-right: 0.25rem;

  i {
    fill: currentColor;
    vertical-align: text-top;
    line-height: 17px;
    font-size: 15px;
  }

  &.green {
    color: #5ebd5e;
  }

  &.red {
    color: #e66454;
  }

  &.orange {
    color: #f4b04f;
  }

  &.is-active,
  &:hover {
    color: #000;
    background-color: #fff;
  }
}
</style>
