// If an input field with `js-token-field` is found, this will enable
// the jQuery plugin, and will make sure Cocoon will also call it
// after adding a new field
//
const onLoad = () => {
  $('.js-has-token-field').on('cocoon:after-insert', () => {
    $('.js-token-field').tokenField({ regex: /\S+/, delimiters: ',' })
  })

  $('.js-token-field').tokenField({ regex: /\S+/, delimiters: ',' })
}

document.addEventListener('DOMContentLoaded', onLoad)
