const $ = window.jQuery
$(document).on('change', '[data-toggle-content-url]', function () {
  const linkType = $(this).val()
  $('[data-fields=content_url]').toggle(linkType === 'content')
  $('[data-fields=tab]').toggle(linkType === 'tab')
  $('[data-fields=station]').toggle(linkType === 'station')
})

$(() => {
  $('[data-toggle-content-url]:checked').trigger('change')
})
