<script setup>
import ApiModal from '../general/components/ApiModal.vue'
import ApiPagination from '../general/components/ApiPagination.vue'
import PhoneToLink from '../general/components/PhoneToLink.vue'
import { format, parseISO } from 'date-fns'

import { onMounted, ref } from 'vue'
import api from '../store/API'

const emits = defineEmits(['close'])
const props = defineProps({
  answerId: Number,
})

const votes = ref(null)
const pagination = ref(null)
const answer = ref(null)
const loading = ref(true)

onMounted(async () => {
  await fetchVotes(1)
})

const fetchVotes = async (page) => {
  loading.value = true
  const {
    answer: responseAnswer,
    votes: responseVotes,
    pagination: responsePagination,
  } = (await api.get(`/production/poll_answers/${props.answerId}/votes`, { page })).data

  answer.value = responseAnswer
  votes.value = responseVotes
  pagination.value = responsePagination
  loading.value = false
}

const formatDate = (date) => {
  return format(parseISO(date), 'dd-MM-yyyy HH:mm')
}
</script>

<template>
  <apiModal size="wide" @close="emits('close')">
    <template #header>{{ $t('production.poll_votes.title_html', { answer: answer?.body }) }}</template>
    <div v-if="votes?.length" class="modal-body">
      <table class="table table-striped ratings-modal">
        <thead>
          <tr>
            <th>
              {{ $t('production.poll_votes.name') }}
            </th>
            <th>
              {{ $t('production.poll_votes.city') }}
            </th>
            <th>
              {{ $t('production.poll_votes.mobile') }}
            </th>
            <th>
              {{ $t('production.poll_votes.created_at') }}
            </th>
            <th>
              {{ $t('production.poll_votes.updated_at') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="vote in votes" :key="vote.id">
            <td>
              <a :href="`/production/profiles/${vote.profileId}`" target="_blank">{{ vote.name }}</a>
            </td>
            <td>{{ vote.zip }} {{ vote.city }}</td>
            <td>
              <PhoneToLink :number="vote.mobile" :name="vote.name" />
            </td>
            <td>{{ formatDate(vote.createdAt) }}</td>
            <td>{{ formatDate(vote.updatedAt) }}</td>
          </tr>
        </tbody>
      </table>

      <ApiPagination
        :currentPage="pagination.currentPage"
        :totalPages="pagination.totalPages"
        @changePage="fetchVotes"
      />
    </div>
    <div v-else-if="loading" class="modal-body">
      <div class="text-center">
        <i class="fa fa-spinner fa-spin fa-2x" />
      </div>
    </div>
    <div v-else class="modal-body">
      <div class="text-center">
        {{ $t('production.poll_votes.no_votes') }}
      </div>
    </div>
  </apiModal>
</template>

<style lang="scss" scoped>
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
