<template>
  <div>
    <button type="button" class="btn btn-with-icon btn-sm btn-delete-contact" @click="$emit('remove')">
      <i class="fa fa-close" />
    </button>
    <div v-html="data" />
  </div>
</template>

<script>
import api from '../store/API'

export default {
  name: 'ContactDetails',
  props: {
    id: {
      type: Number,
    },
  },
  emits: ['remove'],
  data() {
    return {
      data: '',
    }
  },
  mounted() {
    this.fetchInfo()
  },
  methods: {
    async fetchInfo() {
      const contactHtml = await api.get('/production/references/new.html', {
        params: {
          contact_id: this.id,
          delete_button: false,
        },
      })

      this.data = contactHtml.data
    },
  },
}
</script>

<style scoped>
.btn-delete-contact {
  position: absolute;
  right: 10px;
  z-index: 1;
  margin-top: 10px;
  margin-right: 10px;
}
</style>
