/*
This plugin is derived from the code of TipTap. https://github.com/ueberdosis/tiptap/blob/6f218be6e439603c85559c6d77ec93a205003bf5/packages/extension-ordered-list/src/ordered-list.ts
We chose to create our own implementation instead of using the standard package because the standard package does not include the formatting of the previous line,
which is something we want to retain.
*/

import { mergeAttributes, Node, wrappingInputRule } from '@tiptap/core'

export const inputRegex = /^(\d+)\.\s$/

export const OrderedList = Node.create({
  name: 'orderedList',

  addOptions() {
    return {
      itemTypeName: 'listItem',
      HTMLAttributes: {},
    }
  },

  group: 'block list',

  content() {
    return `${this.options.itemTypeName}+`
  },

  addAttributes() {
    return {
      start: {
        default: 1,
        parseHTML: (element) => {
          return element.hasAttribute('start') ? parseInt(element.getAttribute('start') || '', 10) : 1
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'ol',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { start, ...attributesWithoutStart } = HTMLAttributes

    return start === 1
      ? ['ol', mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart), 0]
      : ['ol', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      toggleOrderedList:
        () =>
        ({ chain }) => {
          return chain().toggleList(this.name, this.options.itemTypeName, this.options.keepMarks).run()
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-7': () => this.editor.commands.toggleOrderedList(),
    }
  },

  addInputRules() {
    let inputRule = wrappingInputRule({
      find: inputRegex,
      type: this.type,
      getAttributes: (match) => ({ start: +match[1] }),
      joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1],
    })

    inputRule = wrappingInputRule({
      find: inputRegex,
      type: this.type,
      keepMarks: true,
      keepAttributes: true,
      getAttributes: (match) => ({
        start: +match[1],
      }),
      joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1],
      editor: this.editor,
    })
    return [inputRule]
  },
})
