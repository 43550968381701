<template>
  <div class="form-group">
    <label>Jouw telefoonnummer</label>
    <input v-model="personalNumber" type="text" class="form-control" placeholder="Jouw telefoonnummer" />

    <p class="help-block">
      We bellen je eerst op je eigen nummer en verbinden je dan door naar het telefoonnummer hierboven.
    </p>
  </div>

  <div class="form-group">
    <label>Bel namens</label>
    <select v-model="callerId" class="form-control" :disabled="!callerIdOptions.length">
      <option v-for="{ value, label } in callerIdOptions" :key="value" :value="value">
        {{ label }}
      </option>
    </select>
    <p class="help-block">
      <span v-if="callerId === 'self'">De persoon die je belt ziet jouw nummer ({{ personalNumber }}).</span>
      <span v-else-if="callerId">
        De persoon die je belt ziet nooit jouw nummer. Die wordt gebeld met het telefoonnummer van
        <strong>{{ callerIdName }}</strong
        >.
      </span>
    </p>
  </div>

  <p class="recording-details">
    <strong>Let op:</strong> gesprekken worden opgenomen en vind je terug in
    <a href="/production/private_calls">Dario</a>.
  </p>

  <button class="btn btn-success btn-lg call-button" :disabled="loading" @click="startCall()">
    <i class="fa fa-phone" />
    Bellen
  </button>

  <div v-if="validationErrors" class="alert alert-danger">
    <strong>Oeps</strong>, je bent iets vergeten in te vullen!
    <ul>
      <li v-for="(errors, key) in validationErrors" :key="key">
        <strong>{{ key }}</strong> {{ errors.join(', ') }}
      </li>
    </ul>
  </div>
</template>

<script>
import api from '@/store/API'
import socket from '../store/PublicSocket'

export default {
  name: 'PrivateCall',
  props: ['number'],
  data() {
    return {
      personalNumber: window.userConfig.user.phone_number,
      callerId: null,
      callerIdOptions: [],
      loading: false,
      validationErrors: null,
      clearResponseStateTimeout: null,
    }
  },
  computed: {
    callerIdName() {
      return this.callerIdOptions.find(({ value }) => value === this.callerId)?.label
    },
  },
  mounted() {
    this.retrieveCallerIds()
  },
  methods: {
    async retrieveCallerIds() {
      try {
        const { data } = await api.get('/production/private_calls/caller_ids')

        this.callerIdOptions = [
          ...Object.entries(data.caller_ids).map(([label]) => ({ value: label, label })),
          { value: 'self', label: 'Jezelf' },
        ]
        this.callerId = this.callerIdOptions[0].value
      } catch (e) {
        alert('Er ging iets mis bij het ophalen van de metadata voor private calls. Probeer het later opnieuw.')
      }
    },
    async startCall() {
      try {
        clearTimeout(this.clearResponseStateTimeout)
        this.$emit('state', 'idle')
        this.loading = true
        this.validationErrors = null

        const { data } = await api.post('/production/private_calls', {
          call: {
            number: this.number,
            personal_number: this.personalNumber,
            caller_id: this.callerId,
          },
        })

        this.listenToStateUpdates(data.private_call.id)

        this.$emit('state', 'connecting')
      } catch (e) {
        this.$emit('state', 'failed')
        if (e.response.status === 422) {
          this.validationErrors = e.response.data.errors
        }
        this.clearResponseStateTimeout = setTimeout(() => {
          this.$emit('state', 'idle')
          this.validationErrors = null
        }, 5000)
      } finally {
        this.loading = false
      }
    },
    async listenToStateUpdates(privateCallId) {
      ;(await socket()).subscribe('dario:private_call').on('state_changed', ({ id, state }) => {
        if (id === privateCallId) {
          this.$emit('state', state)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.call-button {
  width: 100%;
  margin-bottom: 20px;
}

.recording-details {
  a {
    line-height: 1 !important;
  }
}
</style>
