<template>
  <div>
    <h4 class="profile-list-title">Geselecteerde winnaars ({{ profiles.length }})</h4>
    <ul class="profile-list">
      <ProfileCard v-for="profile in profiles" :key="profile.id" :profile="profile" />
    </ul>
  </div>
</template>

<script>
import ProfileCard from './ProfileCard.vue'

export default {
  name: 'ProfileList',
  components: {
    ProfileCard,
  },
  props: {
    profiles: Array,
  },
}
</script>

<style lang="scss" scoped>
.profile-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 15px;
}
.profile-list-title {
  font-size: 15px;
  font-weight: bold;
}
</style>
