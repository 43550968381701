<template>
  <div class="dario-tooltip">
    <slot />
    <span class="dario-tooltip__text">
      <slot name="tooltip-content" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'DarioTooltip',
}
</script>

<style lang="scss" scoped>
.dario-tooltip {
  position: relative;
  display: inline-block;

  &__text {
    font-size: 14px;
    visibility: hidden;
    width: 200px;
    bottom: calc(100% - 15px);
    left: 20px;
    background-color: white;
    color: #555;
    box-shadow: 4px 1px 5px rgba($color: #000000, $alpha: 0.5);
    padding: 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      bottom: 3px;
      left: 0%;
      margin-left: -10px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent white transparent transparent;
    }
  }

  &:hover &__text {
    visibility: visible;
  }
}
</style>
