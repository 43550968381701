<template>
  <a class="btn btn-with-icon btn-default" :class="{ 'btn-success': count > 0 }" @click="click()">
    <i class="fa fa-lg fa-refresh" :class="{ 'fa-spin': clicked }" />
    <span v-if="count > 0" class="count">{{ count }}</span>
  </a>
</template>

<script>
export default {
  name: 'RefreshButton',
  props: {
    count: Number,
  },
  data() {
    return {
      clicked: false,
    }
  },
  methods: {
    click() {
      this.clicked = true
      this.$emit('click')
      this.$el.addEventListener(
        'animationiteration',
        () => {
          this.clicked = false
        },
        { once: true }
      )
    },
  },
}
</script>
