<template>
  <div>
    <button type="button" class="btn btn-default dropdown-toggle" :class="[buttonClass]" @click="toggleDropdown">
      <i :class="iconClass" />
      <span class="caret" />
    </button>
    <div v-show="open" class="dario-dropdown-menu dropdown-menu" :class="positionClassName" @click="toggleDropdown">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownButton',
  props: ['icon', 'position', 'buttonClass'],
  data() {
    return {
      open: false,
    }
  },
  computed: {
    iconClass() {
      return `fa fa-${this.icon}`
    },
    positionClassName() {
      return `dropdown-menu-${this.position}`
    },
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open
    },
  },
}
</script>

<style lang="scss">
.dario-dropdown-menu {
  cursor: pointer;
  left: auto;
  top: auto;
  display: flex;
  flex-direction: column;

  > * {
    display: flex;
    align-items: center;
    color: #333;
    margin: 0;

    &:hover {
      background-color: #333;
      color: white;
    }
  }
}
</style>
